import {AxPermissionRequest} from '../AxPermissionRequest';
import {forkJoin, Observable, single} from 'rxjs';
import {User} from '../../model/User';


export interface UserRole {
  id: string; // Unique identifier for the role
  displayName: string; // Human-readable name of the role
  description?: string; // Optional description
  permissions?: Set<string>; // List of permissions associated with the role
  isHidden: boolean; // Indicates if the role is hidden by default
}

export const NullRole: UserRole = {
  id: 'null',
  displayName: 'Null Role',
  isHidden: true,
  permissions: new Set<string>()
};

export abstract class RoleManagerService {
  abstract getRole(roleId: string): Observable<UserRole>;
  abstract getAvailableRoles(): Observable<UserRole[]>;
  getRolesForUser(user: User): Observable<UserRole[]>{
    return forkJoin(user.roles.map(roleId => this.getRole(roleId).pipe(single())));
  };
}

export interface PermissionContext{
  user: User|null;
  role: UserRole|null;
  // roles?: UserRole[];
}

export abstract class PermissionService {

  protected constructor() {
    // Method not implemented
  }

  abstract evaluateRequest<T>(req: AxPermissionRequest<T>): Observable<boolean>;
  abstract evaluateRequestWithAnyRoles<T>(req: AxPermissionRequest<T>): Observable<boolean>;
}



