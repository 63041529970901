import {Injectable, Type} from '@angular/core';
import {FileReadMode, ImportExportType, ImportService} from '../../../../import-export/import-export.service';
import {Errorable, some} from '../../../../utils/optional';
import {Observable} from 'rxjs';
import {ConfigComponent} from '../../../../import-export/config.component';
import {
  BaseImportConfigType
} from '../../../../import-export/components/base-import-config/base-import-config.component';
import {
  GeojsonVolumeImportConfigComponent
} from '../geojson-volume-import-config/geojson-volume-import-config.component';
import {map} from 'rxjs/operators';
import * as L from 'leaflet';
import * as tj from '@mapbox/togeojson';

function supressDrag(e: L.LeafletEvent) {
  (e?.layer as any)?.pm?.disableLayerDrag?.();
}

function supressEdit(e: L.LeafletEvent) {
  (e?.layer as any)?.pm?.disable?.();
}

@Injectable({
  providedIn: 'root'
})
export class KmlImportService extends ImportService<Errorable<any>> {

  constructor() {
    super();
  }

  private disableEditDrag(layer: L.Layer) {
    (layer as any).pm.setOptions({
      allowEditing: false,
      allowRotation: false,
      draggable: false
    });
    layer.on('pm:dragenable', supressDrag);
    layer.on('pm:enable', supressEdit);

    if (layer instanceof L.LayerGroup || layer instanceof L.FeatureGroup) {
      const that = this;
      layer.eachLayer((l) => {
        that.disableEditDrag(l);
      });
    }
  }

  doImport(config: any): Observable<Errorable<any>> {
    return this.readFile(config.file, FileReadMode.STRING).pipe(map((fileContents) => {
      const parser = new DOMParser();
      const kml = parser.parseFromString(fileContents as string, 'text/xml');
      const converted = tj.kml(kml, {styles: true});
      const geojsonLayer = new L.GeoJSON();

      geojsonLayer.addData(converted);

      geojsonLayer.eachLayer((sublayer: any) => {
        if (sublayer.feature.properties.stroke && !!sublayer.setStyle) {
          sublayer.feature.properties.color = sublayer.feature.properties.stroke;
          sublayer.feature.properties.stroke = true;
          sublayer.setStyle(sublayer.feature.properties);
        }
      });

      this.disableEditDrag(geojsonLayer);
      return some(geojsonLayer);
    }));
  }

  getFormatName(): string {
    return 'KML Overlay';
  }

  getType(): ImportExportType {
    return ImportExportType.UNIVERSAL;
  }

  getFileExtensions(): string[] {
    return ['.kml'];
  }

  getConfigComponent(): Type<ConfigComponent> {
    return GeojsonVolumeImportConfigComponent;
  }

  getDefaultConfig(): any {
    return {file: null} as BaseImportConfigType;
  }
}
