import {Injectable} from '@angular/core';
import {concat, Observable} from 'rxjs';
import {Contact} from '../model/Contact';
import {SearchResult} from '../model/SearchResult';
import {ContactResponse} from '../model/ContactResponse';
import {map, mergeMap, reduce} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import _ from 'lodash';

export interface IContactSearch {
  isPilot?: boolean;
  isUserContact?: boolean;
  sort?: ContactSearchSortField;
  sortIncreasing?: boolean;
}

export enum ContactSearchSortField {
  firstName = 'firstName',
  lastName = 'lastName'
}

export class ContactSearch implements IContactSearch {
  isPilot: boolean;
  isUserContact: boolean;
  sort: ContactSearchSortField;
  sortIncreasing: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public constructor() {
    // Method not implemented
  }

  public static defaultSearch(): ContactSearch {
    return new ContactSearch(
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class ContactService {
  abstract getContacts(contactSearch: IContactSearch,
                       limit?: number,
                       offset?: number,
                       fetchCount?: boolean): Observable<SearchResult<Contact>>;

  abstract getContactByUserID(userId: string): Observable<ContactResponse>;

  getAllContacts(search: IContactSearch, stepSize = 100): Observable<Contact[]> {
    return this.getContacts(search, stepSize, 0, true).pipe(
      mergeMap(res => {
        const obs: Observable<SearchResult<Contact>>[] = [of(res)];

        if (res.results.length < res.total) {
          for (let i = res.offset + res.results.length; i < res.total; i += stepSize) {
            obs.push(this.getContacts(search, stepSize, i, false));
          }
        }

        return concat(...obs).pipe(
          reduce((acc, curr) => acc.concat([curr]), [])
        );
      }),
      map((res: SearchResult<Contact>[]) => _.flatten(res.map(r => r.results)))
    )
  }
}
