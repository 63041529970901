import {OperatorIntentResult} from './OperatorIntentResult';
import {ConformanceState, humanizedOperatorIntentFailureReason, OperatorIntentType} from './enums';
import {UserMessage} from './UserMessage';
import {ConformanceAlertMessage} from './ConformanceAlertMessage';
import {OperationConflictAlertMessage} from './OperationConflictAlertMessage';
import {ApprovalStatus, state} from '../gen/utm';
import {ConstraintAlertMessage} from './ConstraintAlertMessage';
import {UserMessageEntry} from './UserMessageEntry';
import {UserDirectMessage} from './UserDirectMessage';
import {truncateWithEllip} from '../../utils/misc';
import {ApprovalAlertMessage} from './ApprovalAlertMessage';

export class UserMessageUtil {

  constructor() {
    // Method not implemented
  }

  static getSummaryText(message: UserMessage): string {
    switch (message.type) {

      case 'OPERATOR_INTENT_RESULT':
        const opIntent = message.data as OperatorIntentResult;

        if (!opIntent.success) {
          const failureReason = humanizedOperatorIntentFailureReason[opIntent.failureReason];

          switch (opIntent.intentType) {
            case OperatorIntentType.OPERATION_CREATE:
              return (`Operation Not Accepted (${failureReason})`);
            case OperatorIntentType.OPERATION_MODIFY:
              return (`Operation Modification Failed (${failureReason})`);
            case OperatorIntentType.OPERATION_ACTIVATE:
              return (`Operation Activation Failed (${failureReason})`);
            case OperatorIntentType.OPERATION_CLOSE:
              return (`Operation Close Failed (${failureReason})`);
            case OperatorIntentType.CONSTRAINT_CREATE:
              return (`Constraint Not Accepted (${failureReason})`);
            case OperatorIntentType.CONSTRAINT_MODIFY:
              return (`Constraint Modification Failed (${failureReason})`);
            case OperatorIntentType.CONSTRAINT_ACTIVATE:
              return (`Constraint Activation Failed (${failureReason})`);
            case OperatorIntentType.CONSTRAINT_CLOSE:
              return (`Constraint Close Failed (${failureReason})`);
          }
          return (`${opIntent.intentType ? opIntent.intentType : ''} Failure (${failureReason})`);
        } else {
          switch (opIntent.intentType) {
            case OperatorIntentType.OPERATION_CREATE:
              return (`Operation Accepted`);
            case OperatorIntentType.OPERATION_MODIFY:
              return (`Operation Modified`);
            case OperatorIntentType.OPERATION_ACTIVATE:
              return (`Operation Activated`);
            case OperatorIntentType.OPERATION_CLOSE:
              return (`Operation Closed`);
            case OperatorIntentType.CONSTRAINT_CREATE:
              return (`Constraint Accepted`);
            case OperatorIntentType.CONSTRAINT_MODIFY:
              return (`Constraint Modified`);
            case OperatorIntentType.CONSTRAINT_ACTIVATE:
              return (`Constraint Activated`);
            case OperatorIntentType.CONSTRAINT_CLOSE:
              return (`Constraint Closed`);
          }
          return (`${opIntent.intentType ? opIntent.intentType : ''} Success`);
        }

      case 'OPERATION_CONFLICT_ALERT':
        const conflictAlert = message.data as  OperationConflictAlertMessage;
        return (`${conflictAlert.conflictingOperationPrioritized ? 'Higher Priority ' : ''}Conflicting Operation Placed on Operation ${conflictAlert.conflictingOperationState === state.CLOSED ? 'Has Been Closed' : ''}`);

      case 'CONFORMANCE_ALERT':
        const conformanceAlert = message.data as ConformanceAlertMessage;
        if (conformanceAlert.operationEnded) {
          switch (conformanceAlert.conformanceState) {
            case ConformanceState.NONCONFORMING:
              return 'Non-Conforming Operation in Your Area Has Been Closed';
            case ConformanceState.CONTINGENT:
              return 'Contingent Operation in Your Area Has Been Closed';
            default:
              break;
          }
        }
        switch (conformanceAlert.conformanceState) {
          case ConformanceState.CONFORMING:
            return conformanceAlert.ownedByUser ? `Operation Conforming` : `Non-Conforming Operation in Your Area Has Returned to Conformance`;
          case ConformanceState.NONCONFORMING:
            return conformanceAlert.ownedByUser ? 'Operation Non-Conforming' :  'Non-Conforming Operation in Your Area';
          case ConformanceState.CONTINGENT:
            return conformanceAlert.ownedByUser ? 'Operation Contingent' : 'Contingent Operation in Your Area';
          default:
            return (`Operation Conformance State Unknown`);
        }

      case 'CONSTRAINT_ALERT':
        const constraintAlert = message.data as ConstraintAlertMessage;
        return (`${constraintAlert.constraintTypePermitted ? 'Permitted' : 'Conflicting'} Constraint Placed on Operation ${constraintAlert.constraintClosed ? 'Has Been Closed' : ''}`);

      case 'USER_DIRECT':
        const userDirect = message.data as UserDirectMessage;
        return(`Message From: ${userDirect.from?.name || 'Unknown User'}`);

      case 'APPROVAL_ALERT':
        const approvalAlert = message.data as ApprovalAlertMessage;
        switch (approvalAlert.approval.approvalStatus) {
          case ApprovalStatus.PENDING:
            return 'Operation Pending Approval';
          case ApprovalStatus.APPROVED:
            return 'Operation Approved';
          case ApprovalStatus.REJECTED:
            return 'Operation Approval Request Denied';
          case ApprovalStatus.EXPIRED:
            return 'Operation Approval Request Expired';
          case ApprovalStatus.CANCELLED:
            return 'Operation Approval Request Cancelled';
        }

      // case 'OPERATION_DRAFT_MESSAGE':
      //   const opDraft = message.data as OperationDraftMessage;
      //   if (!opDraft.success) {
      //     switch (opDraft.draftType) {
      //       case OperationDraftType.OPERATION_CREATE_DRAFT:
      //         return of(`Operation draft failed to be created (${opDraft.failureReason})`);
      //       case OperationDraftType.OPERATION_MODIFY_DRAFT:
      //         return of(`Operation draft failed to be updated (${opDraft.failureReason})`);
      //     }
      //     return of(`${opDraft.draftId}: ${opDraft.draftType ? opDraft.draftType : ''} Failure (${opDraft.failureReason})`);
      //   } else {
      //     switch (opDraft.draftType) {
      //       case OperationDraftType.OPERATION_CREATE_DRAFT:
      //         return of('Operation Draft Created');
      //       case OperationDraftType.OPERATION_MODIFY_DRAFT:
      //         return of('Operation Draft Updated');
      //     }
      //   }
      //   return of(`${opDraft.draftId}: ${opDraft.draftType ? opDraft.draftType : ''} Success`);

      default:
        return (message.body);
    }
  }

  static getNotesText(message: UserMessage) {
    let notes = message.body;
    if (message.type === 'USER_DIRECT') {
      notes = `Message Body: ${(message.data as UserDirectMessage).text}`;
    }
    return truncateWithEllip(notes, 40, false);
  }

  static getModalSize(message: UserMessage): string {
    switch (message.type) {
      case 'OPERATOR_INTENT_RESULT':
      case 'OPERATION_CONFLICT_ALERT':
      case 'CONFORMANCE_ALERT':
      case 'CONSTRAINT_ALERT':
      case 'APPROVAL_ALERT':
        return 'xl';
      default:
        return 'md';
    }
  }

  static getAlertType(message: UserMessageEntry): string {
    switch (message.message.type) {
      case 'OPERATOR_INTENT_RESULT':
        const opIntent = message.message.data as OperatorIntentResult;
        if (!opIntent.success) {
          return 'danger';
        } else {
          switch (opIntent.intentType) {
            case OperatorIntentType.OPERATION_CREATE:
            case OperatorIntentType.OPERATION_ACTIVATE:
            case OperatorIntentType.CONSTRAINT_CREATE:
              return 'success';
            case OperatorIntentType.OPERATION_MODIFY:
            case OperatorIntentType.OPERATION_CLOSE:
            case OperatorIntentType.CONSTRAINT_MODIFY:
            case OperatorIntentType.CONSTRAINT_ACTIVATE:
            case OperatorIntentType.CONSTRAINT_CLOSE:
              return 'info';
          }
        }
        break;
      case 'OPERATION_CONFLICT_ALERT':
        const conflictAlert = message.message.data as OperationConflictAlertMessage;
        return conflictAlert.conflictingOperationState === state.CLOSED ? 'info' : 'danger';
      case 'CONFORMANCE_ALERT':
        const conformanceAlert = message.message.data as ConformanceAlertMessage;
        return (conformanceAlert.conformanceState === ConformanceState.CONFORMING || conformanceAlert.operationEnded) ?
          'info' : 'danger';
      case 'CONSTRAINT_ALERT':
        const constraintAlert = message.message.data as ConstraintAlertMessage;
        return (!constraintAlert.constraintTypePermitted && !constraintAlert.constraintClosed) ? 'danger' : 'info';
      case 'USER_DIRECT':
        return 'info';
      case 'APPROVAL_ALERT':
        const approvalAlert = message.message.data as ApprovalAlertMessage;
        switch (approvalAlert.approval.approvalStatus) {
          case ApprovalStatus.APPROVED:
            return 'success';
          case ApprovalStatus.REJECTED:
          case ApprovalStatus.EXPIRED:
            return 'danger';
          default:
            return 'info';
        }
      // case 'OPERATION_DRAFT_MESSAGE':
      //   const opDraft = message.message.data as OperationDraftMessage;
      //   if (!opDraft.success) {
      //     return 'danger';
      //   } else {
      //     switch (opDraft.draftType) {
      //       case OperationDraftType.OPERATION_CREATE_DRAFT:
      //         return 'success';
      //       case OperationDraftType.OPERATION_MODIFY_DRAFT:
      //         return 'info';
      //     }
      //   }
    }
  }
}
