<h1 cds-text="title">View Operations</h1>
@if (deviceSize$() !== 'sm') {
  <div class="btn-row-wrapper">
    <button type="button" (click)="refresh()" class="btn btn-primary">Refresh</button>
    <button class="btn btn-primary" (click)="resetFilters()">Reset Filters</button>
  </div>
  <div [formGroup]="filterFG">
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact operation-table">
      <!-- Datagrid header row -->
      <!--    Uncomment this code to enable the "requires rerouting" column
      <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>-->
      <clr-dg-column class="col-state" [clrDgField]="'state'" [clrDgSortBy]="'state'" [clrDgSortOrder]="sortDirections['state']">State
        <clr-dg-filter [clrDgFilter]="stateFilter">

          <app-operation-states-filter #stateFilter [formControlName]="'states'">
          </app-operation-states-filter>
        </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column [clrDgField]="'flight_number'" [clrDgSortBy]="'flight_number'"
                     [clrDgSortOrder]="sortDirections['flight_number']">Name</clr-dg-column>
      <clr-dg-column [clrDgField]="'flight_comments'" [clrDgSortBy]="'flight_comments'"
                     [clrDgSortOrder]="sortDirections['flight_comments']">Description</clr-dg-column>
    <!--  <clr-dg-column class="time_col"> <ng-container *clrDgHideableColumn="{hidden: false}">Submit Time</ng-container></clr-dg-column>-->
    <!--    The begin and end times use custom sort and filtering functions due to the inability to bind to an op volume index -->
      <clr-dg-column [clrDgSortBy]="'effective_time_begin'" [clrDgSortOrder]="sortDirections['effective_time_begin']" >
        <ng-container *clrDgHideableColumn="{hidden: false}">Begin Time</ng-container>
      </clr-dg-column>
      <clr-dg-column [clrDgSortBy]="'effective_time_end'" [clrDgSortOrder]="sortDirections['effective_time_end']">
        <ng-container *clrDgHideableColumn="{hidden: false}">End Time</ng-container>
      </clr-dg-column>
      <clr-dg-column [clrDgField]="'scope'" class="col-scope">Ownership
        <clr-dg-filter [clrDgFilter]="scopesFilter">
            <app-operation-scopes-filter #scopesFilter [formControlName]="'scope'">
            </app-operation-scopes-filter>
        </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column [clrDgField]="'uss_name'" [clrDgSortBy]="'uss_name'" [clrDgSortOrder]="sortDirections['uss_name']">
        <ng-container *clrDgHideableColumn="{hidden: false}">USS</ng-container>
      </clr-dg-column>
      <clr-dg-column>Telemetry Status</clr-dg-column>
      <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>

      <!-- Datagrid body rows -->
      <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>
      <clr-dg-row *ngFor="let operation of operations" [clrDgItem]="operation" class="datagrid-body-row">
  <!--      Uncomment this code to enable the "requires rerouting" icon
        <clr-dg-cell class="icon_col datagrid-fixed-column datagrid-expandable-caret">
          <clr-tooltip *ngIf="operation.replan_required" class="replan-warning">
            <cds-icon clrTooltipTrigger role="img" shape="warning-standard" aria-label="warning icon"></cds-icon>
            <clr-tooltip-content clrPosition="top-right" *clrIfOpen>
              <span>Operation requires replanning</span>
            </clr-tooltip-content>
          </clr-tooltip>
        </clr-dg-cell>-->
        <clr-dg-cell>
          <app-operation-state-badge [state]="operation.state"></app-operation-state-badge>
        </clr-dg-cell><!-- State -->
        <clr-dg-cell class="col-name">
          {{operation.managed ? operation.flight_number : 'External Operation (' + operation.flight_number + ')'}}
        </clr-dg-cell><!-- Name -->
        <clr-dg-cell>{{truncateWithEllip(operation.flight_comments)}}</clr-dg-cell><!-- Description -->
        <clr-dg-cell>{{formatDateTime(operation.effective_time_begin)}}</clr-dg-cell><!-- Begin Time -->
        <clr-dg-cell>{{formatDateTime(operation.effective_time_end)}}</clr-dg-cell><!-- End Time -->
        <clr-dg-cell>{{getOperationScope(operation)}}</clr-dg-cell>
        <clr-dg-cell>{{operation.uss_name}}</clr-dg-cell><!-- USS -->
        <clr-dg-cell class="col-telemetry">
          <ng-container *ngIf="operationTelemetryStatuses[operation.operationId]?.status">
            <cds-icon shape="circle" solid="true" size="xs"
                  [ngClass]="{'ACTIVE': 'active', 'INACTIVE': 'inactive', 'ERROR': 'error'}[operationTelemetryStatuses[operation?.operationId]?.status]">
            </cds-icon>
            {{operationTelemetryStatuses[operation.operationId]?.status}}
          </ng-container>
        </clr-dg-cell>
        <clr-dg-cell class="datagrid-fixed-column datagrid-expandable-caret">
          <a (click)="viewOperation(operation)"><cds-icon shape="link"></cds-icon></a>
        </clr-dg-cell>
        <!--<clr-dg-cell>
          <ul>
            <li *ngFor="let airspace of getAirspaces(operation.additional_data.airspace)">{{airspace}}</li>
          </ul>
        </clr-dg-cell>--><!-- Airspace -->

        <clr-dg-action-overflow>
          <ng-container *hasPermission="{action: 'activate', resourceType: 'operation', resource: operation}">
            <button *ngIf="operation.state === operationState.ACCEPTED && operation.managed && operation.effective_time_begin.diffNow() <= 0"
                    (click)="activateOperation(operation)" class="action-item">Activate</button>
          </ng-container>
          <button class="action-item" (click)="viewOperation(operation)">View</button>
          <ng-container *hasPermission="'submit_operation'">
            <button *ngIf="operation.state !== operationState.REJECTED" class="action-item" (click)="modifyOperation(operation, OP_MODE.clone)">Clone</button>
            <!-- Replan Rejected Operations -->
            <ng-container *ngIf="operation.state === operationState.REJECTED && operation.managed">
              <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                      (click)="modifyOperation(operation, OP_MODE.replan)">Replan</button>
            </ng-container>
            <!-- Reroute Accepted Operations -->
            <ng-container *ngIf="operation.state === operationState.ACCEPTED && operation.managed && !operation.approval?.approved">
              <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                      (click)="modifyOperation(operation, OP_MODE.rerouteAccepted)">Replan</button>
            </ng-container>
            <!-- Reroute Activated Operations -->
            <ng-container *ngIf="(operation.state === operationState.ACTIVATED || operation.state === operationState.NONCONFORMING) &&
              operation.managed && !operation.approval?.approved">
              <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                      (click)="modifyOperation(operation, OP_MODE.rerouteActive)">Replan</button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="enableExperimentalDirectMessagingSupport && operation.state !== operationState.CLOSED &&
            operation.state !== operationState.REJECTED && operation.managed && operation.additional_data?.user_id !== currentUser?.uid">
            <button class="action-item" (click)="openSendMessageModal(operation.operationId)">Send Message</button>
          </ng-container>
          <ng-container *ngIf="operation.state !== operationState.CLOSED && operation.state !== operationState.REJECTED && operation.managed">
            <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                    (click)="closeOperation(operation)">Close</button>
          </ng-container>
        </clr-dg-action-overflow>
  <!--      Uncomment this code to enable expandable details section
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-summary [operation]="operation"></app-ax-operation-summary>
        </clr-dg-row-detail>-->
      </clr-dg-row>

      <!-- Datagrid footer -->
      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                           [clrDgTotalItems]="totalItems"></clr-dg-pagination>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}} |
        <clr-dg-page-size [clrPageSizeOptions]="pageSizeOptions">Operations per page</clr-dg-page-size>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
} @else {
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" [formGroup]="filterFG">
    <!-- Datagrid header row -->
    <!--    Uncomment this code to enable the "requires rerouting" column
    <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>-->
    <clr-dg-column class="col-state" [clrDgField]="'state'" [clrDgSortBy]="'state'" [clrDgSortOrder]="sortDirections['state']">State
      <clr-dg-filter [clrDgFilter]="stateFilter">

        <app-operation-states-filter #stateFilter [formControlName]="'states'">
        </app-operation-states-filter>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="col-name" [clrDgField]="'flight_number'" [clrDgSortBy]="'flight_number'"
                   [clrDgSortOrder]="sortDirections['flight_number']">Name</clr-dg-column>

    <!-- Datagrid body rows -->
    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>
    <clr-dg-row *ngFor="let operation of operations" [clrDgItem]="operation" class="datagrid-body-row">
      <clr-dg-cell>
        <app-operation-state-badge [state]="operation.state"></app-operation-state-badge>
      </clr-dg-cell><!-- State -->
      <clr-dg-cell>
        {{operation.managed ? operation.flight_number : 'External Operation (' + operation.flight_number + ')'}}
      </clr-dg-cell><!-- Name -->

      <clr-dg-action-overflow>
        <ng-container *hasPermission="{action: 'activate', resourceType: 'operation', resource: operation}">
          <button *ngIf="operation.state === operationState.ACCEPTED && operation.managed && operation.effective_time_begin.diffNow() <= 0"
                  (click)="activateOperation(operation)" class="action-item">Activate</button>
        </ng-container>
        <button class="action-item" (click)="viewOperation(operation)">View</button>
        <ng-container *hasPermission="'submit_operation'">
          <button *ngIf="operation.state !== operationState.REJECTED" class="action-item" (click)="modifyOperation(operation, OP_MODE.clone)">Clone</button>
          <!-- Replan Rejected Operations -->
          <ng-container *ngIf="operation.state === operationState.REJECTED && operation.managed">
            <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                    (click)="modifyOperation(operation, OP_MODE.replan)">Replan</button>
          </ng-container>
          <!-- Reroute Accepted Operations -->
          <ng-container *ngIf="operation.state === operationState.ACCEPTED && operation.managed && !operation.approval?.approved">
            <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                    (click)="modifyOperation(operation, OP_MODE.rerouteAccepted)">Replan</button>
          </ng-container>
          <!-- Reroute Activated Operations -->
          <ng-container *ngIf="(operation.state === operationState.ACTIVATED || operation.state === operationState.NONCONFORMING) &&
            operation.managed && !operation.approval?.approved">
            <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                    (click)="modifyOperation(operation, OP_MODE.rerouteActive)">Replan</button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="enableExperimentalDirectMessagingSupport && operation.state !== operationState.CLOSED &&
            operation.state !== operationState.REJECTED && operation.managed && operation.additional_data?.user_id !== currentUser?.uid">
          <button class="action-item" (click)="openSendMessageModal(operation.operationId)">Send Message</button>
        </ng-container>
        <ng-container *ngIf="operation.state !== operationState.CLOSED && operation.state !== operationState.REJECTED && operation.managed">
          <button *hasPermission="{action: 'modify', resourceType:'operation', resource: operation}" class="action-item"
                  (click)="closeOperation(operation)">Close</button>
        </ng-container>
      </clr-dg-action-overflow>
      <!--      Uncomment this code to enable expandable details section
            <clr-dg-row-detail *clrIfExpanded>
              <app-ax-operation-summary [operation]="operation"></app-ax-operation-summary>
            </clr-dg-row-detail>-->
    </clr-dg-row>

    <!-- Datagrid footer -->
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      <span class="datagrid-footer-description">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}</span>
    </clr-dg-footer>
  </clr-datagrid>
}

<!-- Operation closure confirmation modals -->
<clr-modal *ngIf="operationToClose"
           [clrModalOpen]="!!operationToClose"
           (clrModalOpenChange)="operationToClose = $event ? operationToClose : undefined"
           [clrModalSize]="'md'">
  <h3 class="modal-title">Close Operation</h3>
  <div class="modal-body">
    <span class="spinner" *ngIf="waitingForOperationToClose">
      Closing...
    </span>
    <p *ngIf="!waitingForOperationToClose">Are you sure you want to close operation `{{operationToClose.operationId}}`?</p>
  </div>
  <div class="modal-footer" *ngIf="!waitingForOperationToClose">
    <button type="button" class="btn btn-primary" (click)="operationToClose=null">Cancel</button>
    <button type="button" class="btn btn-primary btn-danger" (click)="confirmOperationClose()">Close</button>
  </div>
</clr-modal>

<clr-modal *ngIf="operationCloseSubmitted" [(clrModalOpen)]="operationCloseSubmitted" [clrModalSize]="'md'" [clrModalStaticBackdrop]="false">
  <h3 class="modal-title">Operation Closure Request Submitted</h3>
  <div class="modal-body">
    <p>The operation closure request has been submitted.</p>
  </div>
</clr-modal>
