import {BaseEnumSerializerDeserializer} from "../common";
import {fail, Result, some} from "../../utils";

export enum vertical_reference {
  W84 = 'W84',
  MSL = 'MSL',
  AGL = 'AGL'
}

export class VerticalReferenceUtil implements BaseEnumSerializerDeserializer<vertical_reference, vertical_reference> {
  deserialize(raw: unknown): Result<vertical_reference> {
    if (typeof raw !== 'string') return fail('Invalid type for vertical reference');

    switch (raw.toUpperCase()) {
      case 'W84':
        return some(vertical_reference.W84);
      case 'MSL':
        return some(vertical_reference.MSL);
      case 'AGL':
        return some(vertical_reference.AGL);
      default:
        return fail(`Invalid vertical reference: ${raw}`);
    }
  }

  serialize(obj: vertical_reference): vertical_reference {
    return obj;
  }

}

export const DEFAULT_VERTICAL_REFERENCE_UTIL = new VerticalReferenceUtil();
