import {Injectable, Type} from '@angular/core';
import {ImportExportType, ImportService} from '../../../../../import-export/import-export.service';
import {Errorable, fail} from '../../../../../utils/optional';
import {IOpGeoSubmissionFG} from '../../../../../components/operation/create-operation/create-operation.component';
import {Observable} from 'rxjs';
import {ConfigComponent} from '../../../../../import-export/config.component';
import {map} from 'rxjs/operators';
import {
  BaseImportConfigType
} from '../../../../../import-export/components/base-import-config/base-import-config.component';
import {
  GeojsonVolumeImportConfigComponent
} from '../../geojson-volume-import-config/geojson-volume-import-config.component';
import {DEFAULT_ALTITUDE_UTIL, isEmpty, isObject, some} from '@ax-uss-ui/common';
import {
  IOpVolumeSubmissionFG
} from '../../../../../components/operation/operation-geometry-editor/operation-geometry-editor.component';
import {TimeRange} from '../../../../../model/TimeRange';
import {DateTime} from 'luxon';
import {polygon_type, units_of_measure, vertical_reference} from '../../../../../model/gen/utm';
import {AltitudeRange} from '../../../../../model/gen/utm/altitude-range-model';
import {OPERATION_START_OFFSET_LIMITS} from '../../../../../constants';
import {isArray} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UltraJsonImportService extends ImportService<Errorable<IOpGeoSubmissionFG>> {
  constructor() {
    super();
  }

  doImport(config: any): Observable<Errorable<IOpGeoSubmissionFG>> {
    return this.readFileJson(config.file).pipe(map((value: unknown) => {
      if (!value) {
        return fail('Invalid JSON');
      }
      return this.deserializeImport(value);
    }));
  }

  getConfigComponent(): Type<ConfigComponent> {
    return GeojsonVolumeImportConfigComponent;
  }

  getDefaultConfig(): any {
    return {file: null} as BaseImportConfigType;
  }

  getFileExtensions(): string[] {
    return ['.json'];
  }

  getFormatName(): string {
    return 'JSON (Ultra)';
  }

  getType(): ImportExportType {
    return ImportExportType.OPERATIONVOLUME;
  }

  private deserializeGeographyType(type: string): Errorable<polygon_type> {
    switch (type) {
      case 'Polygon':
        return some(polygon_type.Polygon);
      default:
        return fail(`Unrecognized flightGeography type: ${type}`)
    }
  }

  private deserializeVolume(rawVol: unknown, currentTime: DateTime): Errorable<IOpVolumeSubmissionFG> {
    if (isEmpty(rawVol)) return fail('No volume value');
    if (!isObject(rawVol)) return fail('Invalid volume value');

    // Deserialize altitudes
    if (!('minAlt' in rawVol) || isEmpty(rawVol.minAlt)) return fail('No minAlt value');
    if (!('maxAlt' in rawVol) || isEmpty(rawVol.maxAlt)) return fail('No maxAlt value');

    // Construct min & max altitude objects for deserialization via the AltitudeUtil class
    const rawMinAlt = {
      altitude_value: parseFloat(rawVol.minAlt.toString()),
      vertical_reference: rawVol['altType'] || 'W84',
      units_of_measure: 'FT',
      source: 'OTHER'
    };
    const rawMaxAlt = {
      altitude_value: parseFloat(rawVol.maxAlt.toString()),
      vertical_reference: rawVol['altType'] || 'W84',
      units_of_measure: 'FT',
      source: 'OTHER'
    };

    const minAlt = DEFAULT_ALTITUDE_UTIL.deserialize(rawMinAlt);
    if (minAlt.type === 'error') return fail(minAlt.message);

    const maxAlt = DEFAULT_ALTITUDE_UTIL.deserialize(rawMaxAlt);
    if (maxAlt.type === 'error') return fail(maxAlt.message);

    // Deserialize time range
    if (!('minutesBegin' in rawVol) || isEmpty(rawVol.minutesBegin)) return fail('No minutesBegin value');
    const minutesBegin = parseFloat(rawVol.minutesBegin.toString());
    if (isNaN(minutesBegin)) return fail('Invalid minutesBegin value');

    if (!('minutesEnd' in rawVol) || isEmpty(rawVol.minutesEnd)) return fail('No minutesEnd value');
    const minutesEnd = parseFloat(rawVol.minutesEnd.toString());
    if (isNaN(minutesEnd)) return fail('Invalid minutesEnd value');

    // If the start time offset is less than the permitted minimum, set it to ASAP (null). Otherwise, the
    // OperationGeometryEditorComponent will replace the invalid time range using the default offset.
    const startTime = minutesBegin < OPERATION_START_OFFSET_LIMITS.min ? null : currentTime.plus({minutes: minutesBegin});

    const timeRange = new TimeRange(startTime, currentTime.plus({minutes: minutesEnd}));

    // Deserialize geography
    if (!('flightGeography' in rawVol) || isEmpty(rawVol.flightGeography)) return fail('No flightGeography value');
    if (!isObject(rawVol.flightGeography)) return fail('Invalid flightGeography value');

    if (!('type' in rawVol.flightGeography) || isEmpty(rawVol.flightGeography.type)) return fail('No flightGeography type value');
    if (typeof rawVol.flightGeography.type !== 'string') return fail('Invalid flightGeography type value');
    const geographyType = this.deserializeGeographyType(rawVol.flightGeography.type);
    if (geographyType.type === 'error') return fail(geographyType.message);

    if (!('coordinates' in rawVol.flightGeography) || isEmpty(rawVol.flightGeography.coordinates)) return fail('No flightGeography coordinates value');
    if (!Array.isArray(rawVol.flightGeography.coordinates) || !rawVol.flightGeography.coordinates.length) return fail('Invalid flightGeography coordinates value');

    // Extract the coordinates from the raw geography
    const coordinates: number[][][] = [];
    for (const rawCoords of rawVol.flightGeography.coordinates) {
      if (!Array.isArray(rawCoords) || !rawCoords.length) return fail('Invalid flightGeography coordinates value');
      const tmpCoords: number[][] = [];
      for (const coord of rawCoords) {
        if (!Array.isArray(coord) || coord.length < 2) return fail('Invalid flightGeography coordinates value');
          const lat = parseFloat(coord[1].toString());
          const lon = parseFloat(coord[0].toString());
          if (isNaN(lat) || isNaN(lon)) return fail('Invalid flightGeography coordinates value');
          tmpCoords.push([lon, lat]);
      }
      coordinates.push(tmpCoords);
    }
    if (!coordinates[0].length) return fail('No flightGeography coordinates value');

    return some({
      geography: {
        type: geographyType.value,
          coordinates: coordinates
      },
      circle: undefined,
        altitudeRange: new AltitudeRange({
      min_altitude: minAlt.value.altitude_value,
      max_altitude: maxAlt.value.altitude_value,
      altitude_vertical_reference: minAlt.value.vertical_reference as any as vertical_reference,
      altitude_units: minAlt.value.units_of_measure as any as units_of_measure
    }),
      timeRange: timeRange
    } as IOpVolumeSubmissionFG);
  }

  private deserializeImport(raw: unknown): Errorable<IOpGeoSubmissionFG> {
    if (isEmpty(raw)) return fail('No data supplied for imported volumes');
    if (!isArray(raw)) return fail('Invalid data supplied for imported volumes');

    const volumes: IOpVolumeSubmissionFG[] = [];
    const currentTime = DateTime.now();
    let error = '';

    raw.some((rawVol => {
      let result = this.deserializeVolume(rawVol, currentTime);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        // Construct the volume & append it to the volumes array
        volumes.push(result.value);
        return false;
      }
    }));

    return error ? fail(error) : some({volumes});
  }
}
