@let position = selectedAircraftPositions$ | async;
<form [formGroup]="fg">
  <!-- Small screens: Show geometry editor & Cesium preview in modal -->
  @switch (deviceSize$()) {
    @case ('sm') {
      <button type="button" class="btn btn-icon btn-draw-operation" (click)="showGeometryMap = true">
        <cds-icon shape="pencil"></cds-icon>
        Draw Operation
      </button>
      <div class="volumes-section">
        <h3 cds-text="section">Volume Details</h3>
        <ng-container *ngTemplateOutlet="volumesDetails"></ng-container>
      </div>
    }
    @case ('md') {
      <!--Medium screens: Show geometry editor & Cesium preview in tabbed interface-->
      <div class="volumes-section">
        <ng-container *ngTemplateOutlet="tabbedMapsLayout"></ng-container>
        <ng-container *ngTemplateOutlet="volumesDetails"></ng-container>
      </div>
    }
    @case ('lg') {
      <!--Large screens: Show geometry editor & Cesium preview side-by-side-->
      <div class="clr-row full-editor volumes-section">
        <div class="clr-col-md-6">
          <h4 class="map-heading">
            <cds-icon shape="note"></cds-icon>
            Edit
          </h4>
          <ng-container *ngTemplateOutlet="leafletContainer"></ng-container>
        </div>
        <div class="clr-col-md-6">
          <h4 class="map-heading">
            <cds-icon shape="eye"></cds-icon>
            Preview
          </h4>
          <ng-container *ngTemplateOutlet="cesiumContainer"></ng-container>
        </div>
        <div class="clr-col-12">
          <ng-container *ngTemplateOutlet="volumesDetails"></ng-container>
        </div>
      </div>
    }
  }

  <!-- Template for tabbed maps layout -->
  <ng-template #tabbedMapsLayout>
    <clr-tabs class="tabbed-editor">
      <clr-tab>
        <button clrTabLink>Edit</button>
        <clr-tab-content *clrIfActive>
          <ng-container *ngTemplateOutlet="leafletContainer"></ng-container>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink [disabled]="fg.invalid">Preview</button>
        <clr-tab-content *clrIfActive>
          <ng-container *ngTemplateOutlet="cesiumContainer"></ng-container>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </ng-template>

  <!-- Template for Leaflet map editor -->
  <ng-template #leafletContainer>
    <ax-leaflet-container>
      <app-import-export [type]="importExportType.OPERATIONVOLUME"
                         (importResult)="handleOperationVolumeImport($event)"
                         [disableExport]="fg.invalid"
                         [exportData]="currentGeometry"
                         (exportResult)="handleOperationVolumeExport($event)"
      ></app-import-export>

      <leaflet-expose-map (mapInit)="handleMapInit($event)"></leaflet-expose-map>

      <app-leaflet-volume-creator
        [name]="'OperationVolume'"
        [title]="'Draw Operation Volume'"
        [colorConfig]="colorConfig"
        (newVolume)="createVolume($event)">
      </app-leaflet-volume-creator>

      <app-leaflet-circle-creator
        [name]="'OperationVolumeCircle'"
        [title]="'Draw Operation Circle Volume'"
        [colorConfig]="colorConfig"
        [selectedUnits]="selectedUnits"
        (newVolume)="createVolume($event)">
      </app-leaflet-circle-creator>

      <app-leaflet-point-creator
        [name]="'ControllerLocation'"
        [title]="'Controller Location'"
        [controlClassName]="'fa fa-gamepad'"
        [icon]="controllerIcon"
        [formControlName]="'controllerLocation'">
      </app-leaflet-point-creator>

      <app-leaflet-point-creator
        [name]="'TakeOffLocation'"
        [title]="'Take Off Location'"
        [controlClassName]="'drone-icon'"
        [icon]="takeOffIcon"
        [formControlName]="'takeOffLocation'">
      </app-leaflet-point-creator>
      <lib-leaflet-position-drawer [position$]="selectedAircraftPositions$"></lib-leaflet-position-drawer>
      <app-leaflet-sitrep-drawer [enabled]="sitRepEnabled" [altRef]="selectedVertRef" [altUnits]="selectedUnits"
                                 (enabledChange)="this.updateSitMode($event)"></app-leaflet-sitrep-drawer>

      <ng-container *ngFor="let volumeForm of volumeFgArrayAsFormGroups; let i = index">
        <app-leaflet-polygon-editor
          *ngIf="volumeForm.controls.geography?.value"
          [colorConfig]="colorConfig"
          [formControl]="volumeForm.controls.geography"
          [index]="i">
        </app-leaflet-polygon-editor>

        <app-leaflet-circle-editor
          *ngIf="volumeForm.controls.circle?.value"
          [colorConfig]="colorConfig"
          [formControl]="volumeForm.controls.circle"
          [index]="i">
        </app-leaflet-circle-editor>
      </ng-container>

      <app-leaflet-polyline-drawer [points]="points" [color]="'#fcba03'"
                                   [fillColor]="'#fcba03'"></app-leaflet-polyline-drawer>
    </ax-leaflet-container>
  </ng-template>

  <!-- Template for 3D Cesium preview -->
  <ng-template #cesiumContainer>
    <div class="cesium-wrapper">
      <ax-cesium-container [timeline]="false"
                           [navigationInstructionsInitiallyVisible]="false"
                           [projectionPicker]="true"
                           [toolbarZIndex]="'999'"
                           [sceneModePicker]="false">
        <lib-infobox-container/>
        <app-lib-safire-button [btnAlt]="'Situational Awareness'" [state]="sitRepEnabled ? ON : OFF"
                               [btnIcon]="sitRepEnabled ? '/assets/icons/eye.png' : '/assets/icons/eye-slash.png'"
                               (stateChange)="sitRepEnabled = $event === ON" [showText]="false">
          <ng-container *libSafireButtonContent>
            <app-operation-sitrep-drawer [altRef]="selectedVertRef"
                                         [altUnits]="selectedUnits"></app-operation-sitrep-drawer>
            <app-constraint-sitrep-drawer [altRef]="selectedVertRef"
                                          [altUnits]="selectedUnits"></app-constraint-sitrep-drawer>
          </ng-container>
        </app-lib-safire-button>

        @if(previewGeometry){
          <app-operation-geometry-drawer [geometry]="previewGeometry" [colorConfig]="colorConfig" />
        }

        <lib-cesium-telemetry-drawer [position$]="selectedAircraftPositions$" />

      </ax-cesium-container>
    </div>
  </ng-template>

  <!-- Template for volumes details section -->
  <ng-template #volumesDetails>
    <error-container [control]="fg.controls.volumes">
      <error-group [prefix]="'volume_' + (i+1)" *ngFor="let volume of fg.controls.volumes.value; let i = index">
        <error-message [has]="'geometry_missing'"></error-message>
        <error-message [has]="'coordinates_missing'"></error-message>
        <error-message [has]="'radius_missing'"></error-message>
        <error-message [has]="'min_radius'"></error-message>
        <error-message [has]="'max_radius'"></error-message>
        <error-message [has]="'geometry_continuity_fail'"></error-message>
        <error-message [has]="'altitude_continuity_fail'"></error-message>
        <error-message [has]="'time_continuity_fail'"></error-message>
        <error-message [has]="'self_intersecting'"></error-message>
      </error-group>
    </error-container>

    <error-container [control]="fg.controls.volumes">
      <error-message [has]="'required'">Volumes missing</error-message>
      <error-message [has]="'operationStartFuture'">Activated operations cannot be replanned to start in the future</error-message>
    </error-container>
    <error-container [control]="fg.controls.controllerLocation">
      <error-message [has]="'required'">Controller location missing</error-message>
    </error-container>
    <error-container [control]="fg.controls.takeOffLocation">
      <error-message [has]="'required'">Take off location missing</error-message>
    </error-container>

    <div class="clr-row clr-align-items-center utility-actions-row">
      @if (deviceSize$() !== 'sm') {
        <div class="clr-col-auto">
          <button type="button" class="btn btn-sm btn-icon" aria-label="plus" (click)="showManualCoordsEditor()">
            <cds-icon shape="plus"></cds-icon>
            New Volume From Coordinates
          </button>
        </div>
      }
      @if (volumeArray.controls.length > 1) {
        <div class="clr-col-auto">
          <clr-dropdown class="volume-actions-dropdown">
            <button type="button" class="btn btn-sm btn-icon btn-outline-primary" clrDropdownTrigger>
              Volume Actions
              <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
              <div class="actions-group">
                <label class="dropdown-header" aria-hidden="true">General Actions</label>
                <button clrDropdownItem (click)="setVolumeAction(volumeAction.RTL)"
                        [clrDisabled]="fg.controls?.volumes?.invalid">RTL
                  <clr-tooltip>
                    <cds-icon clrTooltipTrigger shape="info-circle" size="24"></cds-icon>
                    <clr-tooltip-content [clrPosition]="'right'" [clrSize]="'sm'">
                      Reverses all volumes to return the aircraft to the first volume
                    </clr-tooltip-content>
                  </clr-tooltip>
                </button>
                <button clrDropdownItem (click)="setVolumeAction(volumeAction.LAND_NOW)"
                        [clrDisabled]="fg.controls?.volumes?.invalid || !selectedAircraftPositionSig$()">Land Now
                  <clr-tooltip>
                    <cds-icon clrTooltipTrigger shape="info-circle" size="24"></cds-icon>
                    <clr-tooltip-content [clrPosition]="'right'" [clrSize]="'sm'">
                      Replaces all volumes with a single landing volume at the aircraft's current location
                    </clr-tooltip-content>
                  </clr-tooltip>
                </button>
              </div>
              <div class="actions-group">
                <label class="dropdown-header" aria-hidden="true">Merge Volumes</label>
                <button clrDropdownItem (click)="setVolumeAction(volumeAction.SINGLE_MERGE)"
                        [clrDisabled]="fg.controls?.volumes?.invalid">Single Volume
                  <clr-tooltip>
                    <cds-icon clrTooltipTrigger shape="info-circle" size="24"></cds-icon>
                    <clr-tooltip-content [clrPosition]="'right'" [clrSize]="'sm'">
                      Merges all volumes into a single volume
                    </clr-tooltip-content>
                  </clr-tooltip>
                </button>
                <button clrDropdownItem (click)="setVolumeAction(volumeAction.SMART_MERGE)"
                        [clrDisabled]="fg.controls?.volumes?.invalid">Smart Merge
                  <clr-tooltip>
                    <cds-icon clrTooltipTrigger shape="info-circle" size="24"></cds-icon>
                    <clr-tooltip-content [clrPosition]="'right'" [clrSize]="'sm'">
                      Reduces the number of volumes by merging adjacent volumes with similar properties
                    </clr-tooltip-content>
                  </clr-tooltip>
                </button>
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>
        </div>
        <div class="clr-col-auto">
          <clr-checkbox-container clrInline style="margin: auto 0 auto 0" class="duplicate-settings-checkbox">
            <label>Duplicate first volume's:</label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="simpleVolDetails" [ngModel]="simpleVolDetails.simpleTimeRange"
                     (ngModelChange)="handleSimpleVolTimeRangeChange($event)" [ngModelOptions]="{standalone: true}"/>
              <label>Time Range</label>
            </clr-checkbox-wrapper>

            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="simpleVolDetails"
                     [ngModel]="simpleVolDetails.simpleAltitudeRange"
                     (ngModelChange)="handleSimpleVolAltitudeRangeChange($event)"
                     [ngModelOptions]="{standalone: true}"/>
              <label>Altitude Range</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
        </div>
        <div class="clr-col-auto preserve-vol-overlap-wrapper">
          <clr-checkbox-container clrInline style="margin: auto 0 auto 0" class="duplicate-settings-checkbox">
            <clr-checkbox-wrapper>
              <input type="checkbox"
                     clrCheckbox
                     name="preserveVolumeOverlap"
                     [ngModel]="preserveVolumeOverlap$()"
                     (ngModelChange)="preserveVolumeOverlap$.set($event)"
                     [ngModelOptions]="{standalone: true}"
                     [disabled]="simpleVolDetails.simpleTimeRange"/>
              <label>Preserve Volume Time Overlap</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
        </div>
      }
    </div>

    @if (deviceSize$() === 'sm') {
      <ng-container *ngTemplateOutlet="volumesTableSimple"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="volumesTableFull"></ng-container>
    }

    <cds-accordion class="manual-locations-editor">
      <cds-accordion-panel [expanded]="manualLocationExpanded">
        <cds-accordion-header (click)="manualLocationExpanded = !manualLocationExpanded">Manual Locations
        </cds-accordion-header>
        <cds-accordion-content>
          <div class="clr-row">
            <div class="clr-col-md-6 clr-form-control">
              <label class="clr-control-label">Controller/GCS Location</label>
              <div class="clr-control-container">
                <ax-latlngpoint-input formControlName="manualControllerLocation"></ax-latlngpoint-input>
              </div>
            </div>
            <div class="clr-col-md-6 clr-form-control">
              <label class="clr-control-label">Take Off Location</label>
              <div class="clr-control-container">
                <ax-latlngpoint-input formControlName="manualTakeOffLocation"></ax-latlngpoint-input>
              </div>
            </div>
          </div>
        </cds-accordion-content>
      </cds-accordion-panel>
    </cds-accordion>
  </ng-template>
</form>

<!-- Template for volume details form desktop view -->
<ng-template #volumesTableFull>
  <clr-datagrid (clrDgRefresh)="refresh($event)" class="vol-details-table">
    <clr-dg-column>Volume Number</clr-dg-column>
    <clr-dg-column>Time Range ({{ timeZone }})</clr-dg-column>
    <clr-dg-column>Altitude Range</clr-dg-column>
    <clr-dg-column>Edit Points</clr-dg-column>

    @for (volumeForm of volumeFgArray; track volumeForm; let i = $index) {
      <clr-dg-row [clrDgItem]="volumeForm"
                  [ngClass]="{'hideRow': !(i >= pagination.firstItem && i <=pagination.lastItem)}">
        <ng-container [formGroup]="volumeForm">
          <clr-dg-cell class="clr-row ordinal-col">
            <div class="clr-col-4 remove-icon">
              <cds-icon shape="trash" size="sm" class="custom-btn-icon"
                        (click)="removeVolume(this.baseVolumeIndex$() + i)"></cds-icon>
            </div>
            <div class="clr-col-4 arrows">
                  <span class="arrow-icon"><cds-icon shape="arrow" size="sm" class="custom-btn-icon"
                                                     (click)="moveVolume(-1, (this.baseVolumeIndex$() + i))"></cds-icon></span>
            <span class="arrow-icon arrow-down"><cds-icon shape="arrow" size="sm" class="custom-btn-icon"
                                                          (click)="moveVolume(1, (this.baseVolumeIndex$() + i))"></cds-icon></span>
          </div>
          <div class="clr-col-4 ordinal">{{ this.baseVolumeIndex$() + i + 1 }}</div>
        </clr-dg-cell>
        <clr-dg-cell>
          <div (keydown)="stopMove($event)">
            <app-time-range-selector formControlName="timeRange"
                                     [startTimeOffsetLimits]="startTimeOffsetLimits"
                                     [durationLimits]="durationLimits"
                                     [startCheckInterval]="timeCheckInterval"
                                     [volumeIndex$]="baseVolumeIndex$() + i"
                                     (durationChange$)="handleVolumeDurationChange($event)">
            </app-time-range-selector>
          </div>
        </clr-dg-cell>
        <clr-dg-cell>
          <div (keydown)="stopMove($event)">
            <app-altitude-range-selector formControlName="altitudeRange"
                                         [simpleRange]="true"
                                         [fgValidators]="altitudeFGValidators"
                                         [valueValidators]="altitudeValueValidators">
            </app-altitude-range-selector>
          </div>
        </clr-dg-cell>
        <clr-dg-cell class="clr-row ordinal-col">
          <div class="clr-col-4 ordinal">
            <cds-icon shape="pencil" size="sm" class="custom-btn-icon"
                      (click)="showManualCoordsEditor((this.baseVolumeIndex$() + i), volumeArray.controls[(this.baseVolumeIndex$() + i)].value)"></cds-icon>
          </div>
        </clr-dg-cell>
      </ng-container>
    </clr-dg-row>
    }
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                         [clrDgTotalItems]="volumeFgArray.length"></clr-dg-pagination>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ volumeFgArray.length }}
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Volumes per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
</ng-template>

<!-- Template for volumes details form mobile view -->
<ng-template #volumesTableSimple>
  <clr-datagrid class="vol-details-table">
    <clr-dg-column>Volume Number</clr-dg-column>
    @for (volumeForm of volumeFgArray; track volumeForm; let i = $index) {

      <clr-dg-row [clrDgItem]="volumeForm">
      <clr-dg-cell>{{ this.baseVolumeIndex$() + i + 1 }}</clr-dg-cell>
      <clr-dg-row-detail *clrIfExpanded>
        <div [formGroup]="volumeForm" class="clr-row clr-flex-column volumes-form">
          <app-time-range-selector formControlName="timeRange"
                                   class="clr-col-auto"
                                   [startTimeOffsetLimits]="startTimeOffsetLimits"
                                   [durationLimits]="durationLimits"
                                   [startCheckInterval]="timeCheckInterval"
                                   [volumeIndex$]="this.baseVolumeIndex$() + i"
                                   (durationChange$)="handleVolumeDurationChange($event)">>
          </app-time-range-selector>
          <app-altitude-range-selector class="clr-col-auto"
                                       formControlName="altitudeRange"
                                       [simpleRange]="true"
                                       [fgValidators]="altitudeFGValidators"
                                       [valueValidators]="altitudeValueValidators">
          </app-altitude-range-selector>
        </div>
      </clr-dg-row-detail>
    </clr-dg-row>
    }
    <clr-dg-placeholder>No volumes found. Use the Draw Operation button to draw or import operation volumes.
    </clr-dg-placeholder>
  </clr-datagrid>
</ng-template>

<!-- Modals -->
<!-- New Volume From Coordinates Modal -->
<clr-modal [(clrModalOpen)]="showNewManualCoordsEditor" *ngIf="showNewManualCoordsEditor"
           class="geometry-input-modal">
  <div class="modal-title">
    New Volume From Coordinates
  </div>
  <div class="modal-body">
    <form [formGroup]="manualGeometryTypeFG">
      <clr-select-container class="geometry-type-selector">
        <label>Geometry Type</label>
        <select clrSelect formControlName="geometryType">
          <option *ngFor="let type of geometryTypes" [value]="type.value">{{ type.label }}</option>
        </select>
      </clr-select-container>
    </form>
    @switch (manualGeometryTypeFG.controls.geometryType.value) {
      @case (geometryTypeEnum.POLYGON) {
        <form [formGroup]="manualPolygonFg" style="height:100%">
          <app-manual-polygon-input [formControlName]="'polygon'"></app-manual-polygon-input>
        </form>
      }
      @case (geometryTypeEnum.CIRCLE) {
        <form [formGroup]="manualCircleFg" style="height:100%">
          <app-manual-circle-input [formControlName]="'circle'"></app-manual-circle-input>
        </form>
      }
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showNewManualCoordsEditor = false">Cancel</button>
    @if (manualGeometryTypeFG.controls.geometryType.value === geometryTypeEnum.POLYGON) {
      <button type="button" class="btn btn-primary" [disabled]="!manualPolygonFg.controls.polygon.value"
              (click)="saveGeometry(manualGeometryTypeFG.controls.geometryType.value)">Save
      </button>
    }
    @if (manualGeometryTypeFG.controls.geometryType.value === geometryTypeEnum.CIRCLE) {
      <button type="button" class="btn btn-primary" [disabled]="!manualCircleFg.controls.circle.value"
              (click)="saveGeometry(manualGeometryTypeFG.controls.geometryType.value)">Save
      </button>
    }
  </div>
</clr-modal>

<!-- Manual Polygon Editor Modal -->
<clr-modal [(clrModalOpen)]="showManualPolygonEditor" *ngIf="showManualPolygonEditor" class="geometry-input-modal">
  <div class="modal-title">
    Polygon Coordinates Editor
  </div>
  <div class="modal-body">
    <form [formGroup]="manualPolygonFg" style="height:100%">
      <app-manual-polygon-input [formControlName]="'polygon'"></app-manual-polygon-input>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showManualPolygonEditor = false">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!manualPolygonFg.controls.polygon.value"
            (click)="savePolygon()">Save
    </button>
  </div>
</clr-modal>

<!-- Manual Circle Editor Modal -->
<clr-modal [(clrModalOpen)]="showManualCircleEditor" *ngIf="showManualCircleEditor" class="geometry-input-modal">
  <div class="modal-title">
    Circle Coordinates Editor
  </div>
  <div class="modal-body">
    <form [formGroup]="manualCircleFg" style="height:100%">
      <app-manual-circle-input [formControlName]="'circle'"></app-manual-circle-input>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showManualCircleEditor = false">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!manualCircleFg.controls.circle.value"
            (click)="saveCircle()">Save
    </button>
  </div>
</clr-modal>

<!-- Mobile Geometry Map Modal -->
@if (showGeometryMap) {
  <clr-modal [(clrModalOpen)]="showGeometryMap" class="fullscreen-map-modal">
    <div class="modal-title">Draw Operation</div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="tabbedMapsLayout"></ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="showGeometryMap = false">Done</button>
    </div>
  </clr-modal>
}

@if (showVolumeActionConfirmationModal$()) {
  <clr-modal [(clrModalOpen)]="showVolumeActionConfirmationModal$" class="volume-actions-confirmation-modal">
    <div class="modal-title">
      @switch (selectedVolumeAction$()) {
        @case (volumeAction.RTL) {
          Confirm Return to Launch
        }
        @case (volumeAction.LAND_NOW) {
          Confirm Land Now
        }
        @case (volumeAction.SINGLE_MERGE) {
          Confirm Single Volume Merge
        }
        @case (volumeAction.SMART_MERGE) {
          Confirm Smart Volumes Merge
        }
        @default {
          None
        }
      }
    </div>
    <div class="modal-body">
      @switch (selectedVolumeAction$()) {
        @case (volumeAction.RTL)  {
          <p>Returning to launch will reverse the operation volumes order to facilitate returning the aircraft to the first
            volume. All volume times will get set to start ASAP and end at the overall operation's end time.</p>
        }
        @case (volumeAction.LAND_NOW) {
          <p>Landing now will replace all volumes with a single volume intersecting with the ground, drawn around the
            aircraft's current position.</p>
        }
        @case (volumeAction.SINGLE_MERGE) {
          <p>This will combine all volumes into a single volume. The start time of the new volume will be set to the
            earliest start time of all the current volumes, and the end time will be set to the latest end time of the
            current volumes. The altitude range will be set to the minimum and maximum altitudes across all volumes.</p>
        }
        @case (volumeAction.SMART_MERGE) {
          <p>This will reduce the number of volumes by merging adjacent volumes with similar properties (i.e. time
            range, altitude range).</p>
        }
        @default {
          None
        }
      }
      <p>Are you sure you want to proceed?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="setVolumeAction(volumeAction.NONE)">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="confirmVolumeAction()">Confirm</button>
    </div>
  </clr-modal>
}
