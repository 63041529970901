import {fail, isEmpty, isObject, Result, some} from "../utils";
import {BaseModel, BaseModelSerializerDeserializer} from "./common";
import {
  DEFAULT_POSITION_SUBMISSION_V2_UTIL,
  PositionSubmissionV2,
  TPositionSubmissionV2,
} from './PositionSubmissionV2';

import {DateTime} from "luxon";

export interface TPositionSubmissionMessage {
  id: string;
  user_id: string;
  time_created: string;
  source: string;
  position_submission: TPositionSubmissionV2;
};


export class PositionSubmissionMessage implements BaseModel {
  constructor(
    public id: string,
    public userId: string,
    public timeCreated: DateTime<true>,
    public source: string,
    public positionSubmission: PositionSubmissionV2
  ) {
  }
}

export class PositionSubmissionMessageUtil implements BaseModelSerializerDeserializer<TPositionSubmissionMessage, PositionSubmissionMessage> {
  deserialize(raw: unknown): Result<PositionSubmissionMessage> {
    if (isEmpty(raw)) return fail('No data supplied');
    if (!isObject(raw)) return fail('Invalid data supplied');

    if (!('id' in raw)) return fail('No id');
    if (typeof raw.id !== 'string' || isEmpty(raw.id)) return fail('Invalid id');

    if (!('user_id' in raw)) return fail('No user id');
    if (typeof raw.user_id !== 'string' || isEmpty(raw.user_id)) return fail('Invalid user id');

    if (!('time_created' in raw)) return fail('No time created');
    if (typeof raw.time_created !== 'string' || isEmpty(raw.time_created)) return fail('Invalid time created');
    const timeCreated = DateTime.fromISO(raw.time_created);
    if (!timeCreated.isValid) return fail('Invalid time created');

    if (!('source' in raw)) return fail('No source');
    if (typeof raw.source !== 'string') return fail('Invalid source');

    if (!('position_submission' in raw)) return fail('No position submission');
    const positionSubmission = DEFAULT_POSITION_SUBMISSION_V2_UTIL.deserialize(raw.position_submission);
    if (positionSubmission.type === 'error') return fail(`Invalid position data: \n` + positionSubmission.message);

    return some(new PositionSubmissionMessage(
      raw.id,
      raw.user_id,
      timeCreated,
      raw.source,
      positionSubmission.value
    ));

  }

  serialize(obj: PositionSubmissionMessage): Result<TPositionSubmissionMessage> {
    const serializedPositionSubmission = DEFAULT_POSITION_SUBMISSION_V2_UTIL.serialize(obj.positionSubmission);
    if(serializedPositionSubmission.type === 'error') return fail(serializedPositionSubmission.message);

    return some({
      id: obj.id,
      user_id: obj.userId,
      time_created: obj.timeCreated.toISO(),
      source: obj.source,
      position_submission: serializedPositionSubmission.value
    });
  }
}

export const DEFAULT_POSITION_SUBMISSION_MESSAGE_UTIL = new PositionSubmissionMessageUtil();
