<div [formGroup]="statesFg">
  <p class="filter-label"><strong>States</strong></p>
  <a class="select-all" (click)="toggleSelectAll()">Select All</a>
  @for (state of availableStateOptions; track $index) {
    <clr-checkbox-wrapper>
      <input autocomplete="off" type="checkbox" clrCheckbox [formControlName]="state"/>
      <label>{{getPrettyOperationStateName(state)}}</label>
    </clr-checkbox-wrapper>
  }
</div>
