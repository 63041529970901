import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Contact} from '../../model/Contact';
import {IControlValueAccessor} from '@rxweb/types';
import {Parser} from '../../model/utm/parser/OperationParser';
import {forbiddenPatternRegexes, invalidCharactersValidator, isNullValidator} from '../../utils/Validators';
import {InputType} from '../string-array-input/string-array-input.component';
import {requiredWhiteSpace} from '@ax-uss-ui/common';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditContactComponent),
    }
  ]
})
export class EditContactComponent implements IControlValueAccessor<Contact>, OnInit {
  @Input() altEmails = false;

  formGroup: UntypedFormGroup;
  inputTypeEnum = InputType;

  private onChange: (value: Contact) => void;

  constructor() {
    // Method not implemented
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      hasPart107: new UntypedFormControl(false),
      prefixName: new UntypedFormControl(null, [Validators.maxLength(60),
        invalidCharactersValidator(forbiddenPatternRegexes.alphaOnly)]),
      firstName: new UntypedFormControl(null, [requiredWhiteSpace, Validators.maxLength(60),
        invalidCharactersValidator(forbiddenPatternRegexes.firstMiddleName)]),
      middleName: new UntypedFormControl(null, [Validators.maxLength(60),
        invalidCharactersValidator(forbiddenPatternRegexes.firstMiddleName)]),
      lastName: new UntypedFormControl(null, [requiredWhiteSpace, Validators.maxLength(60),
        invalidCharactersValidator(forbiddenPatternRegexes.lastName)]),
      suffixName: new UntypedFormControl(null, [Validators.maxLength(60),
        invalidCharactersValidator(forbiddenPatternRegexes.suffixName)]),
      division: new UntypedFormControl(null, [Validators.maxLength(128),
        invalidCharactersValidator(forbiddenPatternRegexes.division)]),
      phones: new UntypedFormControl([], [isNullValidator, Validators.maxLength(5)]),
      emails: new UntypedFormControl([], [isNullValidator, Validators.maxLength(4)]),
      title: new UntypedFormControl(null, [requiredWhiteSpace, Validators.maxLength(128),
        invalidCharactersValidator(forbiddenPatternRegexes.title)])
    });

    this.formGroup.valueChanges.subscribe((c) => {
      const changes = this.formGroup.invalid ?  null : Parser.parseContact(this.formGroup.getRawValue());
      if (this.onChange) {
        this.onChange(changes);
      }
    });
    /*
    prefixName: string;
      firstName: string;
      middleName: string;
      lastName: string;
      suffixName: string;
      division: string;
      phones: string[];
      emails: string[];
      title: string;
      comments: string;
      address: string;
     */

  }

  registerOnChange(fn: (value: Contact) => void): void {
    this.onChange = fn;
  }


  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    // Method not implemented
  }

  writeValue(obj: Contact): void {
    if (obj === null) {
      this.resetForm();
      return;
    }
    this.formGroup.controls.hasPart107.setValue(obj.hasPart107);
    this.formGroup.controls.prefixName.setValue(obj.prefixName);
    this.formGroup.controls.firstName.setValue(obj.firstName);
    this.formGroup.controls.middleName.setValue(obj.middleName);
    this.formGroup.controls.lastName.setValue(obj.lastName);
    this.formGroup.controls.suffixName.setValue(obj.suffixName);
    this.formGroup.controls.division.setValue(obj.division);
    this.formGroup.controls.phones.setValue(obj.phones);
    if (this.altEmails) {
      this.formGroup.controls.emails.setValue(obj.emails.slice(1));
    }
    this.formGroup.controls.title.setValue(obj.title);
  }

  resetForm() {
    this.formGroup.reset({
      hasPart107: false,
      prefixName: null,
      firstName: null,
      middleName: null,
      lastName: null,
      suffixName: null,
      division: null,
      phones: [],
      emails: [],
      title: null,
    });
    this.formGroup.markAsUntouched({onlySelf: true});
    this.formGroup.markAsPristine({onlySelf: true});
  }

}
