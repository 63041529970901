import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';

/**
  * A validator function that checks whether a field passes Angular's required validator and doesn't only contain whitespace.
  */
export const requiredWhiteSpace: ValidatorFn = (ac: AbstractControl) => {
  const value = ac.value;
  if (Validators.required(ac) !== null || typeof value !== 'string' || value.trim().length === 0) {
    return {required: true};
  } else {
    return null;
  }
};
