@if (internalPlatform) {
  <div class="clr-row">
    <div class="clr-col-md-6">
      <h2 cds-text="section">General Information</h2>
      <table class="table table-vertical" aria-label="General platform information">
        <tbody>
          <tr>
            <th scope="row">Name</th>
            <td>{{internalPlatform.name}}</td>
          </tr>
          <tr>
            <th scope="row">FAA Registration Number</th>
            <td>{{internalPlatform.faaRegistrationNumber}}</td>
          </tr>
          <tr>
            <th scope="row">UVIN</th>
            <td>{{internalPlatform.uvin}}</td>
          </tr>
        </tbody>
      </table>

      <h2 cds-text="section">Aircraft Specifications</h2>
      <h3 cds-text="subsection">General</h3>
      @if (internalPlatform.aircraftSpecs) {
        <table class="table table-vertical" aria-label="Platform specifications">
          <tbody>
            <tr><th scope="row">Serial Number</th><td>{{internalPlatform.aircraftSpecs.serialNumber || 'Not Available'}}</td></tr>
            <tr><th scope="row">Manufacturer</th><td>{{internalPlatform.aircraftSpecs.manufacturer || 'Not Available'}}</td></tr>
            <tr><th scope="row">Make</th><td>{{internalPlatform.aircraftSpecs.make || 'Not Available'}}</td></tr>
            <tr><th scope="row">Model</th><td>{{internalPlatform.aircraftSpecs.model || 'Not Available'}}</td></tr>
            <tr><th scope="row">Control Link</th><td>{{internalPlatform.aircraftSpecs.controlLink || 'Not Available'}}</td></tr>
            <tr><th scope="row">Fuel Source</th><td>{{internalPlatform.aircraftSpecs.fuelSource || 'Not Available'}}</td></tr>
            <tr><th scope="row">Country Of Origin</th><td>{{internalPlatform.aircraftSpecs.countryOfOrigin || 'Not Available'}}</td></tr>
            <tr><th scope="row">Service Interval</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.serviceInterval)}}</td></tr>
          </tbody>
        </table>
      }
      <h3 cds-text="subsection">Physical Characteristics</h3>
      <table class="table table-vertical" aria-label="Platform physical characteristics">
        <tbody>
          <tr><th scope="row">Color</th><td>{{internalPlatform.aircraftSpecs.color || 'Not Available'}}</td></tr>
          <tr><th scope="row">Airframe Type</th><td>{{prettifyAirframe(internalPlatform.aircraftSpecs.airframe)}}</td></tr>
          <tr><th scope="row">Number Of Rotors/Motors</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.numberOfRotors)}}</td></tr>
          <tr><th scope="row">Propeller Length ({{unitLabels.lengthWidthHeight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.propLength)}}</td></tr>
          <tr><th scope="row">Dry Weight ({{unitLabels.weight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.dryWeight)}}</td></tr>
          <tr><th scope="row">Length ({{unitLabels.lengthWidthHeight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.length)}}</td></tr>
          <tr><th scope="row">Width ({{unitLabels.lengthWidthHeight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.width)}}</td></tr>
          <tr><th scope="row">Wingspan ({{unitLabels.lengthWidthHeight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.wingspan)}}</td></tr>
          <tr><th scope="row">Height ({{unitLabels.lengthWidthHeight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.height)}}</td></tr>
          <tr><th scope="row">Primary Construction Material</th><td>{{internalPlatform.aircraftSpecs.primaryConstructionMaterial || 'Not Available'}}</td></tr>
        </tbody>
      </table>

      <h3 cds-text="subsection">Capabilities</h3>
      <table class="table table-vertical" aria-label="Platform capabilities">
        <tbody>
          <tr><th scope="row">Maximum Take Off Weight ({{unitLabels.weight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxTakeOffWeight)}}</td></tr>
          <tr><th scope="row">Maximum Round Trip Range ({{unitLabels.maxRoundTripRange}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxRoundTripRange)}}</td></tr>
          <tr><th scope="row">Maximum Endurance/Flight Time (Minutes)</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxEnduranceTime)}}</td></tr>
          <tr><th scope="row">Maximum Airspeed ({{unitLabels.maxAirspeed}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxAirspeed)}}</td></tr>
          <tr><th scope="row">Maximum Vertical Speed ({{unitLabels.maxVerticalSpeed}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxVerticalSpeed)}}</td></tr>
          <tr><th scope="row">Maximum Vertical Acceleration ({{unitLabels.maxVerticalAcceleration}}<sup>2</sup>)</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxVerticalAcceleration)}}</td></tr>
          <tr><th scope="row">Minimum Operating Temperature ({{unitLabels.operatingTemperature}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.minOperatingTemp)}}</td></tr>
          <tr><th scope="row">Maximum Operating Temperature ({{unitLabels.operatingTemperature}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.maxOperatingTemp)}}</td></tr>
          <tr><th scope="row">IP Rating</th><td>{{internalPlatform.aircraftSpecs.ipRating || 'Not Available'}}</td></tr>
        </tbody>
      </table>

      <h3 cds-text="subsection">Powerplant Specifications</h3>
      <table class="table table-vertical" aria-label="Platform powerplant specifications">
        <tbody>
          <tr><th scope="row">Engine Type</th><td>{{internalPlatform.aircraftSpecs.engineType || 'Not Available'}}</td></tr>
          <tr><th scope="row">Fuel Type</th><td>{{internalPlatform.aircraftSpecs.fuelSource || 'Not Available'}}</td></tr>
          <tr><th scope="row">Fuel Capacity ({{unitLabels.fuelCapacity}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.fuelCapacity)}}</td></tr>
        </tbody>
      </table>

      <h3 cds-text="subsection">Battery Specifications</h3>
      <table class="table table-vertical" aria-label="Platform battery specifications">
        <tbody>
          <tr><th scope="row">Number of Batteries</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.numBatteries)}}</td></tr>
          <tr><th scope="row">Battery Capacity (mAH)</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.batteryCapacity)}}</td></tr>
          <tr><th scope="row">Battery Voltage (v)</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.batteryVoltage)}}</td></tr>
          <tr><th scope="row">Battery Weight ({{unitLabels.weight}})</th><td>{{wrapWithUnits(internalPlatform.aircraftSpecs.batteryWeight)}}</td></tr>
          <tr><th scope="row">Battery Chemistry</th><td>{{internalPlatform.aircraftSpecs.batteryChemistry || 'Not Available'}}</td></tr>
        </tbody>
      </table>
    </div>

    <div class="clr-col-md-6">
      <h2 cds-text="section">Owner/Contact Information</h2>
      @if (internalPlatform.ownerContact) {
        <table class="table table-vertical" aria-label="Platform owner/contact information">
          <tbody>
            <tr>
              <th scope="row">Name</th>
              <td>{{internalPlatform.ownerContact.getName()}}</td>
            </tr>
            @if (internalPlatform.userId) {
              <tr>
                <th scope="row">User Id</th>
                <td>{{internalPlatform.userId}}</td>
              </tr>
            }
            <tr>
              <th scope="row">Organization</th>
              <td>{{internalPlatform.getPrettyOrganizationName()}}</td>
            </tr>
            <tr>
              <th scope="row">Phone Numbers</th>
              <td>
                <ul>
                  @for (phoneNumber of internalPlatform.ownerContact.phones; track $index) {
                    <li style="list-style: none;">{{phoneNumber}}</li>
                  }
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Email Addresses</th>
              <td>
                <ul>
                  @for (email of internalPlatform.ownerContact.emails; track $index) {
                    <li style="list-style: none;">{{email}}</li>
                  }
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      } @else {
        <p>Not Available</p>
      }

      @if (internalPlatform.integrations) {
        <div class="telemetry-integrations">
          <h2 cds-text="section">Integrations</h2>
          @if (internalPlatform.integrations.cot?.length) {
            <h3 cds-text="subsection">COT (Cursor on Target)</h3>
            @if (supportedIntegrations$() && !supportedIntegrations$().COT) {
              <ng-container *ngTemplateOutlet="unsupportedIntegrationMsg"></ng-container>
            }
            <table class="table table-compact" aria-label="COT integrations">
              <thead>
                <th>COT Call Sign</th>
                <th>COT UID</th>
                <th>Altitude Uses MSL</th>
              </thead>

              <tbody>
                @for (cot of internalPlatform.integrations.cot; track $index) {
                  <tr>
                    <td>{{cot.cotCallsign}}</td>
                    <td>{{cot.cotUid}}</td>
                    <td>{{cot.cotAltitudeUsesMsl ? 'Yes' : 'No'}}</td>
                  </tr>
                }
              </tbody>
            </table>
          }

          <!-- Rigitech Integrations List -->
          @if (internalPlatform.integrations.rigitech?.length) {
            <h3 cds-text="subsection">Rigitech</h3>
            @if (supportedIntegrations$() && !supportedIntegrations$().RIGITECH) {
              <ng-container *ngTemplateOutlet="unsupportedIntegrationMsg"></ng-container>
            }
            <table class="table table-compact" aria-label="Rigitech integrations">
              <thead>
                <th>Drone ID</th>
                <th>Serial Number</th>
                <th></th>
              </thead>

              <tbody>
                @for (rigitech of internalPlatform.integrations.rigitech; track $index) {
                  <tr>
                    <td>{{rigitech.rigitechDroneId}}</td>
                    <td>{{rigitech.rigitechSerialNumber}}</td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>
          }

          <!-- Mavlink Integrations List -->
          @if (internalPlatform.integrations.mavlink?.length) {
            <h3 cds-text="subsection">Mavlink</h3>
            @if (supportedIntegrations$() && !supportedIntegrations$().MAVLINK) {
              <ng-container *ngTemplateOutlet="unsupportedIntegrationMsg"></ng-container>
            }
            <table class="table table-compact" aria-label="Mavlink integrations">
              <thead>
                <th>Protocol</th>
                <th>Port</th>
                <th></th>
              </thead>

              <tbody>
                @for (mavlink of internalPlatform.integrations.mavlink; track $index) {
                  <tr>
                    <td>{{mavlink.protocol}}</td>
                    <td>{{mavlink.port}}</td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
      }
    </div>
  </div>
}

<ng-template #unsupportedIntegrationMsg>
  <p class="unsupported-integration-msg">
    <cds-icon shape="warning-standard" size="small" solid="true"></cds-icon>This integration is no longer supported by this USS.</p>
</ng-template>
