import {Error, isNullish, Result} from "./optional";
import {computed, Signal} from "@angular/core";


export interface ResultSignal<T, E> {
  value: Signal<T | undefined>;
  error: Signal<E | undefined>;
  empty: Signal<boolean>;
}

export function resultSignal<T, E extends Error<T>>(rawResult: Signal<Result<T, E> | null | undefined>): ResultSignal<T, E> {
  return {
    value: computed(() => {
      const value = rawResult();
      if (isNullish(value)) {
        return undefined;
      }
      return value.type === 'some' ? value.value : undefined;
    }),
    error: computed(() => {
      const value = rawResult();
      if (isNullish(value)) {
        return undefined;
      }
      return value.type === 'error' ? value as E : undefined;
    }),
    empty: computed(() => {
      const value = rawResult();
      return isNullish(value);
    })
  };
}


export function bufferSignal<T>(rawSignal: Signal<T>, bufferSize: number): Signal<T[]> {
  const buffer: T[] = [];
  return computed(() => {
    const value = rawSignal();
    buffer.push(value);
    if (buffer.length > bufferSize) {
      buffer.shift();
    }
    return buffer;
  });
}

export function withPrevious<T>(rawSignal: Signal<T>): Signal<[T|undefined, T]> {
  let prev: T | undefined = undefined;
  return computed(() => {
    const value = rawSignal();
    const result:[T|undefined, T] = [prev, value];
    prev = value;
    return result;
  });
}
