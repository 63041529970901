import {Injectable} from '@angular/core';
import {SpringAuthInterceptor} from './spring-auth-interceptor.service';
import {Stomp} from '@stomp/stompjs';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {RxStomp, RxStompConfig} from '@stomp/rx-stomp';
import {SettingsService} from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class StompService {
  private stompObservable: ReplaySubject<RxStomp | null>;

  private lastStomp: RxStomp;

  constructor(private springAuthInterceptor: SpringAuthInterceptor, private environmentService: SettingsService) {
    this.stompObservable = new ReplaySubject<RxStomp | null>(1);
    springAuthInterceptor.getAuthToken().pipe(map((token: string | null) => {
      // if (this.lastStomp) {
      //   this.lastStomp.deactivate();
      // }

      if (token === null) {
        this.lastStomp?.deactivate();
        this.lastStomp = null;
        return null;
      }
      if(!this.lastStomp){
        this.lastStomp = new RxStomp();
      }
      const rxStomp = this.lastStomp;

      const loc = window.location;
      let newURI: string;
      if (loc.protocol === 'https:') {
        newURI = 'wss:';
      } else {
        newURI = 'ws:';
      }
      newURI += '//' + loc.host;
      newURI += '/messageprovider';

      const stompConfig: RxStompConfig = {
        // Typically login, passcode and vhost
        // Adjust these for your broker
        connectHeaders: {
          Authorization: token
        },

        // Broker URL, should start with ws:// or wss:// - adjust for your broker setup
        brokerURL: newURI,

        // Keep it off for production, it can be quit verbose
        // Skip this key to disable
        /* eslint-disable-next-line */

        // If disconnected, it will retry after 200ms
        reconnectDelay: 200,
      };
      // Create an instance. The first RxStomp is the UMD module name and other is the class name


      // You can set additional configuration here
      rxStomp.configure(stompConfig);

      // Attempt to connect
      rxStomp.activate();
      this.lastStomp = rxStomp;
      return rxStomp;
    })).subscribe(this.stompObservable);
  }

  getStompClient(): Observable<RxStomp> {
    return this.stompObservable;
  }
}
