import {IOpVolumeSubmissionFG} from "./operation-geometry-editor.component";
import {featureCollection, polygon} from "@turf/turf";
import union from "@turf/union";
import {TimeRange} from "../../../model/TimeRange";
import {DateTime} from "luxon";
import {GeoCircle, Polygon} from "../../../model/gen/utm";
import {LatLng} from "leaflet";
import bbox from "@turf/bbox";
import {tryIt} from "../../../utils/optional";


export enum MergeMode {
  SINGLE = 'SINGLE',
  SMART = 'SMART',
}

function maxLengthFt(newGeometry: Polygon): number {
  const tmp = polygon(newGeometry.coordinates);
  const bb = bbox(tmp);
  const p1 = new LatLng(bb[1], bb[0]);
  const p2 = new LatLng(bb[3], bb[2]);
  return p1.distanceTo(p2);
}

interface MergeOptions {
  restrictLength: number;
  ignoreAltitude: boolean;
  mergeCircles: boolean;
}

function toFixed(num: number, precision = 8) {
  return Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

function trimPrecision(coordinates: number[][][]): number[][][] {
  return coordinates.map((c) => c.map((p) => p.map((n) => toFixed(n, 8))));
}

function mergeVolumes(volumes: IOpVolumeSubmissionFG[], options: MergeOptions) {
  let fallbackCurrentCircle: GeoCircle | null = null;
  let fallbackNextCircle: GeoCircle | null = null;

  const newVolumes = [];

  let currentVolume = volumes[0];
  newVolumes.push(currentVolume);

  const iterateVolumes = (nextVolume: IOpVolumeSubmissionFG) => {
    if (fallbackCurrentCircle) {
      currentVolume.circle = fallbackCurrentCircle;
      currentVolume.geography = null;
    }
    if (fallbackNextCircle) {
      nextVolume.circle = fallbackNextCircle;
      nextVolume.geography = null;
    }
    currentVolume = nextVolume;
    newVolumes.push(currentVolume);
  };


  for (let i = 1; i < volumes.length; i++) {
    fallbackCurrentCircle = currentVolume.circle;
    fallbackNextCircle = volumes[i].circle;

    if (currentVolume.circle && options.mergeCircles) {
      const points = currentVolume.circle.toLonLatCoordinates();
      currentVolume.geography = new Polygon({
        coordinates: [points]
      });
      currentVolume.circle = null;
    }
    if (volumes[i].circle && options.mergeCircles) {
      const points = volumes[i].circle.toLonLatCoordinates();
      volumes[i].geography = new Polygon({
        coordinates: [points]
      });
      volumes[i].circle = null;
    }

    if (
      currentVolume.geography === null ||
      volumes[i].geography === null ||
      !options.ignoreAltitude && !currentVolume.altitudeRange.equals(volumes[i].altitudeRange)
    ) {
      iterateVolumes(volumes[i]);
      continue;
    }

    let poly = [
      polygon(trimPrecision(currentVolume.geography.coordinates)),
      polygon(trimPrecision(volumes[i].geography.coordinates))
    ];

    const retRes = tryIt(() => union(featureCollection(poly)));
    if (retRes.type === 'error') {
      console.error('Error merging volumes', retRes.message);
      iterateVolumes(volumes[i]);
      continue;
    }
    const ret = retRes.value;


    if (ret.geometry.type !== "Polygon") {
      iterateVolumes(volumes[i]);
      continue;
    }

    if (ret.geometry.coordinates.length > 1) {
      ret.geometry.coordinates.splice(1);
    }

    const newGeometry = new Polygon({
      coordinates: ret.geometry.coordinates
    });

    if (options.restrictLength && maxLengthFt(newGeometry) > options.restrictLength) {
      iterateVolumes(volumes[i]);
      continue;
    }

    currentVolume.geography = newGeometry;
    currentVolume.altitudeRange.min_altitude = Math.min(currentVolume.altitudeRange.min_altitude, volumes[i].altitudeRange.min_altitude);
    currentVolume.altitudeRange.max_altitude = Math.max(currentVolume.altitudeRange.max_altitude, volumes[i].altitudeRange.max_altitude);

    if (!currentVolume.timeRange) {
      currentVolume.timeRange = new TimeRange();
    }
    if (!currentVolume.timeRange.start || !volumes[i].timeRange?.start) {
      currentVolume.timeRange.start = null;
    } else {
      currentVolume.timeRange.start = DateTime.min(currentVolume.timeRange.start, volumes[i].timeRange?.start);
    }
    if (!currentVolume.timeRange.end || !volumes[i].timeRange?.end) {
      currentVolume.timeRange.end = null;

    } else {
      currentVolume.timeRange.end = DateTime.max(currentVolume.timeRange.end, volumes[i].timeRange?.end);
    }

  }
  return newVolumes;
}


function singleMerge(volumes: IOpVolumeSubmissionFG[]): IOpVolumeSubmissionFG[] {
  const options = {
    restrictLength: 0,
    ignoreAltitude: true,
    mergeCircles: true,
  }
  return mergeVolumes(volumes, options);
}

function smartMerge(volumes: IOpVolumeSubmissionFG[]): IOpVolumeSubmissionFG[] {
  const options = {
    // restrictLength: Utm.maxOpVolSpatialDimensionFt,
    restrictLength: 0,
    ignoreAltitude: false,
    mergeCircles: false,
  }
  return mergeVolumes(volumes, options);
}


export function mergeOperationVolumes(mode: MergeMode, volumes: IOpVolumeSubmissionFG[]): IOpVolumeSubmissionFG[] {
  switch (mode) {
    case MergeMode.SINGLE:
      return singleMerge(volumes);
    case MergeMode.SMART:
      return smartMerge(volumes);
    default:
      console.error('Invalid merge mode', mode);
      return volumes;
  }


}
