import {
  Component,
  computed,
  effect,
  inject,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../../services/contact.service';
import {
  Altitude,
  enumValidator,
  faa_rule,
  GeoCircle,
  OperationActivationPolicy,
  OperationVolume,
  permitted_uas,
  Point,
  state,
  units_of_measure,
  vertical_reference
} from '../../../model/gen/utm';
import {OperationUtil} from '../../../model/OperationUtil';
import {UasRegistrationInfo} from '../../../model/UasRegistrationInfo';
import {OperationExt} from '../../../model/utm/OperationExt';
import {ConstraintTypeService} from '../../../services/constraint-type.service';
import {Contact} from '../../../model/Contact';
import {BehaviorSubject, combineLatest, Subscription, timer} from 'rxjs';
import {CurrentUserService} from '../../../services/current-user.service';
import {PlatformSearchSortField, PlatformService} from '../../../services/platform.service';
import {UserSettings, UserSettingsService} from '../../../services/user-settings.service';
import {SettingsService} from '../../../services/settings.service';
import {LatLngPoint} from '../../../model/WaypointParser';
import {Params, Router} from '@angular/router';
import {OperationService} from '../../../services/operation.service';
import {SituationalAwarenessService} from '../../../services/situational-awareness.service';
import {IOpVolumeSubmissionFG} from '../operation-geometry-editor/operation-geometry-editor.component';
import {AltitudeRange} from '../../../model/gen/utm/altitude-range-model';
import {SelectOption} from '../../../select-option';
import {OperationGeometry} from '../../../model/operation.geometry';
import {MeasurementSystemType} from '../../../model/MeasurementSystem';
import {IOperatorIntentEntry, OperatorIntentStatus} from '../../../model/OperatorIntentEntry';
import {OperationResponse} from '../../../services/rest/rest-operation.service';
import {humanizedOperatorIntentFailureReason, OperatorIntentFailureReason} from '../../../model/UserMessage/enums';
import {TimeRange} from '../../../model/TimeRange';
import {exhaustMap} from 'rxjs/operators';
import {ColorConfig, ColorService} from '../../../services/color.service';
import {forbiddenPatternRegexes, getPrettyDuration, invalidCharactersValidator} from '../../../utils/Validators';
import {DateTime} from 'luxon';
import {OperatorIntentService} from '../../../services/operator-intent.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DEFAULT_OPERATION_ACTIVATION} from '../../../constants';
import {IOperationVolumeSubmission} from '../../../model/OperationSubmission';
import {MeasurementSystemPreferenceService} from '../../../services/measurement-system-preference.service';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {isNil} from 'lodash';
import {importExportServiceProviders} from '../../../services/import-export/import-export-service.module';
import {ResponsiveScreenService} from '../../../services/responsive-screen.service';
import {IFederationPolicy} from "../../../model/FederationPolicy";
import {CreateOperationMode} from "../../../../fuss/operations/new-operations/new-operations.component";
import {requiredWhiteSpace} from '@ax-uss-ui/common';

export interface CreateOperationFormValues {
  /* eslint-disable @typescript-eslint/naming-convention */
  loadDrafts: string;
  platform: UasRegistrationInfo;
  operationName: string;
  operationDescription: string;
  pilot: Contact;
  operationGeometry: IOpGeoSubmissionFG;
  operationType: permitted_uas;
  public_access: boolean;
  faarule: faa_rule;
  visibility: string;
  permittedConstraintType: string;
  federationPolicy: IFederationPolicy;
  priority: number;
  altitude_vertical_reference: vertical_reference;
  altitude_units: units_of_measure;
  activation: OperationActivationPolicy;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export interface IOpGeoSubmissionFG {
  volumes: IOpVolumeSubmissionFG[];
  points?: LatLngPoint[];
  controllerLocation?: LatLngPoint;
  takeOffLocation?: LatLngPoint;
  manualControllerLocation?: LatLngPoint;
  manualTakeOffLocation?: LatLngPoint;
}

interface OperationSubmissionStatusDetails {
  modalHeading: string;
  summary: string;
  percent: number;
  success: boolean;
  intent: IOperatorIntentEntry;
}

@Component({
  selector: 'app-create-operation',
  templateUrl: './create-operation.component.html',
  styleUrls: ['./create-operation.component.scss'],
  providers: [{
    provide: SituationalAwarenessService
  },
    {
      provide: MeasurementSystemPreferenceService
    },
    ...importExportServiceProviders
  ]
})
export class CreateOperationComponent implements OnInit, OnChanges, OnDestroy {
  // @ViewChild('draftSelectorComponent') draftSelectorComponent: DraftSelectorComponent; // Drafts
  @Input() operation: OperationExt;
  @Input() draftId: string;
  submissionMode$ = input.required<CreateOperationMode>();
  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;
  ussSettings$ = toSignal(inject(SettingsService).getRawSettings());

  measurementSystemPreferenceService = inject(MeasurementSystemPreferenceService);

  formGroup = new FormGroup({
    /* eslint-disable @typescript-eslint/naming-convention */
    loadDrafts: new FormControl<string>(null),
    platform: new FormControl<UasRegistrationInfo>(null, [Validators.required]),
    operationName: new FormControl<string>('', [requiredWhiteSpace, Validators.maxLength(100),
      invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpaces)]),
    operationDescription: new FormControl<string>('', [requiredWhiteSpace, Validators.maxLength(1000),
      invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpaces)]),
    pilot: new FormControl<Contact>(null, [Validators.required]),
    // beginTime: tmpBeginTimeControl,
    // endTime: tmpEndTimeControl,
    operationGeometry: new FormControl<IOpGeoSubmissionFG>(null, [Validators.required]),
    // OperationVolumeEditorValidator(), OperationVolumeContinuityValidator(true)]),
    operationType: new FormControl<permitted_uas>(permitted_uas.NOT_SET, [Validators.required]),
    public_access: new FormControl<boolean>(true),
    faarule: new FormControl<faa_rule>(faa_rule.OTHER, [Validators.required]),
    visibility: new FormControl<string>('bvlos', [Validators.required]),
    permittedConstraintType: new FormControl<string>(null, []),
    federationPolicy: new FormControl<IFederationPolicy>({allowAll: false, domains: []}, [Validators.required]),
    priority: new FormControl<number>(0, [Validators.required, Validators.min(0), Validators.max(100)]),
    altitude_vertical_reference: new FormControl<vertical_reference>(vertical_reference.W84, [Validators.required, enumValidator(vertical_reference)]),
    altitude_units: new FormControl<units_of_measure>(units_of_measure.FT, [Validators.required, enumValidator(units_of_measure)]),
    activation: new FormControl<OperationActivationPolicy>(DEFAULT_OPERATION_ACTIVATION, [Validators.required]),
    /* eslint-disable @typescript-eslint/naming-convention */
  });

  availableContacts: Contact[];
  availablePlatformsSubject: BehaviorSubject<UasRegistrationInfo[]> = new BehaviorSubject<UasRegistrationInfo[]>([]);

  availableConstraintTypes: { name: string; value: string }[] = [];
  loadingPermittedConstraintTypes: boolean;
  federationPolicyAccordionExpanded = false;

  displayOpSubmissionProgressModal = false;
  displayOpSubmissionProgressBar = false;
  opSubmissionStatusDetails: OperationSubmissionStatusDetails;
  humanizedOpIntentFailureReason = humanizedOperatorIntentFailureReason;
  priorityIter: number[] = [0, 10, 20, 30, 40];
  mode = CreateOperationMode;
  colorConfig: ColorConfig;
  userSettings: UserSettings;

  verticalReferenceIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(vertical_reference)) {
      ret.push({label: option, value: vertical_reference[option]});
    }
    return ret;
  })();
  unitsOfMeasureIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(units_of_measure)) {
      if (option === 'M' || option === 'FT') {
        ret.push({label: option, value: units_of_measure[option]});
      }
    }
    return ret;
  })();
  activationOptions: { name: string; value: OperationActivationPolicy }[] = Object.keys(OperationActivationPolicy)
    .map(key => ({
      name: OperationUtil.humanizeOperationActivationPolicy(OperationActivationPolicy[key]),
      value: OperationActivationPolicy[key]
    }));

  currentUnitsOfMeasure$ = toSignal(this.formGroup.controls.altitude_units.valueChanges, {
    initialValue: this.formGroup.controls.altitude_units.value
  });
  modificationModeText$ = computed(() => {
    switch (this.submissionMode$()) {
      case CreateOperationMode.replan :
      case CreateOperationMode.rerouteAccepted:
      case CreateOperationMode.rerouteActive:
        return 'Replan';
      case CreateOperationMode.clone:
      case CreateOperationMode.none:
      default:
        return 'Create';
    }
  });

  private submissionStartTime: DateTime | null;
  private clearForm = false;
  private currentGeometry: OperationGeometry;
  private clonedContactIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private clonedPlatformIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private intentSub: Subscription;
  private opStateSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              private contactService: ContactService,
              private operationService: OperationService,
              // private operationDraftService: OperationDraftService,
              private constraintTypeService: ConstraintTypeService,
              private userSettingsService: UserSettingsService,
              private currentUserService: CurrentUserService,
              private platformService: PlatformService,
              private colorService: ColorService,
              private operatorIntentService: OperatorIntentService) {

    effect(() => {
      // const tmp = this.measurementSystemPreferenceService.measurementSystem$();
      const currentUnits = this.currentUnitsOfMeasure$();
      switch (currentUnits) {
        case units_of_measure.M:
          this.measurementSystemPreferenceService.overrideUserPreferenceMeasurementSystem$.set(MeasurementSystemType.METRIC);
          break;
        case units_of_measure.FT:
          this.measurementSystemPreferenceService.overrideUserPreferenceMeasurementSystem$.set(MeasurementSystemType.IMPERIAL);
          break;
        default:
          console.error('Unknown units of measure');

      }

    }, {allowSignalWrites: true});

    // Handle USS settings retrieval
    effect(() => {
      if (this.ussSettings$()) {
        // Set the default federation policy
        const isDirty: boolean = this.formGroup.dirty;
        this.formGroup.controls.federationPolicy.setValue(this.ussSettings$()?.defaultFederationPolicy);
        // If the form wasn't dirty prior to setting the federation policy, mark it as pristine to restore its status
        if (!isDirty) {
          this.setPristineUntouchedState();
        }
      }
    });

    // Get user settings
    this.subscriptions.push(this.userSettingsService.getRawSettings().subscribe((rawSettings) => {
      this.userSettings = rawSettings;
      // Get clear form after submission setting
      this.clearForm = rawSettings.clearFormAfterSubmission.operationSubmission;

      if (!this.operation) {
        // Get measurement system setting
        switch (rawSettings.measurementSystemType) {
          case MeasurementSystemType.METRIC:
            this.formGroup.controls.altitude_units.setValue(units_of_measure.M);
            break;
          case MeasurementSystemType.IMPERIAL:
            this.formGroup.controls.altitude_units.setValue(units_of_measure.FT);
            break;
        }

        // Get vertical reference setting
        this.formGroup.controls.altitude_vertical_reference.setValue(rawSettings.defaultVerticalReference);

        this.formGroup.controls.activation.setValue(rawSettings.operationActivation);
      }
    }));

    this.fetchPermittedConstraintTypes();

    // Populate the pilot dropdown
    this.subscriptions.push(combineLatest([
      this.contactService.getAllContacts({isPilot: true}),
      this.currentUserService.currentUser,
      this.clonedContactIdSubject
    ]).subscribe(([contacts, currentUser, clonedContactId]) => {
      this.availableContacts = contacts;

      if (clonedContactId) {
        // If a cloned contact ID is provided and the contact exists, set the pilot field to that contact ID
        const clonedContact = this.availableContacts.find(c => c.userContact && c.id === clonedContactId);
        if ( clonedContact) {
          this.formGroup.controls.pilot.setValue(clonedContact);
        } else if (this.submissionMode$() === CreateOperationMode.rerouteAccepted ||
          this.submissionMode$() === CreateOperationMode.rerouteActive) {
          // If the operation is being rerouted and the contact has been deleted, set the pilot field to the deleted contact.
          // Note: The pilot field is unchanged when rerouting, so it is permissible to pass the deleted contact into this field.
          this.formGroup.controls.pilot.setValue(new Contact({firstName: 'Deleted', lastName: 'User', id: clonedContactId}));
        } else {
          // Otherwise, if the cloned contact has been deleted and the operation isn't being rerouted, clear the pilot field
          this.formGroup.controls.pilot.setValue(null);
        }
        // If the pilot field is empty, set its value to the current user if they have the pilot role
      } else if (!this.formGroup.controls.pilot.value
        && (currentUser?.roles || []).find(r => r.toUpperCase() === 'PILOT')) {
        this.formGroup.controls.pilot.setValue(this.availableContacts.find(c => c.userContact === true && c.userId === currentUser.uid));
      }
    }));

    // Populate the platforms dropdown
    this.platformService.getAllPlatforms({
      archived: false, sort: [PlatformSearchSortField.name],
      sortIncreasing: true
    }).pipe(takeUntilDestroyed())
      .subscribe(platforms => {
        this.availablePlatformsSubject.next(platforms);
      });

    // If a cloned platform ID is provided and it exists in the list of available platforms, set the platform field to that ID
    combineLatest([
      this.clonedPlatformIdSubject,
      this.availablePlatformsSubject
    ]).pipe(takeUntilDestroyed())
      .subscribe(([clonedPlatformId, platforms]) => {
      const platform = platforms.find(p => p.uvin === clonedPlatformId);
      if (!!platform && !platform.archived) {
        this.formGroup.controls.platform.setValue(platform);
      }
    });

    // Set the geometry to be displayed in the Cesium preview
    this.subscriptions.push(this.formGroup.controls.operationGeometry.valueChanges.subscribe(() => {
      if (this.formGroup.controls.operationGeometry.valid) {
        this.currentGeometry = this.convertOpGeomFGtoOpGeom(this.formGroup.controls.operationGeometry.value);
      }
    }));

    this.subscriptions.push(this.colorService.getColorForId('operation', true).subscribe(config => this.colorConfig = config));

    // Drafts: Hide draft overflow buttons if operation name field is empty
    // this.subscriptions.push(this.formGroup.controls.operationName.valueChanges.subscribe(res => {
    //   if (!res && this.overflowState === true) {
    //     this.setOverflowState(false);
    //   }
    // }));
  }

  ngOnInit(): void {
    // Drafts: Load draft if a draft ID is passed into the component as input
    // if (this.draftId) {
    //   this.loadDraftOperation(this.draftId);
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.operation && changes.operation.currentValue) {
      this.displayOpSubmissionProgressBar = false;
      this.setValues(changes.operation.currentValue as OperationExt);
    }
  }

  ngOnDestroy(): void {
    this.intentSub?.unsubscribe();
    this.opStateSub?.unsubscribe();

    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        if (!subscription.closed) {
          subscription.unsubscribe();
        }
      }
    }
  }

  submitOperation() {
    const rawValues = this.formGroup.getRawValue();
    let controllerLocation: Point;
    if (rawValues.operationGeometry.controllerLocation) {
      const tmpControllerLocation = rawValues.operationGeometry.controllerLocation;
      controllerLocation = Point.fromLatLong(tmpControllerLocation.lat, tmpControllerLocation.lng);
    }
    let takeoffLocation: Point;
    if (rawValues.operationGeometry.takeOffLocation) {
      const tmpTakeOffLocation = rawValues.operationGeometry.takeOffLocation;
      takeoffLocation = Point.fromLatLong(tmpTakeOffLocation.lat, tmpTakeOffLocation.lng);
    }

    const tmp = OperationUtil.extractIOperationSubmission(
      rawValues,
      rawValues.pilot,
      controllerLocation,
      takeoffLocation,
      this.submissionMode$() === CreateOperationMode.replan ? this.operation.operator_intent_id : null,
      this.submissionMode$() === CreateOperationMode.replan ? this.operation.operationId : null
    );

    this.opSubmissionStatusDetails = {
      modalHeading: 'Submitting Operation...',
      summary: 'Submitting Operation...',
      percent: 0,
      success: undefined,
      intent: undefined
    };
    this.displayOpSubmissionProgressModal = true;
    this.displayOpSubmissionProgressBar = true;
    this.subscriptions.push(this.operationService.createOperation(tmp).subscribe((res: OperationResponse) => {
      this.submissionStartTime = this.getEarliestVolStart(tmp.volumes);
      this.watchIntentId(res.intentId);
      if (this.clearForm && this.submissionMode$() !== CreateOperationMode.replan) {
        this.resetForm();
      } else {
        this.setPristineUntouchedState();
      }
      // Drafts: Delete draft on successful operation creation
      // if (this.draftId) {
      //   this.subscriptions.push(this.operationDraftService.deleteDraft(this.draftId).subscribe((res) => {
      //     if (res && this.clearForm) {
      //       this.clearLoadedDraft();
      //     }
      //     this.draftId = null;
      //     this.formGroup.controls.loadDrafts.setValue(this.draftId);
      //     this.router.navigate(['fuss', 'operations', 'newop'], {queryParams: {draftId: undefined}});
      //     // this.draftSelectorComponent.refreshDrafts();
      //
      //   }));
      // }
    }, (error: HttpErrorResponse) => {
      this.opSubmissionStatusDetails = {
        modalHeading: 'Submission Error',
        summary: error?.error?.message || 'Unknown Error Submitting Operation',
        percent: 100,
        success: false,
        intent: undefined
      };
    }));

  }

  modifyOperation() {
    const rawValues = this.formGroup.getRawValue();
    let controllerLocation: Point;
    if (rawValues.operationGeometry.controllerLocation) {
      const tmpControllerLocation = rawValues.operationGeometry.controllerLocation;
      controllerLocation = Point.fromLatLong(tmpControllerLocation.lat, tmpControllerLocation.lng);
    }
    let takeoffLocation: Point;
    if (rawValues.operationGeometry.takeOffLocation) {
      const tmpTakeOffLocation = rawValues.operationGeometry.takeOffLocation;
      takeoffLocation = Point.fromLatLong(tmpTakeOffLocation.lat, tmpTakeOffLocation.lng);
    }

    const tmp = OperationUtil.extractIOperationModification(
      rawValues,
      controllerLocation,
      takeoffLocation,
      this.operation.operator_intent_id,
      this.operation.operationId);

    this.opSubmissionStatusDetails = {
      modalHeading: 'Submitting Operation...',
      summary: 'Submitting Operation...',
      percent: 0,
      success: undefined,
      intent: undefined
    };
    this.displayOpSubmissionProgressModal = true;
    this.displayOpSubmissionProgressBar = true;
    this.subscriptions.push(this.operationService.modifyOperation(this.operation.operationId, tmp).subscribe((res: OperationResponse) => {
      this.submissionStartTime = this.getEarliestVolStart(tmp.volumes);
      this.watchIntentId(res.intentId);
      this.setPristineUntouchedState();
    }, (error: HttpErrorResponse) => {
      this.opSubmissionStatusDetails = {
        modalHeading: 'Submission Error',
        summary: error?.error?.message || 'Unknown Error Replanning Operation',
        percent: 100,
        success: false,
        intent: undefined
      };
    }));
  }

  replanOperation() {
    const mode: CreateOperationMode = (this.submissionMode$() === CreateOperationMode.rerouteAccepted ||
      this.submissionMode$() === CreateOperationMode.rerouteActive) ?
      this.submissionMode$() : CreateOperationMode.replan;
    const queryParams: Params = {operationId: this.opSubmissionStatusDetails.intent.intentResult.resultEntityId, mode};
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['fuss', 'operations', 'newop'], {queryParams});
    }).catch(error => console.error(error));
  }

  updateAltRef($event: vertical_reference) {
    this.formGroup.controls.altitude_vertical_reference.setValue($event);
  }

  updateAltUnits($event: units_of_measure) {
    this.formGroup.controls.altitude_units.setValue($event);

  }

  clearSelectedPlatform(): void {
    this.formGroup.controls.platform.setValue(null);
    this.formGroup.controls.platform.markAsTouched();
    this.formGroup.controls.platform.updateValueAndValidity();
  }

  clearSelectedPilot(): void {
    this.formGroup.controls.pilot.setValue(null);
    this.formGroup.controls.pilot.markAsTouched();
    this.formGroup.controls.pilot.updateValueAndValidity();
  }

  private fetchPermittedConstraintTypes($event?: string) {
    this.loadingPermittedConstraintTypes = true;
    this.subscriptions.push(this.constraintTypeService.fetchConstraintTypes($event).subscribe(types => {
      const knownNames = new Set<string>();
      const constraintTypes = new Set<{ name: string; value: string }>();
      if ($event) {
        constraintTypes.add({name: $event, value: $event});
        knownNames.add($event);
      }
      for (const t of types) {
        if (knownNames.has(t.name) || ($event && !t.name.includes($event)) ||
          t.name === 'Restricted' || t.name === 'Advisory' || t.name === 'Approval Required') {
          continue;
        }
        knownNames.add(t.name);
        constraintTypes.add(t);
      }
      this.availableConstraintTypes = [{name: 'None', value: null}, ...constraintTypes];
      this.loadingPermittedConstraintTypes = false;
    }));
  }

  private setValues(values: OperationExt) {
    this.formGroup.controls.operationName.setValue(this.submissionMode$() === CreateOperationMode.clone ? values.flight_number + ' (copy)' : values.flight_number);
    this.formGroup.controls.operationDescription.setValue(values.flight_comments);
    this.formGroup.controls.priority.setValue(values.priority);

    if (values.contact_id) {
      this.clonedContactIdSubject.next(values.contact_id);
    }

    if (values.uas_registrations?.length && values.uas_registrations[0].registration_id !== undefined) {
      this.clonedPlatformIdSubject.next(values.uas_registrations[0].registration_id);
    }

    this.formGroup.controls.permittedConstraintType.setValue(values.additional_data.permitted_constraint_types[0] || null);

    const controllerLocation: LatLngPoint = values.controller_location ? {
      lat: values.controller_location.getLatitude(),
      lng: values.controller_location.getLongitude(),
      alt: 0
    } : undefined;

    const takeOffLocation: LatLngPoint = values.takeoff_location ? {
      lat: values.takeoff_location.getLatitude(),
      lng: values.takeoff_location.getLongitude(),
      alt: 0
    } : undefined;

    const isAltitudeComplete = (alt: Altitude): boolean => ((!!alt.altitude_value || alt.altitude_value === 0) &&
      !!alt.units_of_measure && !!alt.vertical_reference);

    const geometry: IOpGeoSubmissionFG = {
      volumes: values.operation_volumes.map(vol => {
        const useSubmittedRadius = !isNil(vol.submitted_radius?.radius) && !!vol.submitted_radius?.units_of_measure;
        return {
          circle: vol.circle ? new GeoCircle({
            latitude: vol.circle.latitude,
            longitude: vol.circle.longitude,
            radius: useSubmittedRadius ? vol.submitted_radius.radius : vol.circle.radius,
            units: useSubmittedRadius ? vol.submitted_radius.units_of_measure : vol.circle.units
          }) : null,
          geography: !vol.circle ? vol.geography : null,
          altitudeRange: (isAltitudeComplete(vol.submitted_min_altitude) && isAltitudeComplete(vol.submitted_max_altitude)) ?
            new AltitudeRange({
              min_altitude: vol.submitted_min_altitude.altitude_value,
              max_altitude: vol.submitted_max_altitude.altitude_value,
              altitude_vertical_reference: vol.submitted_min_altitude.vertical_reference,
              altitude_units: vol.submitted_min_altitude.units_of_measure
            }) :
            new AltitudeRange({
              min_altitude: vol.min_altitude.altitude_value,
              max_altitude: vol.max_altitude.altitude_value,
              altitude_vertical_reference: vol.min_altitude.vertical_reference,
              altitude_units: vol.min_altitude.units_of_measure
            }),
          timeRange: new TimeRange(vol.effective_time_begin, vol.effective_time_end),
          offNominal: vol.off_nominal
        } as IOpVolumeSubmissionFG;
      }),
      points: [],
      controllerLocation,
      takeOffLocation
    };

    this.formGroup.controls.altitude_vertical_reference.setValue(geometry.volumes[0].altitudeRange?.altitude_vertical_reference);
    this.formGroup.controls.altitude_units.setValue(geometry.volumes[0].altitudeRange?.altitude_units);
    this.formGroup.controls.public_access.setValue(values.additional_data.public_access);

    if (values.activation) {
      this.formGroup.controls.activation.setValue(values.activation);
    }

    const stateColor = values.state === state.ACTIVATED && this.submissionMode$() !== CreateOperationMode.clone ? values.state : state.ACCEPTED;
    this.subscriptions.push(this.colorService.getColorForId(this.colorService.getIdForState(stateColor), true).subscribe(config => this.colorConfig = config));
    this.formGroup.controls.operationGeometry.setValue(geometry);

    // If rerouting an operation, disable all form controls except for the geometry editor
    if (this.submissionMode$() === CreateOperationMode.rerouteAccepted || this.submissionMode$() === CreateOperationMode.rerouteActive) {
      this.formGroup.controls.operationName.disable();
      this.formGroup.controls.operationDescription.disable();
      this.formGroup.controls.priority.disable();
      this.formGroup.controls.pilot.disable();
      this.formGroup.controls.platform.disable();
      this.formGroup.controls.permittedConstraintType.disable();
      this.formGroup.controls.activation.disable();
    }

    this.markFormAsTouched(this.formGroup);
  }

  private setPristineUntouchedState(): void {
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  private setOperationSubmissionStatus(intent: IOperatorIntentEntry) {
    switch (intent.intentStatus) {
      case OperatorIntentStatus.RECEIVED:
        this.opSubmissionStatusDetails = {
          modalHeading: 'Submitting Operation...',
          summary: 'Operation Received',
          percent: 33,
          success: undefined,
          intent
        };
        break;
      case OperatorIntentStatus.PROCESSING:
        this.opSubmissionStatusDetails = {
          modalHeading: 'Submitting Operation...',
          summary: 'Processing Operation...',
          percent: 66,
          success: undefined,
          intent
        };
        break;
      case OperatorIntentStatus.PENDING_APPROVAL:
        this.intentSub?.unsubscribe();
        this.opSubmissionStatusDetails = {
          modalHeading: 'Operation Pending Approval',
          summary: 'A notification will be sent when the operation is approved or denied.',
          percent: 100,
          success: undefined,
          intent
        };
        break;
      case OperatorIntentStatus.FINISHED:
        this.intentSub?.unsubscribe();
        if (intent.intentResult.success) {
          const beginTimeOffset = this.submissionStartTime ? getPrettyDuration(this.submissionStartTime.diffNow()) : 'ASAP';
          let beginTimeSummary = '';
          if (this.submissionStartTime && this.submissionStartTime <= DateTime.now()) {
            beginTimeSummary = `began ${beginTimeOffset} ago`;
          } else {
            beginTimeSummary = `will begin ${beginTimeOffset === 'ASAP' ? beginTimeOffset : 'in ' + beginTimeOffset}`;
          }
          this.opSubmissionStatusDetails = {
            modalHeading: 'Operation Submitted',
            summary: `Operation accepted and ${beginTimeSummary}`,
            percent: 100,
            success: true,
            intent
          };
        } else {
          const failureReason = intent.intentResult.failureReason;
          this.opSubmissionStatusDetails = {
            modalHeading: 'Operation Submission Failed',
            summary: 'Operation ' + (failureReason === OperatorIntentFailureReason.CONFLICT ? 'Rejected' : `Submission Failed (${humanizedOperatorIntentFailureReason[failureReason]})`),
            percent: 100,
            success: false,
            intent
          };
        }
        break;
      default:
        this.opSubmissionStatusDetails = {
          modalHeading: 'Operation Submission Status Unknown',
          summary: 'Unknown',
          percent: 0,
          success: undefined,
          intent: undefined,
        };
    }
  }

  private markFormAsTouched(formGroup: FormGroup): void {
    formGroup.updateValueAndValidity();
    formGroup.markAllAsTouched();
    for (const controlKey of Object.keys(formGroup.controls)) {
      if (formGroup.controls[controlKey].markAsTouched) {
        formGroup.controls[controlKey].markAsTouched();
        formGroup.controls[controlKey].updateValueAndValidity();
      }
    }
  }

  private convertOpGeomFGtoOpGeom(opGeomFG: IOpGeoSubmissionFG): OperationGeometry {
    const vols: OperationVolume[] = opGeomFG.volumes.map(vol => new OperationVolume({
      effective_time_begin: vol.timeRange.start,
      effective_time_end: vol.timeRange.end,
      min_altitude: vol.altitudeRange.getMinAlt(),
      max_altitude: vol.altitudeRange.getMaxAlt(),
      geography: vol.geography
    }));
    return new OperationGeometry(vols, opGeomFG.points, opGeomFG.controllerLocation, opGeomFG.takeOffLocation);
  }

  private watchIntentId(intentId: string) {
    this.intentSub?.unsubscribe();

    this.intentSub = timer(100, 1000).pipe(exhaustMap(() => this.operatorIntentService.getOperatorIntent(intentId))).subscribe(res => {
      if (res.intentStatus !== this.opSubmissionStatusDetails?.intent?.intentStatus) {
        this.setOperationSubmissionStatus(res);
      }
    });
  }

  private getEarliestVolStart(vols: IOperationVolumeSubmission[]): DateTime | null {
    const now = DateTime.now();
    let earliestTime = vols[0].effective_time_begin || now;
    vols.forEach(vol => {
      const volumeTime = vol.effective_time_begin || now;
      if (volumeTime < earliestTime) {
        earliestTime = volumeTime;
      }
    });
    return earliestTime === now ? null : earliestTime;
  }

  private resetForm() {
    this.formGroup.reset({
      federationPolicy: {allowAll: false, domains: []} as IFederationPolicy,
      /* eslint-disable @typescript-eslint/naming-convention */
      loadDrafts: null,
      platform: null,
      operationName: '',
      operationDescription: '',
      pilot: null,
      operationGeometry: null,
      operationType: permitted_uas.NOT_SET,
      public_access: true,
      faarule: faa_rule.OTHER,
      visibility: 'bvlos',
      permittedConstraintType: null,
      priority: 0,
      altitude_vertical_reference: this.userSettings?.defaultVerticalReference || vertical_reference.W84,
      altitude_units: this.userSettings?.measurementSystemType === MeasurementSystemType.METRIC ? units_of_measure.M : units_of_measure.FT,
      activation: DEFAULT_OPERATION_ACTIVATION
      /* eslint-disable @typescript-eslint/naming-convention */
    });
    this.setPristineUntouchedState();
    this.draftId = null;
  }

  // Draft Functionality

  // saveDraftOperation(continueDraft: boolean): void {
  //   this.setOverflowState(false);
  //   const rawValues = this.formGroup.getRawValue();
  //   const timeRawValues = this.timeRangeFormGroup.getRawValue();
  //   const metadata: IEventMetadata = {
  //     call_sign: rawValues?.callSign,
  //     // data_quality_engineer: '',
  //     // free_text: '',
  //     // modified: false,
  //     data_collection: rawValues?.data_collection,
  //     event_id: rawValues?.event_id,
  //     scenario: rawValues?.scenario,
  //     test_card: rawValues?.test_card,
  //     test_run: rawValues?.test_run,
  //     test_type: rawValues?.test_type,
  //     source: rawValues?.source,
  //     setting: rawValues?.setting,
  //     location: rawValues?.location
  //   };
  //
  //   const geometry = this.parseGeometry(rawValues.operationGeometry);
  //
  //   const tmpControllerLocation = geometry.getControllerLocation();
  //   const controllerLocation = tmpControllerLocation ? Point?.fromLatLong(tmpControllerLocation?.lat, tmpControllerLocation?.lng) : undefined;
  //
  //   // const hasVolumes = !!rawValues?.operationGeometry?.getVolumes()?.[0];
  //   const tmpTakeOffLocation = geometry.getTakeOffLocation();
  //   const takeOffLocation = tmpTakeOffLocation ? Point?.fromLatLong(tmpTakeOffLocation?.lat, tmpTakeOffLocation?.lng) : undefined;
  //
  //   const isNew = !this.draftId;
  //
  //   // If operation name is empty, do not save the draft
  //   if (!rawValues.operationName) {
  //     console.error('Operation name is missing. Aborting draft save...');
  //     this.sendMessage(false, OperationDraftFailureReason.MISSING_NAME, isNew);
  //   } else {
  //     // Assemble draft as a partial submission
  //     const tmp: Partial<IOperationSubmission> = OperationUtil.extractPartialIOperationSubmission(
  //       rawValues?.platform,
  //       rawValues?.operationName,
  //       rawValues?.operationDescription,
  //       rawValues.pilot ? this?.availableContacts.find(c => c.id === rawValues.pilot) : undefined,
  //       rawValues.pilot,
  //       rawValues?.operationType,
  //       rawValues?.public_access,
  //       controllerLocation,
  //       takeOffLocation,
  //       // [rawValues.contingencyPlan],
  //       rawValues?.operationGeometry?.getVolumes(),
  //       timeRawValues?.beginTime?.toUTC(),
  //       timeRawValues?.endTime?.toUTC(),
  //       rawValues?.visibility === 'bvlos',
  //       rawValues?.faarule,
  //       metadata,
  //       (rawValues?.permittedConstraintType) ? [rawValues?.permittedConstraintType] : [],
  //       rawValues?.federationPolicy,
  //       rawValues?.priority
  //     );
  //     this.savingDraftOperation = true;
  //
  //     this.subscriptions.push(this.operationDraftService.saveDraftOperation(this.draftId, tmp).subscribe(res => {
  //       this.savingDraftOperation = false;
  //
  //       if (res.success === true) {
  //         this.draftId = res.id;
  //
  //         this.formGroup.controls.loadDrafts.setValue(this.draftId);
  //
  //         if (continueDraft) {
  //           this.router.navigate(['fuss', 'operations', 'newop'], {queryParams: {draftId: res}});
  //           // Clear required validation errors after save
  //           Object.keys(this.formGroup.controls).forEach(key => {
  //             this.formGroup.get(key).setErrors(null);
  //           });
  //         } else {
  //           this.clearLoadedDraft();
  //         }
  //         // this.draftSelectorComponent.refreshDrafts();
  //         this.sendMessage(true, null, isNew);
  //         this.setPristineUntouchedState();
  //       } else {
  //         console.error('Error: Draft not saved');
  //         this.sendMessage(false, OperationDraftFailureReason.GENERAL, isNew);
  //       }
  //     }, () => {
  //       this.savingDraftOperation = false;
  //       console.error('Error: Draft not saved');
  //       this.sendMessage(false, OperationDraftFailureReason.GENERAL, isNew);
  //     }));
  //   }
  // }

  // updateOperation() {
  //   /*const constraint = new ConstraintSubmission(this.formGroup.getRawValue());
  //   this.submittingConstraint = true;
  //   this.subscriptions.push(this.constraintService.submitConstraint(constraint).subscribe((res) => {
  //     this.submittingConstraint = false;
  //     this.submitConstraintSuccess = true;
  //   }, () => {
  //     this.submittingConstraint = false;
  //   }));*/
  // }

  // loadDraftOperation(draftId: string): void {
  //   // Set the loaded draft to the newly selected draft
  //   if (draftId) {
  //     this.resetForm();
  //
  //     // If a different draft ID is being loaded, update this.draftID with the new value
  //     if (this.draftId !== draftId) {
  //       this.draftId = draftId;
  //       this.formGroup.controls.loadDrafts.setValue(this.draftId);
  //
  //     }
  //     this.operationDraftLoadSub?.unsubscribe();
  //     this.operationDraftLoadSub = this.operationDraftService.getDraftFromUUID(draftId).subscribe((loadedDraft) => {
  //       if (!loadedDraft) {
  //         this.clearLoadedDraft();
  //         return;
  //       }
  //       this.loadedDraft = loadedDraft;
  //       // const loadedDraft = this.operationDraftService.getDraftFromUUID(draftId);
  //       if (loadedDraft) {
  //         const loadedDraftSubmission = loadedDraft.submission;
  //         // Get operation volumes
  //         this.currentVolumes = loadedDraftSubmission?.operation_volumes as OperationVolume[] || [];
  //
  //         // Get controller coordinates
  //         let controllerLocation: LatLngPoint;
  //         if (loadedDraftSubmission?.controller_location) {
  //           const lng: number = loadedDraftSubmission.controller_location.coordinates[0];
  //           const lat: number = loadedDraftSubmission.controller_location.coordinates[1];
  //           controllerLocation = {
  //             lng,
  //             alt: undefined,
  //             lat
  //           };
  //         } else {
  //           controllerLocation = {
  //             lng: null,
  //             alt: undefined,
  //             lat: null
  //           };
  //         }
  //
  //         let takeoffLocation: LatLngPoint;
  //         if (loadedDraftSubmission?.takeoff_location) {
  //           const lng: number = loadedDraftSubmission.takeoff_location.coordinates[0];
  //           const lat: number = loadedDraftSubmission.takeoff_location.coordinates[1];
  //           takeoffLocation = {
  //             lng,
  //             alt: undefined,
  //             lat
  //           };
  //         } else {
  //           takeoffLocation = {
  //             lng: null,
  //             alt: undefined,
  //             lat: null
  //           };
  //         }
  //
  //         // Get new operation geometry
  //         const newOpGeom = new OperationGeometry(loadedDraftSubmission?.operation_volumes as OperationVolume[], [], controllerLocation, takeoffLocation);
  //
  //         // Get platform
  //         if (loadedDraftSubmission?.uas_registrations && loadedDraftSubmission?.uas_registrations.length > 0
  //           && loadedDraftSubmission?.uas_registrations[0].registration_id !== undefined) {
  //           this.subscriptions.push(this.platformService.getPlatform(loadedDraftSubmission.uas_registrations[0].registration_id).subscribe((platform) => {
  //             this.formGroup.controls.platform.setValue(platform);
  //           }));
  //         }
  //
  //         // Get pilot
  //         if (loadedDraftSubmission?.contact_id) {
  //           this.formGroup.controls.pilot.setValue(loadedDraftSubmission.contact_id);
  //         }
  //         // Get & set begin & end times
  //         if (loadedDraftSubmission?.operation_volumes[0]?.effective_time_begin) {
  //           this.beginTimeControl.markAsDirty();
  //           this.beginTimeControl.markAsTouched();
  //           this.beginTimeControl.setValue(loadedDraftSubmission?.operation_volumes[0]?.effective_time_begin);
  //         }
  //         if (loadedDraftSubmission?.operation_volumes[0]?.effective_time_end) {
  //           this.endTimeControl.markAsDirty();
  //           this.endTimeControl.markAsTouched();
  //           this.endTimeControl.setValue(loadedDraftSubmission?.operation_volumes[0]?.effective_time_end);
  //         }
  //
  //         // Get & set visibility
  //         if (loadedDraftSubmission?.vlos !== undefined) {
  //           this.formGroup.controls.visibility.setValue(loadedDraftSubmission.vlos ? 'vlos' : 'bvlos');
  //         }
  //
  //         const opCall = <T>(v: T, cb: (T) => void) => {
  //           if (!_.isNil(v)) {
  //             cb(v);
  //           }
  //         };
  //
  //         // Get & set all other form control values
  //         opCall(loadedDraftSubmission?.flight_number, (v) => this.formGroup.controls.operationName.setValue(v));
  //         opCall(loadedDraftSubmission?.flight_comments, (v) => this.formGroup.controls.operationDescription.setValue(v));
  //         opCall(newOpGeom, (v) => this.formGroup.controls.operationGeometry.setValue(v));
  //         opCall(loadedDraftSubmission?.operation_type, (v) => this.formGroup.controls.operationType.setValue(v));
  //         opCall(loadedDraftSubmission?.public_access, (v) => this.formGroup.controls.public_access.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.data_collection, (v) => this.formGroup.controls.data_collection.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.event_id, (v) => this.formGroup.controls.event_id.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.scenario, (v) => this.formGroup.controls.scenario.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.test_card, (v) => this.formGroup.controls.test_card.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.test_run, (v) => this.formGroup.controls.test_run.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.test_type, (v) => this.formGroup.controls.test_type.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.source, (v) => this.formGroup.controls.source.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.setting, (v) => this.formGroup.controls.setting.setValue(v));
  //         opCall(loadedDraftSubmission?.faa_rule, (v) => this.formGroup.controls.faarule.setValue(v));
  //         opCall(loadedDraftSubmission?.metadata?.location, (v) => this.formGroup.controls.location.setValue(v));
  //         opCall(loadedDraftSubmission?.permitted_constraint_types?.[0], (v) => this.formGroup.controls.permittedConstraintType.setValue(v));
  //         opCall(loadedDraftSubmission?.federation_policy, (v) => this.formGroup.controls.federationPolicy.setValue(v));
  //         opCall(loadedDraftSubmission?.priority, (v) => this.formGroup.controls.priority.setValue(v));
  //
  //         this.setPristineUntouchedState();
  //       }
  //     });
  //   } else {
  //     console.error('No draft exists with this UUID');
  //   }
  // }

  // clearLoadedDraft(): void {
  //   this.resetForm();
  //   this.router.navigate(['fuss', 'operations', 'newop'], {queryParams: {draftId: undefined}});
  // }

  // handleDeletedDraft(draft: OperationDraft): void {
  //   // If the loaded draft has been deleted, refresh the form and page
  //   if (draft.id === this.draftId) {
  //     this.clearLoadedDraft();
  //   }
  // }

  // toggleOverflowState(): void {
  //   this.setOverflowState(!this.overflowState);
  // }

  // setOverflowState(s: boolean): void {
  //   this.overflowState = s;
  //   if (this.overflowState) {
  //     this.overflowClass = 'btn-group-overflow open';
  //   } else {
  //     this.overflowClass = 'btn-group-overflow';
  //   }
  //   // this.crdf.detectChanges();
  // }

  // sendMessage(success: boolean, failureReason?: OperationDraftFailureReason, isNew?: boolean): void {
  //   const message = new UserMessageEntry({
  //     id: UUID.generateUUID(),
  //     timeCreated: DateTime.now(),
  //     userId: 'N/A',
  //     priority: 0,
  //     read: true,
  //     message: new UserMessage({
  //       title: '',
  //       type: 'OPERATION_DRAFT_MESSAGE',
  //       body: '',
  //       data: new OperationDraftMessage({
  //         draftId: null,
  //         draftType: isNew ? OperationDraftType.OPERATION_CREATE_DRAFT : OperationDraftType.OPERATION_MODIFY_DRAFT,
  //         success,
  //         failureReason: failureReason ? failureReason : null,
  //       }),
  //     }),
  //   });
  //   this.userMessageService.emitMessages(message);
  // }
}
