<ng-template #buttonTemplate>
  <div class="leaflet-bar leaflet-control">
    <div class="ie-button-container">
      <a
        class="leaflet-buttons-control-button"
        role="button"
        tabindex="0"
        (click)="handleImport()"
      >
        <div class="control-icon" title="Import">
          <cds-icon shape="upload"></cds-icon>
        </div>
      </a>

      <a
        class="leaflet-buttons-control-button"
        role="button"
        tabindex="0"
        *ngIf="!disableExport"
        (click)="handleExport()"
      >
        <div class="control-icon" title="Export">
          <cds-icon shape="download"></cds-icon>
        </div>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #modalTemplate>
  <form>
    <div class="modals">
      <clr-modal
        [clrModalOpen]="importDialogDisplay"
        (clrModalOpenChange)="setImportDialogDisplay($event)"
        [clrModalSize]="'lg'"
        *ngIf="importDialogDisplay"
      >
        <div class="modal-title">
          <div class="clr-control-container">
            <label class="clr-control-label">Import Type</label>
            <div class="clr-select-wrapper">
              <select class="clr-select" [formControl]="importServiceControl">
                <option
                  *ngFor="let t of availableImportServices()"
                  [ngValue]="t"
                >
                  {{ t.getFormatName() }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-body">
          @if (importServiceControl.valid) {
            <app-import-export-util
              [mode]="IMPORT"
              [service]="importServiceControl.value"
              (importExportResult)="handleImportResult($event)"
            >
            </app-import-export-util>
          }
          @if (errorMessage) {
            <clr-alerts>
              <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
                <clr-alert-item>
                  <span class="alert-text">Error importing: {{ errorMessage || 'unknown error' }}</span>
                </clr-alert-item>
              </clr-alert>
            </clr-alerts>
          }
        </div>
      </clr-modal>
      <clr-modal
        [clrModalOpen]="exportDialogDisplay"
        (clrModalOpenChange)="setExportDialogDisplay($event)"
        [clrModalSize]="'lg'"
        *ngIf="exportDialogDisplay"
      >
        <div class="modal-title">
          <div class="clr-control-container">
            <label class="clr-control-label">Export Type</label>
            <div class="clr-select-wrapper">
              <select class="clr-select" [formControl]="exportServiceControl">
                <option
                  *ngFor="let t of availableExportServices()"
                  [ngValue]="t"
                >
                  {{ t.getFormatName() }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-body">
          @if (exportServiceControl.valid) {
            <app-import-export-util
              [mode]="EXPORT"
              [service]="exportServiceControl.value"
              (importExportResult)="handleExportResult($event)"
              [exportData]="exportData"
            >
            </app-import-export-util>
          }
        </div>

        <!--      <div class="modal-body">-->
        <!--        <app-import-export-util [mode]="IMPORT" [type]="type" (importExportResult)="handleImportResult($event)">-->
        <!--        </app-import-export-util>-->
        <!--        <span *ngIf="errorMessage" class="ax-error">Error: {{errorMessage}}</span>-->
        <!--      </div>-->
      </clr-modal>

      <!--    <clr-modal [clrModalOpen]="exportDialogDisplay" (clrModalOpenChange)="setExportDialogDisplay($event)" [clrModalSize]="'md'" *ngIf="exportDialogDisplay && !disableExport">-->
      <!--      <div class="modal-body">-->
      <!--        <app-import-export-util [mode]="EXPORT" [type]="type" [exportData]="exportData"-->
      <!--                               (importExportResult)="handleExportResult($event)">-->
      <!--        </app-import-export-util>-->
      <!--      </div>-->
      <!--    </clr-modal>-->
    </div>
  </form>
</ng-template>
