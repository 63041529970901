import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ClearFormSettings, UserSettings, UserSettingsService} from '../../services/user-settings.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MeasurementSystemType} from '../../model/MeasurementSystem';
import {PermissionService, RoleManagerService} from '../../permissions/service/permission.service';
import {AxPermissionRequest} from '../../permissions/AxPermissionRequest';
import {PermissionParser} from '../../permissions/PermissionParser';
import {CurrentUserService} from '../../services/current-user.service';
import {switchMap} from 'rxjs/operators';
import {combineLatest} from "rxjs";
import {OperationActivationPolicy, vertical_reference} from '../../model/gen/utm';

import {isInteger} from '../../utils/Validators';
import {double} from '../../model/DataTypes';
import {FormControlify, FormGroupAble} from '../../utils/forms';
import {
  CONSTRAINT_DURATION_LIMITS,
  CONSTRAINT_START_OFFSET_LIMITS,
  DEFAULT_CONSTRAINT_DURATION,
  DEFAULT_CONSTRAINT_START_OFFSET,
  DEFAULT_OPERATION_ACTIVATION,
  DEFAULT_OPERATION_DURATION,
  DEFAULT_OPERATION_START_OFFSET,
  OPERATION_DURATION_LIMITS,
  OPERATION_START_OFFSET_LIMITS
} from '../../constants';
import {of} from 'rxjs/internal/observable/of';
import {OperationUtil} from "../../model/OperationUtil";
import {environment} from "../../../../environments/environment";

interface IUserSettingsFG {
  uiScalingFactor: double;
  measurementSystemType: MeasurementSystemType;
  defaultVerticalReference: vertical_reference;
  clearFormAfterSubmission: IClearFormFG;
  operationStartOffset: number;
  operationDuration: number;
  constraintStartOffset: number;
  constraintDuration: number;
  operationActivation: OperationActivationPolicy;
}

interface IClearFormFG extends FormGroupAble {
  operationSubmission: boolean;
  constraintSubmission: boolean;
  platformSubmission: boolean;
  userSubmission: boolean;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  fg: FormGroup<FormControlify<IUserSettingsFG>>;
  clearFormFG: FormGroup<FormControlify<IClearFormFG>>;
  measurementSystem = MeasurementSystemType;
  verticalReference = vertical_reference;
  showOperation = true;
  showConstraint = true;
  showPlatform = true;
  showUser = true;
  saveSuccess: boolean = undefined;
  ussVersion: string;

  defaultOperationStartOffset = DEFAULT_OPERATION_START_OFFSET;
  defaultOperationDuration = DEFAULT_OPERATION_DURATION;
  defaultConstraintStartOffset = DEFAULT_CONSTRAINT_START_OFFSET;
  defaultConstraintDuration = DEFAULT_CONSTRAINT_DURATION;
  operationStartOffsetLimits = OPERATION_START_OFFSET_LIMITS;
  operationDurationLimits = OPERATION_DURATION_LIMITS;
  constraintStartOffsetLimits = CONSTRAINT_START_OFFSET_LIMITS;
  constraintDurationLimits = CONSTRAINT_DURATION_LIMITS;

  operationActivationOptions: {
    name: string;
    value: OperationActivationPolicy
  }[] = Object.keys(OperationActivationPolicy)
    .map(key => ({
      name: OperationUtil.humanizeOperationActivationPolicy(OperationActivationPolicy[key]),
      value: OperationActivationPolicy[key]
    }));

  private userSettingsSub: Subscription;
  private accessSub: Subscription;
  private updateSub: Subscription;
  private settings: UserSettings;

  private submitOperationRequest: AxPermissionRequest<any> = PermissionParser.parseAxPermissionRequest('submit_operation');
  private submitConstraintRequest: AxPermissionRequest<any> = PermissionParser.parseAxPermissionRequest('submit_constraint');
  private submitPlatformRequest: AxPermissionRequest<any> = PermissionParser.parseAxPermissionRequest('submit_platform');
  private submitUserRequest: AxPermissionRequest<any> = PermissionParser.parseAxPermissionRequest('submit_user');

  roleManagerService = inject(RoleManagerService);

  constructor(private userSettingsService: UserSettingsService,
              private currentUserService: CurrentUserService,
              private permissionService: PermissionService) {
    this.clearFormFG = new FormGroup<FormControlify<IClearFormFG>>({
      operationSubmission: new FormControl<boolean>(false, [Validators.required]),
      constraintSubmission: new FormControl<boolean>(false, [Validators.required]),
      platformSubmission: new FormControl<boolean>(true, [Validators.required]),
      userSubmission: new FormControl<boolean>(true, [Validators.required])
    }, [Validators.required]);
    this.fg = new FormGroup<FormControlify<IUserSettingsFG>>({
      uiScalingFactor: new FormControl<double>(1.0, [Validators.required, Validators.min(0.01)]),
      measurementSystemType: new FormControl<MeasurementSystemType>(MeasurementSystemType.METRIC, [Validators.required]),
      defaultVerticalReference: new FormControl<vertical_reference>(vertical_reference.W84, [Validators.required]),
      clearFormAfterSubmission: this.clearFormFG,
      operationStartOffset: new FormControl<number>(DEFAULT_OPERATION_START_OFFSET, [
        Validators.min(OPERATION_START_OFFSET_LIMITS.min),
        Validators.max(OPERATION_START_OFFSET_LIMITS.max), isInteger]),
      operationDuration: new FormControl<number>(DEFAULT_OPERATION_DURATION, [
        Validators.min(OPERATION_DURATION_LIMITS.min),
        Validators.max(OPERATION_DURATION_LIMITS.max), isInteger]),
      constraintStartOffset: new FormControl<number>(DEFAULT_CONSTRAINT_START_OFFSET, [
        Validators.min(CONSTRAINT_START_OFFSET_LIMITS.min),
        Validators.max(CONSTRAINT_START_OFFSET_LIMITS.max), isInteger]),
      constraintDuration: new FormControl<number>(DEFAULT_CONSTRAINT_DURATION, [
        Validators.min(CONSTRAINT_DURATION_LIMITS.min), isInteger]),
      operationActivation: new FormControl<OperationActivationPolicy>(DEFAULT_OPERATION_ACTIVATION)
    });
    this.ussVersion = environment.version;
  }

  ngOnInit(): void {
    this.userSettingsSub = this.userSettingsService.getRawSettings().subscribe(settings => {
      this.settings = settings;

      this.fg.setValue({
        uiScalingFactor: settings.uiScalingFactor,
        measurementSystemType: settings.measurementSystemType,
        defaultVerticalReference: settings.defaultVerticalReference,
        clearFormAfterSubmission: settings.clearFormAfterSubmission,
        operationStartOffset: settings.operationStartOffset,
        operationDuration: settings.operationDuration,
        constraintStartOffset: settings.constraintStartOffset,
        constraintDuration: settings.constraintDuration,
        operationActivation: settings.operationActivation
      });
    });

    this.accessSub = this.currentUserService.currentExtendedUser.pipe(switchMap((extendedUser) => {
      const user = extendedUser.user;
      const role = extendedUser.role;

      if (user && role) {
        return combineLatest({
          operation: this.permissionService.evaluateRequestWithAnyRoles(this.submitOperationRequest),
          constraint: this.permissionService.evaluateRequestWithAnyRoles(this.submitConstraintRequest),
          platform: this.permissionService.evaluateRequestWithAnyRoles(this.submitPlatformRequest),
          user: this.permissionService.evaluateRequestWithAnyRoles(this.submitUserRequest)
        });
      } else {
        return of({
          operation: false,
          constraint: false,
          platform: false,
          user: false
        });
      }
    })).subscribe(perms => {
      this.showOperation = perms.operation;
      this.showConstraint = perms.constraint;
      this.showPlatform = perms.platform;
      this.showUser = perms.user;
    });
  }

  saveForm() {
    const newSettings: UserSettings = {
      uiScalingFactor: this.fg.controls.uiScalingFactor.value,
      measurementSystemType: this.fg.controls.measurementSystemType.value,
      defaultVerticalReference: this.fg.controls.defaultVerticalReference.value,
      clearFormAfterSubmission: this.extractClearFormSettings(this.fg.controls.clearFormAfterSubmission.getRawValue()),
      operationStartOffset: this.fg.controls.operationStartOffset.value || DEFAULT_OPERATION_START_OFFSET,
      operationDuration: this.fg.controls.operationDuration.value || DEFAULT_OPERATION_DURATION,
      constraintStartOffset: this.fg.controls.constraintStartOffset.value || DEFAULT_CONSTRAINT_START_OFFSET,
      constraintDuration: this.fg.controls.constraintDuration.value || DEFAULT_CONSTRAINT_DURATION,
      operationActivation: this.fg.controls.operationActivation.value || DEFAULT_OPERATION_ACTIVATION
    };

    this.updateSub = this.userSettingsService.updateSettings(newSettings).subscribe(() => {
      this.userSettingsService.refreshSettings();
      this.saveSuccess = true;
    }, () => {
      this.saveSuccess = false;
    });
  }

  ngOnDestroy(): void {
    this.updateSub?.unsubscribe();
    this.accessSub?.unsubscribe();
  }

  getPrettyValue(): string {
    return JSON.stringify(this.fg.getRawValue(), null, 4);
  }

  private extractClearFormSettings(values: IClearFormFG): ClearFormSettings {
    return {
      operationSubmission: values.operationSubmission,
      constraintSubmission: values.constraintSubmission,
      platformSubmission: values.platformSubmission,
      userSubmission: values.userSubmission
    };
  }
}
