export enum OperatorIntentType {
  /* eslint-disable @typescript-eslint/naming-convention */
  OPERATION_CREATE = 'OPERATION_CREATE',
  OPERATION_MODIFY = 'OPERATION_MODIFY',
  OPERATION_ACTIVATE = 'OPERATION_ACTIVATE',
  OPERATION_CLOSE = 'OPERATION_CLOSE',
  CONSTRAINT_CREATE = 'CONSTRAINT_CREATE',
  CONSTRAINT_MODIFY = 'CONSTRAINT_MODIFY',
  CONSTRAINT_ACTIVATE = 'CONSTRAINT_ACTIVATE',
  CONSTRAINT_CLOSE = 'CONSTRAINT_CLOSE',
  // OPERATION_CREATE_DRAFT = 'OPERATION_CREATE_DRAFT'
  /* eslint-enable @typescript-eslint/naming-convention */
}

export enum OperatorIntentFailureReason {
  /* eslint-disable @typescript-eslint/naming-convention */
  OTHER = 'OTHER',
  ENDED = 'ENDED',
  EXPIRED = 'EXPIRED',
  UNSUPPORTED_STATE = 'UNSUPPORTED_STATE',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NOT_FOUND = 'NOT_FOUND',
  CONFLICT = 'CONFLICT',
  INTERNAL = 'INTERNAL',
  USS_COMMUNICATION = 'USS_COMMUNICATION',
  DSS_COMMUNICATION = 'DSS_COMMUNICATION',
  VOLUME_CONVERSION = 'VOLUME_CONVERSION',
  UTM_TOKEN_REQUEST_FAILED = 'UTM_TOKEN_REQUEST_FAILED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVAL_FAILED = 'APPROVAL_FAILED'
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const humanizedOperatorIntentFailureReason: {[key in OperatorIntentFailureReason]: string} & {undefined: string, null:string}  = {
  [OperatorIntentFailureReason.OTHER]: 'Other',
  [OperatorIntentFailureReason.ENDED]: 'Operation/Constraint Ended',
  [OperatorIntentFailureReason.EXPIRED]: 'Operator Intent Expired',
  [OperatorIntentFailureReason.UNSUPPORTED_STATE]: 'Unsupported State For Requested Action',
  [OperatorIntentFailureReason.ACCESS_DENIED]: 'Access Denied',
  [OperatorIntentFailureReason.NOT_FOUND]: 'Not Found',
  [OperatorIntentFailureReason.CONFLICT]: 'Conflict',
  [OperatorIntentFailureReason.INTERNAL]: 'Internal Error',
  [OperatorIntentFailureReason.USS_COMMUNICATION]: 'USS Communication Issue',
  [OperatorIntentFailureReason.DSS_COMMUNICATION]: 'DSS Communication Issue',
  [OperatorIntentFailureReason.VOLUME_CONVERSION]: 'Volume Altitude Conversion Error',
  [OperatorIntentFailureReason.UTM_TOKEN_REQUEST_FAILED]: 'UTM Token Request Failed',
  [OperatorIntentFailureReason.PENDING_APPROVAL]: 'Pending Approval',
  [OperatorIntentFailureReason.APPROVAL_FAILED]: 'Approval Failed',
  undefined: 'Unknown',
  null: 'Unknown'
};

export enum ConformanceState {
  CONFORMING = 'CONFORMING',
  NONCONFORMING = 'NONCONFORMING',
  CONTINGENT = 'CONTINGENT'
}

export enum Volume4dViolation {
  TIME_LOW = 'TIME_LOW',
  TIME_HIGH = 'TIME_HIGH',
  ALTITUDE_LOW = 'ALTITUDE_LOW',
  ALTITUDE_HIGH = 'ALTITUDE_HIGH',
  HORIZONTAL = 'HORIZONTAL'
}

export enum OperationDraftType {
  /* eslint-disable @typescript-eslint/naming-convention */
  OPERATION_CREATE_DRAFT = 'OPERATION_CREATE_DRAFT',
  OPERATION_MODIFY_DRAFT = 'OPERATION_MODIFY_DRAFT',
  /* eslint-enable @typescript-eslint/naming-convention */
}

export enum OperationDraftFailureReason {
  /* eslint-disable @typescript-eslint/naming-convention */
  GENERAL = 'GENERAL',
  MISSING_NAME = 'MISSING_NAME',
  // OTHER = 'OTHER',
  // ACCESS_DENIED = 'ACCESS_DENIED',
  // NOT_FOUND = 'NOT_FOUND',
  // INTERNAL = 'INTERNAL',
  // USS_COMMUNICATION = 'USS_COMMUNICATION',
  /* eslint-enable @typescript-eslint/naming-convention */
}

export type TransportOperatorIntentTypeEnum =
  'OPERATION_CREATE'
  | 'OPERATION_MODIFY'
  | 'OPERATION_ACTIVATE'
  | 'OPERATION_CLOSE'
  | 'CONSTRAINT_CREATE'
  | 'CONSTRAINT_MODIFY'
  | 'CONSTRAINT_ACTIVATE'
  | 'CONSTRAINT_CLOSE';
  // | 'OPERATION_CREATE_DRAFT';

/* eslint-disable @typescript-eslint/naming-convention */
export const TransportOperatorIntentTypeEnum = {
  OPERATION_CREATE: 'OPERATION_CREATE' as TransportOperatorIntentTypeEnum,
  OPERATION_MODIFY: 'OPERATION_MODIFY' as TransportOperatorIntentTypeEnum,
  OPERATION_ACTIVATE: 'OPERATION_ACTIVATE' as TransportOperatorIntentTypeEnum,
  OPERATION_CLOSE: 'OPERATION_CLOSE' as TransportOperatorIntentTypeEnum,
  CONSTRAINT_CREATE: 'CONSTRAINT_CREATE' as TransportOperatorIntentTypeEnum,
  CONSTRAINT_MODIFY: 'CONSTRAINT_MODIFY' as TransportOperatorIntentTypeEnum,
  CONSTRAINT_ACTIVATE: 'CONSTRAINT_ACTIVATE' as TransportOperatorIntentTypeEnum,
  CONSTRAINT_CLOSE: 'CONSTRAINT_CLOSE' as TransportOperatorIntentTypeEnum,
  // OPERATION_CREATE_DRAFT: 'OPERATION_CREATE_DRAFT' as TransportOperatorIntentTypeEnum
};
/* eslint-enable @typescript-eslint/naming-convention */

export type TransportOperatorIntentFailureReasonEnum =
  'OTHER'
  | 'ENDED'
  | 'EXPIRED'
  | 'UNSUPPORTED_STATE'
  | 'ACCESS_DENIED'
  | 'NOT_FOUND'
  | 'CONFLICT'
  | 'INTERNAL'
  | 'USS_COMMUNICATION'
  | 'DSS_COMMUNICATION'
  | 'VOLUME_CONVERSION'
  | 'UTM_TOKEN_REQUEST_FAILED'
  | 'PENDING_APPROVAL'
  | 'APPROVAL_FAILED';

/* eslint-disable @typescript-eslint/naming-convention */
export const TransportOperatorIntentFailureReasonEnum = {
  OTHER: 'OTHER' as TransportOperatorIntentFailureReasonEnum,
  ENDED: 'ENDED' as TransportOperatorIntentFailureReasonEnum,
  EXPIRED: 'EXPIRED' as TransportOperatorIntentFailureReasonEnum,
  UNSUPPORTED_STATE: 'UNSUPPORTED_STATE' as TransportOperatorIntentFailureReasonEnum,
  ACCESS_DENIED: 'ACCESS_DENIED' as TransportOperatorIntentFailureReasonEnum,
  NOT_FOUND: 'NOT_FOUND' as TransportOperatorIntentFailureReasonEnum,
  CONFLICT: 'CONFLICT' as TransportOperatorIntentFailureReasonEnum,
  INTERNAL: 'INTERNAL' as TransportOperatorIntentFailureReasonEnum,
  USS_COMMUNICATION: 'USS_COMMUNICATION' as TransportOperatorIntentFailureReasonEnum,
  DSS_COMMUNICATION: 'DSS_COMMUNICATION' as TransportOperatorIntentFailureReasonEnum,
  VOLUME_CONVERSION: 'VOLUME_CONVERSION' as TransportOperatorIntentFailureReasonEnum,
  UTM_TOKEN_REQUEST_FAILED: 'UTM_TOKEN_REQUEST_FAILED' as TransportOperatorIntentFailureReasonEnum,
  PENDING_APPROVAL: 'PENDING_APPROVAL' as TransportOperatorIntentFailureReasonEnum,
  APPROVAL_FAILED: 'APPROVAL_FAILED' as TransportOperatorIntentFailureReasonEnum
};
/* eslint-enable @typescript-eslint/naming-convention */

export type TransportConformanceState = 'CONFORMING' | 'NONCONFORMING' | 'CONTINGENT';

export const TransportConformanceState = {
  CONFORMING: 'CONFORMING' as TransportConformanceState,
  NONCONFORMING: 'NONCONFORMING' as TransportConformanceState,
  CONTINGENT: 'CONTINGENT' as TransportConformanceState
};

export type TransportVolume4dViolation = 'TIME_LOW' | 'TIME_HIGH' | 'ALTITUDE_LOW' | 'ALTITUDE_HIGH' | 'HORIZONTAL';

export const TransportVolume4dViolation = {
  TIME_LOW: 'TIME_LOW' as TransportVolume4dViolation,
  TIME_HIGH: 'TIME_HIGH' as TransportVolume4dViolation,
  ALTITUDE_LOW: 'ALTITUDE_LOW' as TransportVolume4dViolation,
  ALTITUDE_HIGH: 'ALTITUDE_HIGH' as TransportVolume4dViolation,
  HORIZONTAL: 'HORIZONTAL' as TransportVolume4dViolation
};
