import { inject } from '@angular/core';
import { interval, Observable, of, shareReplay, withLatestFrom } from 'rxjs';
import {
  AbstractMapConfigProvider,
  PointImpl,
} from '@ax/ax-angular-map-common';
import { map } from 'rxjs/operators';
import {
  DEFAULT_POSITION_SUBMISSION_V2_UTIL,
  PositionSubmissionV2,
} from '../models';
import { DateTime } from 'luxon';

export abstract class AircraftPositionService {
  abstract watchPositions(registrationId: string): Observable<PositionSubmissionV2 | null>;
  abstract submitPosition(position: PositionSubmissionV2): Observable<boolean>;
}


function createMockPositionSubmission(registrationId: string, latitude: number, longitude: number, altitude: number): PositionSubmissionV2 {
  const tmp = DEFAULT_POSITION_SUBMISSION_V2_UTIL.deserialize({
    latitude,
    longitude,
    altitude,
    registration_id: registrationId,
    time_measured: DateTime.now().toISO(),
    track_bearing: 0.0,
    track_ground_speed: 0.0,
    simulated: true,
  });

  if (tmp.type === 'error') {
    throw new Error(
      `Failed to create mock position submission: ${tmp.message}`
    );
  }
  return tmp.value;
}


export class MockAircraftPositionService implements AircraftPositionService {
  private mapService = inject(AbstractMapConfigProvider);
  private defaultPosition$ = this.mapService.getTileLayerConfigurations().pipe(
    map((config) => {
      const point = config?.getCenterPoint();
      if (point) {
        return point;
      }

      return new PointImpl(0, 0);
    }),
    shareReplay(1)
  );

  watchPositions(
    registrationId: string
  ): Observable<PositionSubmissionV2 | null> {
    return interval(1000).pipe(
      withLatestFrom(this.defaultPosition$),
      map(([i, point]) => {
        const radius = 0.001;
        const iRad = ((i * 10) % 360) * (Math.PI / 180);
        const longitude =
          ((point.getLongitude() + radius * Math.sin(iRad) + 180) % 360) - 180;
        const latitude =
          ((point.getLatitude() + radius * Math.cos(iRad) + 90) % 180) - 90;
        return createMockPositionSubmission(
          registrationId,
          latitude,
          longitude,
          500 + 100 * Math.sin(iRad)
        );
      })
    );
  }

  submitPosition(position: PositionSubmissionV2): Observable<boolean> {
    return of(true);
  }
}
