import {Component, computed, input} from '@angular/core';
import {OperationExt} from '../../../model/utm/OperationExt';
import {OperationService} from '../../../services/operation.service';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {filter, switchMap, tap} from 'rxjs/operators';
import {fail, Result, some} from '@ax-uss-ui/common';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-ax-operation-ext-summary',
  templateUrl: './ax-operation-ext-summary.component.html',
  styleUrls: ['./ax-operation-ext-summary.component.scss']
})
export class AxOperationExtSummaryComponent {
  operationId = input<string | null>(null);
  operation = input<OperationExt | null>(null);
  simplifiedDisplay = input<boolean>(false);

  displayedOperation: Result<OperationExt> | null;
  operationLoading = true;

  operationIdToFetch = computed(() => {
    if (this.operation()) {
      this.displayedOperation = some(this.operation());
      this.operationLoading = false;
      return null;
    } else if (this.operationId()) {
      return this.operationId();
    }
  });

  constructor(private operationService: OperationService) {
    toObservable(this.operationIdToFetch)
      .pipe(
        filter(operationId => !!operationId),
        tap(() => this.operationLoading = true),
        switchMap(operationId => this.operationService.getOperation(operationId)),
        takeUntilDestroyed()
      ).subscribe({
        next: operation => {
          this.operationLoading = false;
          this.displayedOperation = some(operation);
        },
        error: (error: HttpErrorResponse) => {
          this.operationLoading = false;
          this.displayedOperation = fail('Error: ' + (error?.error?.message || 'Unknown error loading operation'));
        }
      });
  }
}
