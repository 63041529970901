import * as v from 'valibot';

export function flattenRootishIssuesToMessage<T extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>> | v.BaseSchemaAsync<unknown, unknown, v.BaseIssue<unknown>>>(
  result: v.SafeParseResult<T>
): string[] {
  if (result.success) {
    return [];
  }
  const flattenedIssues = v.flatten<T>(
    result.issues
  );
  const messageParts: string[] = [];

  messageParts.push(...(flattenedIssues.root ?? []));
  messageParts.push(...(flattenedIssues.other ?? []));

  return messageParts;
}
