import {BaseEnumSerializerDeserializer} from "../common";
import {fail, Result, some} from "../../utils";

export enum units_of_measure {
  FT = 'FT',
  M = 'M',
  MI = 'MI',
  NM = 'NM'
}

export class UnitsOfMeasureUtil implements BaseEnumSerializerDeserializer<units_of_measure, units_of_measure> {
  deserialize(raw: unknown): Result<units_of_measure> {
    if (typeof raw !== 'string') return fail('Invalid type for units of measure');

    switch (raw.toUpperCase()) {
      case 'FT':
        return some(units_of_measure.FT);
      case 'M':
        return some(units_of_measure.M);
      case 'MI':
        return some(units_of_measure.MI);
      case 'NM':
        return some(units_of_measure.NM);
      default:
        return fail(`Invalid units of measure: ${raw}`);
    }
  }

  serialize(obj: units_of_measure): units_of_measure {
    return obj;
  }

}

export const DEFAULT_UNITS_OF_MEASURE_UTIL = new UnitsOfMeasureUtil();
