<ax-cesium-container [timeline]="false"
                     [toolbarZIndex]="'1000'"
                     [navigationInstructionsInitiallyVisible]="false"
                     [projectionPicker]="true"
                     [sceneModePicker]="false">
  <lib-infobox-container />
  <lib-toolbar-extra-button-container>
    <lib-north-button />
  </lib-toolbar-extra-button-container>
  <app-cesium-vol4d-query></app-cesium-vol4d-query>
  <app-operation-sitrep-drawer [altRef]="altRef" [altUnits]="altUnits"></app-operation-sitrep-drawer>
  <app-constraint-sitrep-drawer [altRef]="altRef" [altUnits]="altUnits"></app-constraint-sitrep-drawer>
</ax-cesium-container>
