import {Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingsService} from '../shared/services/settings.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent {
  ussVersion = environment.version;
  settingsService = inject(SettingsService);
  settings$ = toSignal(this.settingsService.getRawSettings());
}
