@if (operationLoading) {
  <div class="spinner-wrapper">
    <span class="spinner"></span>
    <p>Loading operation</p>
  </div>
} @else if (displayedOperation) {
  @if (displayedOperation.type === 'some') {
    <div class="clr-row">
      <app-ax-operation-summary class="clr-col-md-12" [operation]="displayedOperation.value" [simplifiedDisplay]="simplifiedDisplay()"></app-ax-operation-summary>
    </div>
  } @else {
    <clr-alerts>
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text">{{displayedOperation.message}}</span>
        </clr-alert-item>
      </clr-alert>
    </clr-alerts>
  }
} @else {
  <clr-alerts>
    <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">No operation provided</span>
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>
}
