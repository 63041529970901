import {
  AircraftPositionService,
  DEFAULT_POSITION_SUBMISSION_MESSAGE_UTIL,
  DEFAULT_POSITION_SUBMISSION_V2_UTIL,
  PositionSubmissionV2,
  TUTMRestResponse,
} from '@ax-uss-ui/common';
import { Observable, of, switchMap } from 'rxjs';
import { StompService } from '../stomp.service';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

export class RestAircraftPositionService implements AircraftPositionService {
  private stompService = inject(StompService);
  private http = inject(HttpClient);

  watchPositions(
    registrationId: string
  ): Observable<PositionSubmissionV2 | null> {
    return this.stompService.getStompClient().pipe(
      switchMap((client) => {
        if (client === null) {
          return new Observable<PositionSubmissionV2 | null>();
        }
        return client
          .watch(
            `/exchange/operator_position_submission_topic/${registrationId}`
          )
          .pipe(
            map((message) => {
              try {
                const rawObject = JSON.parse(message.body);
                const positionMessage =
                  DEFAULT_POSITION_SUBMISSION_MESSAGE_UTIL.deserialize(
                    rawObject
                  );
                if (positionMessage.type === 'error') {
                  console.error(
                    `Failed to deserialize position submission: ${positionMessage.message}`
                  );
                  return null;
                }
                return positionMessage.value.positionSubmission;
              } catch (e) {
                console.error(
                  `Failed to parse position submission message: ${e}`
                );
                return null;
              }
            })
          );
      })
    );
  }

  submitPosition(position: PositionSubmissionV2): Observable<boolean> {
    const transportPosition = DEFAULT_POSITION_SUBMISSION_V2_UTIL.serialize(position);
    if (transportPosition.type === 'error') {
      console.error(`Failed to serialize position submission: ${transportPosition.message}`);
      return of(false);
    }
    return this.http
      .post<TUTMRestResponse>('operator/v2/positions', transportPosition.value)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          const msg = 'Error: ' + (err?.error?.message || err?.message || 'Unknown error submitting position');
          console.error(`Failed to submit position: ${msg}`);
          return of(false);
        }),
        map(() => true)
      );
  }
}
