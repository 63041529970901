import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {allTruthy, windowIterator} from '../../../utils/misc';
import {featureCollection, polygon} from '@turf/turf';
import intersect from '@turf/intersect';
import {GeoCircle, units_of_measure} from '../../../model/gen/utm';
import {IOpVolumeSubmissionFG} from './operation-geometry-editor.component';
import {OperationUtil} from '../../../model/OperationUtil';
import {DateTime} from 'luxon';
import {cloneDeep} from "lodash";
import {AltitudeRange} from "../../../model/gen/utm/altitude-range-model";

export function operationVolumeContinuityValidator(): ValidatorFn {
  let lastKey: string|null = null;
  let lastResult: any = null;
  return (control: AbstractControl) => {
    const ret: ValidationErrors = {};
    const value = control.value as IOpVolumeSubmissionFG[];
    const key = JSON.stringify(value);
    if (key === lastKey){
      return lastResult;
    }
    lastKey = key;
    if (!value || value.length === 0) {
      ret.empty_volumes = 'No Volumes';
    } else {
      const vols = value;
      if (vols.length < 2) {
        lastResult = {};
        return {};
      }

      let i = 1;
      for (const [a, b] of windowIterator(vols)) {
        // Only check for overlap if the geometry has polygon coordinates or a circle
        if ((!a.geography?.coordinates?.length && !a.circle) ||
            (!b.geography?.coordinates?.length && !b.circle)) {
          return null;
        }

        // Check volume overlap
        const getLonLatCoordinates = (volume: IOpVolumeSubmissionFG) => {
          if (volume.circle) {
            const circle = new GeoCircle(volume.circle);
            return [circle.toLonLatCoordinates()];
          } else if (volume.geography?.coordinates?.length) {
            return volume.geography.coordinates;
          } else {
            return null;
          }
        }

        const aRaw: number[][][] = (JSON.parse(JSON.stringify(getLonLatCoordinates(a))));
        const bRaw: number[][][] = (JSON.parse(JSON.stringify(getLonLatCoordinates(b))));
        aRaw[0].push(aRaw[0][0]);
        bRaw[0].push(bRaw[0][0]);

        const aPolygon = polygon(aRaw);
        const bPolygon = polygon(bRaw);
        try {
          const overlap = intersect(featureCollection([
            aPolygon,
            bPolygon
          ]));
          if (overlap === null) {
            ret[`volume_${i + 1}_geometry_continuity_fail`] = `Areas of operation volumes ${i} and ${i + 1} must overlap`;
          }
        } catch (e) {
          // check volume overlap
          const aRaw2: number[][][] = cleanRawPoints(aRaw);
          const bRaw2: number[][][] = cleanRawPoints(bRaw);
          aRaw2[0].push(aRaw2[0][0]);
          bRaw2[0].push(bRaw2[0][0]);

          const aPolygon2 = polygon(aRaw2);
          const bPolygon2 = polygon(bRaw2);
          try {
            const overlap = intersect(featureCollection([
              aPolygon2,
              bPolygon2
            ]));
            if (overlap === null) {
              ret[`volume_${i + 1}_geometry_continuity_fail`] = `Areas of operation volumes ${i} and ${i + 1} must overlap`;
            }
          } catch (e) {
            console.log(e);
          }
        }

        // Check altitude overlap
        if (a.altitudeRange && b.altitudeRange) {
          const altA = new AltitudeRange({
            min_altitude: OperationUtil.toM(a.altitudeRange.min_altitude, a.altitudeRange.altitude_units),
            max_altitude: OperationUtil.toM(a.altitudeRange.max_altitude, a.altitudeRange.altitude_units),
            altitude_vertical_reference: a.altitudeRange.altitude_vertical_reference,
            altitude_units: units_of_measure.M
          });
          const altB = new AltitudeRange({
            min_altitude: OperationUtil.toM(b.altitudeRange.min_altitude, b.altitudeRange.altitude_units),
            max_altitude: OperationUtil.toM(b.altitudeRange.max_altitude, b.altitudeRange.altitude_units),
            altitude_vertical_reference: b.altitudeRange.altitude_vertical_reference,
            altitude_units: units_of_measure.M
          });

          const rangeOverlap = Math.min(altA.max_altitude, altB.max_altitude) - Math.max(altA.min_altitude, altB.min_altitude);
          if (!rangeOverlap || rangeOverlap < 5) {
            ret[`volume_${i + 1}_altitude_continuity_fail`] = `Altitudes of operation volumes ${i} and ${i + 1} must overlap by at least 5 M/16.4 FT`;
          }
        }

        // Check time overlap
        const timeRangeA = cloneDeep(a.timeRange);
        const timeRangeB = cloneDeep(b.timeRange);

        if (timeRangeA && !timeRangeA.start) {
          timeRangeA.start = DateTime.now();
        }

        if (timeRangeB && !timeRangeB.start) {
          timeRangeB.start = DateTime.now();
        }

        if (allTruthy(timeRangeA?.end, timeRangeB?.end) &&
          (DateTime.max(timeRangeA.start, timeRangeB.start).toMillis() >= DateTime.min(timeRangeA.end, timeRangeB.end).toMillis())) {
          ret[`volume_${i}_time_continuity_fail`] = `Time ranges of operation volumes ${i} and ${i + 1} must overlap by at least one second.`;
        }
        ++i;
      }
    }

    lastResult = ret;
    return ret;
  };
}

/**
 * Validator to enforce that the overall operation start time isn't in the future
 */
export function operationFutureValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const volumes: IOpVolumeSubmissionFG[] = control.value;

    if (!volumes || volumes.length === 0) {
      return null;
    }

    const currentTime = DateTime.now();

    // Get the earliest volume start time, accounting for ASAP start times (null)
    let earliestStartTime = volumes[0].timeRange?.start === null ? currentTime : volumes[0].timeRange?.start;
    volumes.forEach(volume => {
      const startTime = volume.timeRange?.start === null ? currentTime : volume.timeRange?.start;
      if (startTime && startTime < earliestStartTime) {
        earliestStartTime = volume.timeRange.start;
      }
    });

    if (!earliestStartTime || earliestStartTime <= currentTime) {
      return null;
    } else {
      return {operationStartFuture: true};
    }
  }
}


function cleanRawPoints(raw: number[][][]): number[][][] {
  const precision = Math.pow(10, 6);
  return raw.map(l1 => {
    return l1.map(l2 => {
      return l2.map(num => {
        return Math.floor(num * precision) / precision;
      });
    });
  });
}
