import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClrDatagridFilterInterface} from '@clr/angular';
import {Subject} from 'rxjs';
import {ConstraintState} from '../../../../../shared/model/utm/UvrExt';
import {Parser} from '../../../../../shared/model/utm/parser/OperationParser';

@Component({
  selector: 'app-constraint-states-filter',
  templateUrl: './constraint-states-filter.component.html',
  styleUrls: ['./constraint-states-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ConstraintStatesFilterComponent),
  }]
})
export class ConstraintStatesFilterComponent implements ClrDatagridFilterInterface<any>, ControlValueAccessor {
  changes = new Subject<any>();
  active = false;
  statesFg = new FormGroup({
    ACCEPTED: new FormControl<boolean>(false),
    ACTIVE: new FormControl<boolean>(false),
    ENDED: new FormControl<boolean>(false)
  });
  availableStateOptions: ConstraintState[] = [
    ConstraintState.ACCEPTED,
    ConstraintState.ACTIVE,
    ConstraintState.ENDED
  ];

  private onChange: any;

  private valueChanges$ = this.statesFg.valueChanges.subscribe(() => {
    const rawValues = this.statesFg.getRawValue();
    const selectedStates: ConstraintState[] = [];

    Object.keys(rawValues).forEach(k => {
      // If the checkbox is checked/true, add the state to the selectedStates array
      if (!!rawValues[k]) {
        const parsedState = Parser.parseConstraintState(k as ConstraintState);
        if (!!parsedState) {
          selectedStates.push(parsedState);
        }
      }
    });

    // If only one checkbox is checked, disable it so that at least one box is checked at all times
    if (selectedStates.length === 1) {
      this.statesFg.controls[selectedStates[0]].disable({emitEvent: false});
    } else {
      // Otherwise, all controls should be enabled
      this.statesFg.enable({emitEvent: false});
    }

    if (this.onChange) {
      this.onChange(selectedStates);
    }
  });

  isActive(): boolean {
    return !!this.active;
  }

  accepts(val: any): boolean {
    return true;
  }

  getPrettyConstraintStateName(s: string) {
    const names = {
      /* eslint-disable @typescript-eslint/naming-convention */
      ACCEPTED: 'Accepted',
      ACTIVE: 'Active',
      ENDED: 'Closed'
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return names[s];
  }

  toggleSelectAll() {
    // This only works one way (enable). Disabling all checkboxes is prevented by the
    // minimum requirement of one box being checked at any given time.
    this.statesFg.setValue({
      ACCEPTED: true,
      ACTIVE: true,
      ENDED: true
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(selectedStates: ConstraintState[]): void {
    this.statesFg.reset({
      ACCEPTED: false,
      ACTIVE: false,
      ENDED: false
    }, {emitEvent: false, onlySelf: true});
    selectedStates?.forEach(s => {
      this.statesFg.controls[s]?.setValue(true, {emitEvent: false, onlySelf: true});
    });
  }
}
