import {Component, inject, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DEFAULT_USS_SETTINGS, SettingsService} from '../shared/services/settings.service';
import {Subscription} from 'rxjs';
import {MapConfig} from '@ax/ax-angular-map-common';
import {IFederationDomain, IFederationPolicy} from '../shared/model/FederationPolicy';
import {enforcedPatternRegexes, invalidCharactersValidator} from '../shared/utils/Validators';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {requiredWhiteSpace} from '@ax-uss-ui/common';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnDestroy {
  settingsForm = new FormGroup({
    title: new FormControl<string>('', [requiredWhiteSpace, Validators.minLength(1), Validators.maxLength(80)]),
    supportPoc: new FormGroup({
      name: new FormControl<string | null>(null, [Validators.maxLength(64), invalidCharactersValidator(/[^ a-zA-Z0-9,.'-]/g)]),
      phone: new FormControl<string | null>(null, [Validators.pattern(enforcedPatternRegexes.phone)]),
      email: new FormControl<string | null>(null, [Validators.email])
    }, []),
    mapConfig: new FormControl<MapConfig>(new MapConfig([], null, null, null, null), [Validators.required]),
    knownFederationDomains: new FormControl<IFederationDomain[]>([], []),
    defaultFederationPolicy: new FormControl<IFederationPolicy>(null, [Validators.required]),
    experimentalSettings: new FormGroup({
      enableDashboardSupport: new FormControl<boolean>(DEFAULT_USS_SETTINGS.experimentalSettings.enableDashboardSupport, {
        validators: [Validators.required],
        nonNullable: true
      }),
      enableFederationSupport: new FormControl<boolean>(DEFAULT_USS_SETTINGS.experimentalSettings.enableFederationSupport, {
        validators: [Validators.required],
        nonNullable: true
      }),
      enableDirectMessagingSupport: new FormControl<boolean>(DEFAULT_USS_SETTINGS.experimentalSettings.enableDirectMessagingSupport, {
        validators: [Validators.required],
        nonNullable: true
      })
    })
  });

  experimentalExpanded = false;
  supportPocExpanded = false;
  saveSuccess: boolean|undefined = undefined;

  private updateSub: Subscription;
  private envService = inject(SettingsService);
  private settingsSub = this.envService.getRawSettings().pipe(takeUntilDestroyed()).subscribe(settings => {
    this.settingsForm.setValue(settings);
  });

  get knownFederationDomains(): IFederationDomain[] {
    return this.settingsForm?.controls.knownFederationDomains?.value ?? [];
  }

  saveForm() {
    this.updateSub?.unsubscribe();
    this.updateSub = this.envService.updateSettings(this.settingsForm.getRawValue()).subscribe({
      next: () => {
        this.envService.refreshSettings();
        this.saveSuccess = true;
        setTimeout(() => {
          this.saveSuccess = undefined;
        }, 5000);
      },
      error: () => {
        this.saveSuccess = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.updateSub?.unsubscribe();
  }

}
