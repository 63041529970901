import {BaseEnumSerializerDeserializer} from "../common";
import {fail, Result, some} from "../../utils/optional";


export enum source {
  ONBOARD_SENSOR = 'ONBOARD_SENSOR',
  OTHER = 'OTHER',
}

export class SourceUtil implements BaseEnumSerializerDeserializer<source, source> {
  deserialize(raw: unknown): Result<source> {
    if (typeof raw !== 'string') return fail('Invalid type for source');

    switch (raw.toUpperCase()) {
      case 'ONBOARD_SENSOR':
        return some(source.ONBOARD_SENSOR);
      case 'OTHER':
        return some(source.OTHER);
      default:
        return fail(`Invalid source: ${raw}`);
    }
  }

  serialize(obj: source): source {
    return obj;
  }
}

export const DEFAULT_SOURCE_UTIL = new SourceUtil();
