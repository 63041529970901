import {
  DEFAULT_SOURCE_UTIL,
  DEFAULT_UNITS_OF_MEASURE_UTIL,
  DEFAULT_VERTICAL_REFERENCE_UTIL,
  source,
  units_of_measure,
  vertical_reference
} from "./enums";
import {BaseModel, BaseModelSerializerDeserializer} from "./common";
import {fail, isEmpty, isObject, Result, some} from "../utils/optional";


export interface TAltitude {
  altitude_value: number;
  vertical_reference: vertical_reference;
  units_of_measure: units_of_measure;
  source?: source;
}

export class Altitude implements BaseModel {

  altitude_value: number;
  vertical_reference: vertical_reference;
  units_of_measure: units_of_measure;
  source?: source;

  public constructor(altitude_value: number,
                     ref?: vertical_reference,
                     units?: units_of_measure,
                     source?: source) {
    this.altitude_value = altitude_value;
    this.vertical_reference = ref ?? vertical_reference.W84;
    this.units_of_measure = units ?? units_of_measure.M;
    this.source = source;

  }


}


export class AltitudeUtil implements BaseModelSerializerDeserializer<TAltitude, Altitude> {
  deserialize(raw: unknown): Result<Altitude> {
    if (isEmpty(raw)) return fail('No data supplied');
    if (!isObject(raw)) return fail('Invalid data supplied');
    if (!('altitude_value' in raw)) return fail('No altitude value');
    if (typeof raw.altitude_value !== 'number' || isNaN(raw.altitude_value)) return fail('Invalid altitude value');


    const verticalReference = DEFAULT_VERTICAL_REFERENCE_UTIL.deserialize(('vertical_reference' in raw) ? raw.vertical_reference : vertical_reference.W84);
    const unitsOfMeasure = DEFAULT_UNITS_OF_MEASURE_UTIL.deserialize(('units_of_measure' in raw) ? raw.units_of_measure : units_of_measure.M);
    let s: source | undefined = undefined;

    if (verticalReference.type === 'error') return fail(verticalReference.message);
    if (unitsOfMeasure.type === 'error') return fail(unitsOfMeasure.message);


    if ('source' in raw) {
      const newSource = DEFAULT_SOURCE_UTIL.deserialize(raw.source);
      if (newSource.type === 'error') return fail(newSource.message);
      s = newSource.value;
    }


    return some(new Altitude(raw.altitude_value, verticalReference.value, unitsOfMeasure.value, s));
  }

  serialize(obj: Altitude): Result<TAltitude> {
    return some({
      altitude_value: obj.altitude_value,
      vertical_reference: obj.vertical_reference,
      units_of_measure: obj.units_of_measure,
      source: obj.source
    });
  }

}

export const DEFAULT_ALTITUDE_UTIL = new AltitudeUtil();

