<form [formGroup]="generalDetailsFg">
  <clr-tabs>
    <clr-tab>
      <button clrTabLink>General Information
        @if (generalDetailsFg.invalid) {
          <cds-icon shape="exclamation-circle" solid="true" class="error-icon"></cds-icon>
        }
      </button>
      <ng-template [(clrIfActive)]="activeTabStatus.generalDetails">
        <clr-tab-content>
          <div class="clr-row">
            <clr-input-container class="clr-col-sm-12 clr-col-md-4 clr-col-lg-3">
              <label class="required-custom">Name:</label>
              <input autocomplete="off" clrInput formControlName="name">
              <clr-control-helper>{{generalDetailsFg.controls.name?.value?.length || 0}}/64</clr-control-helper>
              <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
              <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{generalDetailsFg.controls.name.errors.invalidCharacters.characters}}</clr-control-error>
              <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{generalDetailsFg.controls.name.value.length || 0}}
                /{{generalDetailsFg.controls.name.errors.maxlength.requiredLength}})</clr-control-error>
            </clr-input-container>
            <clr-input-container class="clr-col-sm-12 clr-col-md-4 clr-col-lg-3">
              <label class="required-custom">FAA Registration Number:</label>
              <input #FAARegInput autocomplete="off" clrInput formControlName="faaRegistrationNumber"
                     (input)="transformInputToUppercase(generalDetailsFg.controls.faaRegistrationNumber, FAARegInput)">
              <clr-control-helper>{{generalDetailsFg.controls.faaRegistrationNumber?.value?.length || 0}}/32</clr-control-helper>
              <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
              <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{generalDetailsFg.controls.faaRegistrationNumber.errors.invalidCharacters.characters}}</clr-control-error>
              <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{generalDetailsFg.controls.faaRegistrationNumber.value.length || 0}}
                /{{generalDetailsFg.controls.faaRegistrationNumber.errors.maxlength.requiredLength}})</clr-control-error>
            </clr-input-container>
            <div class="clr-col-sm-12 clr-col-md-4 clr-col-lg-3">
              <clr-select-container>
                <label class="required-custom">Contact/Owner</label>
                <select autocomplete="off" clrSelect formControlName="ownerContact" name="ownerContact" style="max-width: 166px;">
                  @for (contact of availableContacts$(); track contact.id) {
                    <option [value]="contact.id">{{contact.getName()}}</option>
                  }
                </select>
              </clr-select-container>
        <!--  Uncomment this code to enable the contact selector modal
              <app-owner-contact-selector formControlName="ownerContact"></app-owner-contact-selector>
              <div class="clr-form-control clr-row">
                <label class="clr-control-label clr-col-md-2 clr-col-12">Contact</label>
                <div class="clr-control-container clr-col-md-10 clr-col-12">
                  <div class="clr-input-wrapper">
                    <app-owner-contact-selector formControlName="ownerContact"></app-owner-contact-selector>
                  </div>
                </div>
              </div>-->
              <error-container [control]="generalDetailsFg?.controls.ownerContact">
                <error-message [has]="'required'">This field is required.</error-message>
              </error-container>
            </div>
          </div>

          <h3>Aircraft Specifications</h3>
          <form [formGroup]="specsFg" class="clr-form clr-form-vertical">
            <p class="section-description">Note: All measurement fields use the metric system.</p>
            <div class="clr-row">
              <div class="clr-col-md-12 clr-col-lg-3">
                <div class="clr-row clr-align-items-start">
                  <h4 class="clr-col-sm-12">General</h4>
                  <div class="clr-col-md-4 clr-col-lg-12">
                    <clr-input-container>
                      <label class="required-custom">Serial Number</label>
                      <input #SNInput autocomplete="off" clrInput formControlName="serialNumber"
                             (input)="transformInputToUppercase(specsFg.controls.serialNumber, SNInput)">
                      <clr-control-helper>{{specsFg.controls.serialNumber?.value?.length || 0}}/36</clr-control-helper>
                      <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
                      <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.serialNumber.errors.invalidCharacters.characters}}</clr-control-error>
                      <clr-control-error *clrIfError="'minlength'">Minimum length is {{specsFg.controls.serialNumber.errors.minlength.requiredLength}}
                        characters ({{specsFg.controls.serialNumber.value.length || 0}}/{{specsFg.controls.serialNumber.errors.minlength.requiredLength}})
                      </clr-control-error>
                      <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.serialNumber.value.length || 0}}
                        /{{specsFg.controls.serialNumber.errors.maxlength.requiredLength}})</clr-control-error>
                    </clr-input-container>
                  </div>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label class="required-custom">Manufacturer</label>
                    <input autocomplete="off" clrInput formControlName="manufacturer"/>
                    <clr-control-helper>{{specsFg.controls.manufacturer?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.manufacturer.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.manufacturer.value.length || 0}}
                      /{{specsFg.controls.manufacturer.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label class="required-custom">Make</label>
                    <input autocomplete="off" clrInput formControlName="make"/>
                    <clr-control-helper>{{specsFg.controls.make?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.make.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.make.value.length || 0}}
                      /{{specsFg.controls.make.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label class="required-custom">Model</label>
                    <input autocomplete="off" clrInput formControlName="model"/>
                    <clr-control-helper>{{specsFg.controls.model?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.model.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.model.value.length || 0}}
                      /{{specsFg.controls.model.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Control Link</label>
                    <input autocomplete="off" clrInput formControlName="controlLink"/>
                    <clr-control-helper>{{specsFg.controls.controlLink?.value?.length || 0}}/128</clr-control-helper>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.controlLink.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.controlLink.value.length || 0}}
                      /{{specsFg.controls.controlLink.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label class="clr-control-label">Country of Origin</label>
                    <input autocomplete="off" clrInput formControlName="countryOfOrigin">
                    <clr-control-helper>{{specsFg.controls.countryOfOrigin?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.countryOfOrigin.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.countryOfOrigin.value.length || 0}}
                      /{{specsFg.controls.countryOfOrigin.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label class="clr-control-label">Service Interval (Hours)</label>
                    <input autocomplete="off" clrInput formControlName="serviceInterval" type="number" step="1">
                    <clr-control-error *clrIfError="'min'">Minimum service interval is {{specsFg.controls.serviceInterval.errors.min.min}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'notInteger'">An integer must be entered</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
              <div class="clr-col-md-12 clr-col-lg-3">
                <div class="clr-row clr-align-items-end">
                  <h4 class="clr-col-sm-12">Physical Characteristics</h4>
                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Color</label>
                    <input autocomplete="off" clrInput formControlName="color"/>
                    <clr-control-helper>{{specsFg.controls.color?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.color.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.color.value.length || 0}}
                      /{{specsFg.controls.color.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-select-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Airframe Type</label>
                    <select autocomplete="off" clrSelect formControlName="airframe">
                      <option value="rotary">Rotary</option>
                      <option value="fixed">Fixed Wing</option>
                      <option value="vtol">VTOL</option>
                    </select>
                    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
                  </clr-select-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Number Of Rotors/Motors</label>
                    <input autocomplete="off" clrInput formControlName="numberOfRotors" type="number" step="1"/>
                    <clr-control-error *clrIfError="'min'">Minimum number of rotors is {{specsFg.controls.numberOfRotors.errors.min.min}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'notInteger'">An integer must be entered</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Propeller Length ({{unitLabels.lengthWidthHeight}})</label>
                    <input autocomplete="off" clrInput formControlName="propLength" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Propeller length must be greater than {{specsFg.controls.propLength.errors.greaterThan.min}}
                    </clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Dry Weight ({{unitLabels.weight}})</label>
                    <input autocomplete="off" clrInput formControlName="dryWeight" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Dry weight must be greater than {{specsFg.controls.dryWeight.errors.greaterThan.min}}
                    </clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Length ({{unitLabels.lengthWidthHeight}})</label>
                    <input autocomplete="off" clrInput formControlName="length" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Length must be greater than {{specsFg.controls.length.errors.greaterThan.min}}
                    </clr-control-error>
                  </clr-input-container>

                  @if (specsFg.controls.airframe.value === 'rotary') {
                    <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                      <label>Width ({{unitLabels.lengthWidthHeight}})</label>
                      <input autocomplete="off" clrInput formControlName="width" type="number" step="0.01"/>
                      <clr-control-error *clrIfError="'greaterThan'">Width must be greater than {{specsFg.controls.width.errors.greaterThan.min}}
                      </clr-control-error>
                    </clr-input-container>
                  }

                  @if (specsFg.controls.airframe.value === 'fixed' || specsFg.controls.airframe.value === 'vtol') {
                    <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                      <label>Wingspan ({{unitLabels.lengthWidthHeight}})</label>
                      <input autocomplete="off" clrInput formControlName="wingspan" type="number" step="0.01"/>
                      <clr-control-error *clrIfError="'greaterThan'">Wingspan must be greater than
                        {{specsFg.controls.wingspan.errors.greaterThan.min}}</clr-control-error>
                    </clr-input-container>
                  }

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Height ({{unitLabels.lengthWidthHeight}})</label>
                    <input autocomplete="off" clrInput formControlName="height" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Height must be greater than {{specsFg.controls.height.errors.greaterThan.min}}
                    </clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Primary Construction Material</label>
                    <input autocomplete="off" clrInput formControlName="primaryConstructionMaterial">
                    <clr-control-helper>{{specsFg.controls.primaryConstructionMaterial?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.primaryConstructionMaterial.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.primaryConstructionMaterial.value.length || 0}}
                      /{{specsFg.controls.primaryConstructionMaterial.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
              <div class="clr-col-md-12 clr-col-lg-3">
                <div class="clr-row clr-align-items-end">
                  <h4 class="clr-col-sm-12">Capabilities</h4>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Take Off Weight ({{unitLabels.weight}})</label>
                    <input autocomplete="off" clrInput formControlName="maxTakeOffWeight" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Maximum take off weight must be greater than
                      {{specsFg.controls.maxTakeOffWeight.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Round Trip Range ({{unitLabels.maxRoundTripRange}})</label>
                    <input autocomplete="off" clrInput formControlName="maxRoundTripRange" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Maximum round trip range must be greater than
                      {{specsFg.controls.maxRoundTripRange.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Endurance/Flight Time (Minutes)</label>
                    <input autocomplete="off" clrInput formControlName="maxEnduranceTime" type="number" step="1"/>
                    <clr-control-error *clrIfError="'greaterThan'">Maximum endurance time must be greater than
                      {{specsFg.controls.maxEnduranceTime.errors.greaterThan.min}}</clr-control-error>
                    <clr-control-error *clrIfError="'notInteger'">An integer must be entered</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Airspeed ({{unitLabels.maxAirspeed}})</label>
                    <input autocomplete="off" clrInput formControlName="maxAirspeed" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Maximum airspeed must be greater than
                      {{specsFg.controls.maxAirspeed.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Vertical Speed ({{unitLabels.maxVerticalSpeed}})</label>
                    <input autocomplete="off" clrInput formControlName="maxVerticalSpeed" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Maximum vertical speed must be greater than
                      {{specsFg.controls.maxVerticalSpeed.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Vertical Acceleration ({{unitLabels.maxVerticalAcceleration}}<sup>2</sup>)</label>
                    <input autocomplete="off" clrInput formControlName="maxVerticalAcceleration" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Maximum vertical acceleration must be greater than
                      {{specsFg.controls.maxVerticalAcceleration.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Minimum Operating Temperature ({{unitLabels.operatingTemperature}})</label>
                    <input autocomplete="off" clrInput formControlName="minOperatingTemp" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'min'">Minimum operating temperature is
                    {{specsFg.controls.minOperatingTemp.errors.min.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Maximum Operating Temperature ({{unitLabels.operatingTemperature}})</label>
                    <input autocomplete="off" clrInput formControlName="maxOperatingTemp" type="number" step="0.01"/>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>IP Rating</label>
                    <input autocomplete="off" clrInput formControlName="ipRating"/>
                    <clr-control-error *clrIfError="'pattern'">Invalid IP rating format</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
              <div class="clr-col-md-12 clr-col-lg-3 ">
                <div class="clr-row clr-align-items-start">
                  <h4 class="clr-col-sm-12">Powerplant Specifications</h4>
                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Engine Type</label>
                    <input autocomplete="off" clrInput formControlName="engineType"/>
                    <clr-control-helper>{{specsFg.controls.engineType?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.engineType.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.engineType.value.length || 0}}
                      /{{specsFg.controls.engineType.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Fuel Type</label>
                    <input autocomplete="off" clrInput formControlName="fuelSource"/>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.fuelSource.errors.invalidCharacters.characters}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Fuel Capacity ({{unitLabels.fuelCapacity}})</label>
                    <input autocomplete="off" clrInput formControlName="fuelCapacity" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'min'">Minimum fuel capacity is {{specsFg.controls.fuelCapacity.errors.min.min}}
                    </clr-control-error>
                  </clr-input-container>

                  <h4 class="clr-col-sm-12">Battery Specifications</h4>
                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Number of Batteries</label>
                    <input autocomplete="off" clrInput formControlName="numBatteries" type="number" step="1"/>
                    <clr-control-error *clrIfError="'min'">Minimum number of batteries is {{specsFg.controls.numBatteries.errors.min.min}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'notInteger'">An integer must be entered</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Battery Capacity (mAH)</label>
                    <input autocomplete="off" clrInput formControlName="batteryCapacity" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'min'">Minimum battery capacity is {{specsFg.controls.batteryCapacity.errors.min.min}}
                    </clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Battery Voltage (v)</label>
                    <input autocomplete="off" clrInput formControlName="batteryVoltage" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Battery voltage must be greater than
                      {{specsFg.controls.batteryVoltage.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Battery Weight  ({{unitLabels.weight}})</label>
                    <input autocomplete="off" clrInput formControlName="batteryWeight" type="number" step="0.01"/>
                    <clr-control-error *clrIfError="'greaterThan'">Battery weight must be greater than
                      {{specsFg.controls.batteryWeight.errors.greaterThan.min}}</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="clr-col-md-4 clr-col-lg-12">
                    <label>Battery Chemistry</label>
                    <input autocomplete="off" clrInput formControlName="batteryChemistry"/>
                    <clr-control-helper>{{specsFg.controls.batteryChemistry?.value?.length || 0}}/64</clr-control-helper>
                    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{specsFg.controls.batteryChemistry.errors.invalidCharacters.characters}}</clr-control-error>
                    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{specsFg.controls.batteryChemistry.value.length || 0}}
                      /{{specsFg.controls.batteryChemistry.errors.maxlength.requiredLength}})</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
            </div>
          </form>
        </clr-tab-content>
      </ng-template>
    </clr-tab>

    <clr-tab>
      <button clrTabLink>Telemetry Integrations</button>
      <ng-template [(clrIfActive)]="activeTabStatus.integrations">
        <clr-tab-content>
          <app-edit-telemetry-integrations formControlName="integrations"></app-edit-telemetry-integrations>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>

  <button class="btn btn-primary" (click)="submitPlatform()" [disabled]="generalDetailsFg.invalid">
    {{mode$() === submissionModeEnum.EDIT ? 'Update' : 'Submit'}} Platform</button>
</form>

@if (displaySubmissionModal) {
  <clr-modal [(clrModalOpen)]="displaySubmissionModal">
    <h3 class="modal-title">{{submissionStatus.modalHeading}}</h3>
    <div class="modal-body">
      @switch (submissionStatus.state) {
        @case (submissionStateEnum.PROCESSING) {
          <span class="spinner spinner-inline"> {{submissionStatus.summary}}</span>
          <span> {{submissionStatus.summary}}</span>
        }
        @case (submissionStateEnum.ERROR) {
          <clr-alerts>
            <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <clr-alert-item>
                <span class="alert-text">Error: {{submissionStatus.summary}}</span>
              </clr-alert-item>
            </clr-alert>
          </clr-alerts>
        }
        @default {
          <p>{{submissionStatus.summary}}</p>
        }
      }
    </div>
  </clr-modal>
}
