import {Component, inject, input, Signal} from '@angular/core';
import {User} from '../../model/User';
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {switchMap} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {RoleManagerService, UserRole} from "../../permissions/service/permission.service";

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html'
})
export class UserSummaryComponent {
  private roleManagerService = inject(RoleManagerService);

  user = input<User|undefined>();
  roles: Signal<UserRole[]> = toSignal(toObservable(this.user).pipe(switchMap(user => {
    if (!user) {
      return [];
    }

    return combineLatest(user.roles.map(roleId => {
      return this.roleManagerService.getRole(roleId);
    }));
  })), {
    initialValue: [] as UserRole[]
  });

}
