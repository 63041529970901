import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UserMessageEntry} from '../../../model/UserMessage/UserMessageEntry';
import {UserMessage} from '../../../model/UserMessage/UserMessage';
import {OperatorIntentResult} from '../../../model/UserMessage/OperatorIntentResult';
import {ConformanceAlertMessage} from '../../../model/UserMessage/ConformanceAlertMessage';
import {ConstraintAlertMessage} from '../../../model/UserMessage/ConstraintAlertMessage';
import {OperationDraftMessage} from '../../../model/UserMessage/OperationDraftMessage';
import {OperationConflictAlertMessage} from '../../../model/UserMessage/OperationConflictAlertMessage';
import {UserDirectMessage} from '../../../model/UserMessage/UserDirectMessage';
import {ConformanceState, humanizedOperatorIntentFailureReason} from '../../../model/UserMessage/enums';
import {ApprovalAlertMessage} from "../../../model/UserMessage/ApprovalAlertMessage";
import {ApprovalStatus} from "../../../model/gen/utm";

@Component({
  selector: 'app-user-message-summary-v2',
  templateUrl: './user-message-summary-v2.component.html',
  styleUrls: ['./user-message-summary-v2.component.scss']
})
export class UserMessageSummaryV2Component implements OnChanges {
  @Input() message: UserMessageEntry;

  conformanceState = ConformanceState;
  msg: UserMessage;
  opIntentMsg: OperatorIntentResult;
  opConflictMsg: OperationConflictAlertMessage;
  conformanceMsg: ConformanceAlertMessage;
  constraintAlertMsg: ConstraintAlertMessage;
  userDirectMsg: UserDirectMessage;
  approvalMsg: ApprovalAlertMessage;
  opDraftMsg: OperationDraftMessage;
  REJECTED = ApprovalStatus.REJECTED;
  humanizedOpIntentFailureReason = humanizedOperatorIntentFailureReason;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      this.msg = this.message.message;
      this.opIntentMsg = this.msg.data as OperatorIntentResult;
      this.opConflictMsg = this.msg.data as OperationConflictAlertMessage;
      this.conformanceMsg = this.msg.data as ConformanceAlertMessage;
      this.constraintAlertMsg = this.msg.data as ConstraintAlertMessage;
      this.userDirectMsg = this.msg.data as UserDirectMessage;
      this.approvalMsg = this.msg.data as ApprovalAlertMessage;
      this.opDraftMsg = this.msg.data as OperationDraftMessage;
    }
  }
}
