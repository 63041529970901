import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FussRoutingModule} from './fuss-routing.module';
import {OperationsModule} from './operations/operations.module';
import {AirspaceSupportDataModule} from './airspace-support-data/airspace-support-data.module';
import {OffNominalReportComponent} from './off-nominal-report/off-nominal-report.component';
import {
  ClrCommonFormsModule,
  ClrInputModule,
  ClrModalModule,
  ClrSelectModule,
  ClrSpinnerModule,
  ClrTextareaModule
} from '@clr/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegistrationModule} from './registration/registration.module';
import {ConstraintModule} from './constraint/constraint.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AxAngularMapCesiumModule} from '@ax/ax-angular-map-cesium';
import {CesiumModule} from '../shared/cesium/cesium.module';
import {CesiumVol4dQueryComponent} from './dashboard/cesium-vol4d-query/cesium-vol4d-query.component';
import {InfoboxContainerComponent, NorthButtonComponent, ToolbarExtraButtonContainerComponent} from '@ax-uss-ui/common';


@NgModule({
  declarations: [OffNominalReportComponent, DashboardComponent, CesiumVol4dQueryComponent],
  imports: [
    CommonModule,
    CesiumModule,
    FussRoutingModule,
    OperationsModule,
    ConstraintModule,
    AirspaceSupportDataModule,
    RegistrationModule,
    ClrCommonFormsModule,
    ClrInputModule,
    ClrSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ClrTextareaModule,
    ClrModalModule,
    ClrSpinnerModule,
    AxAngularMapCesiumModule,
    ToolbarExtraButtonContainerComponent,
    NorthButtonComponent,
    InfoboxContainerComponent
  ]
})
export class FussModule {
}
