@let constraintSig = displayedConstraint();
@let constraintVal = displayedConstraint$.value();
@let constraintError = displayedConstraint$.error();

@if (constraintLoading) {
  <div class="spinner-wrapper">
    <span class="spinner"></span>
    <p>Loading constraint</p>
  </div>
} @else if (constraintSig) {
  @if (constraintVal) {
    @let fallbackText = 'Not Available';

    @if (!constraintVal.managed) {
      <div class="clr-row alert-row">
        <div class="clr-col-md-12">
          <clr-alerts>
            <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
              <clr-alert-item>
                <span class="alert-text">This constraint was submitted by an external USS and has limited information available.</span>
              </clr-alert-item>
            </clr-alert>
          </clr-alerts>
        </div>
      </div>
    }
    <div class="clr-row" style="flex-basis: max-content;">
      <div class="clr-col-md-6 clr-col-sm-12">
        <h2 cds-text="section">General Information</h2>
        <table class="table" aria-label="General constraint information">
          <tbody>
            <tr>
              <th scope="row">State</th>
              <td>
                <app-constraint-state-badge [constraint]="constraintVal"></app-constraint-state-badge>
              </td>
            </tr>
            <tr>
              <th scope="row">Constraint Name</th>
              <td class="constraint_description">{{constraintVal.reason}}</td>
            </tr>
            <tr>
              <th scope="row">Constraint ID</th>
              <td>{{constraintVal.message_id}} <app-copy-to-clipboard [text]="constraintVal.message_id"></app-copy-to-clipboard></td>
            </tr>
            <tr>
              <th scope="row">Constraint Type</th>
              <td>{{getPermittedOperations(constraintVal.additional_data) | async}}</td>
            </tr>
            <tr>
              <th scope="row">Effective Begin Time</th>
              <td>{{constraintVal.effective_time_begin.toLocal().toISO()}}</td>
            </tr>
            <tr>
              <th scope="row">Effective End Time</th>
              <td>{{constraintVal.effective_time_end.toLocal().toISO()}}</td>
            </tr>
            <tr>
              <th scope="row">Actual End Time</th>
              <td>{{constraintVal.actual_time_end ? constraintVal.actual_time_end.toLocal().toISO() : fallbackText}}</td>
            </tr>
            <tr>
              <th scope="row">USS Name</th>
              <td>{{constraintVal.uss_name}}</td>
            </tr>
          </tbody>
        </table>
        @if (constraintUser) {
          <div class="submitting-user-wrapper">
            <h2 cds-text="section">Submitting User Information</h2>
            <table class="table table-vertical" aria-label="Submitting user information">
              <tbody>
                <tr>
                  <th scope="row">Title</th>
                  <td>{{constraintUser.title || fallbackText}}</td>
                </tr>
                <tr>
                  <th scope="row">User</th>
                  <td>{{constraintUser.getName() || fallbackText}}</td>
                </tr>
                <tr>
                  <th scope="row">Email Addresses</th>
                  <td>
                    <ul>
                      @for (email of constraintUser.emails; track $index) {
                        <li style="list-style: none;">
                          {{email || fallbackText}}
                        </li>
                      }
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Phone Numbers</th>
                  <td>
                    <ul>
                      @for (phone of constraintUser.phones; track  $index) {
                        <li style="list-style: none;">
                          {{phone || fallbackText}}
                        </li>
                      }
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
      <div class="clr-col-md-6 clr-col-sm-12">
        <div class="cesium-wrapper">
          @if (constraintVal.volumes) {
            <ax-cesium-container [timeline]="false"
                                 [navigationInstructionsInitiallyVisible]="false"
                                 [toolbarZIndex]="'1000'"
                                 [projectionPicker]="true"
                                 [sceneModePicker]="false">
              <lib-infobox-container />
              <app-constraint-volume-drawer [volumes]="constraintVal.volumes"></app-constraint-volume-drawer>
            </ax-cesium-container>
          }
        </div>
      </div>
    </div>

    <div class="clr-row volumes-wrapper">
      <div class="clr-col-md-12">
        <h2 cds-text="section">Constraint Volumes</h2>
        @if (deviceSize$() === 'lg') {
          <table class="table" aria-label="Constraint volumes information">
            <thead>
              <th>Ordinal</th>
              <th>Effective Time Begin</th>
              <th>Effective Time End</th>
              <th>Min Altitude</th>
              <th>Max Altitude</th>
              <th>Submitted Min Altitude</th>
              <th>Submitted Max Altitude</th>
              <th>Geometry</th>
            </thead>
            <tbody>
            @for (volume of constraintVal.volumes; track $index) {
              <tr>
                <td>{{$index + 1}}</td>
                <td>{{volume.effective_time_begin.toISO()}}</td>
                <td>{{volume.effective_time_end.toISO()}}</td>
                <td>
                  <app-ax-altitude-summary [altitude]="volume.min_altitude"
                                           [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude]
                                                : volume.geography.coordinates[0][0]">
                  </app-ax-altitude-summary>
                </td>
                <td>
                  <app-ax-altitude-summary [altitude]="volume.max_altitude"
                                           [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude]
                                                : volume.geography.coordinates[0][0]">
                  </app-ax-altitude-summary>
                </td>
                @if (constraintVal.managed) {
                  <td>
                    <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_min_altitude">
                    </app-ax-altitude-summary>
                  </td>
                  <td>
                    <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_max_altitude">
                    </app-ax-altitude-summary>
                  </td>
                } @else {
                  <td>{{fallbackText}}</td>
                  <td>{{fallbackText}}</td>
                }
                <td>
                  <button type="button" class="btn btn-primary" (click)="viewVolume($index)">View Volume</button>
                </td>
              </tr>
            }
            </tbody>
          </table>
        } @else {
          <clr-datagrid class="datagrid-compact">
            <clr-dg-column>Ordinal</clr-dg-column>
            <clr-dg-column>Effective Time Begin</clr-dg-column>
            <clr-dg-column>Effective Time End</clr-dg-column>

            @for (volume of constraintVal.volumes; track $index) {
              <clr-dg-row>
                <clr-dg-cell>{{$index + 1}}</clr-dg-cell>
                <clr-dg-cell>{{volume.effective_time_begin.toISO()}}</clr-dg-cell>
                <clr-dg-cell>{{volume.effective_time_end.toISO()}}</clr-dg-cell>

                <clr-dg-row-detail *clrIfExpanded>
                  <table class="table table-vertical" aria-label="Volume details">
                    <tbody>
                      <tr>
                        <th scope="row">Min Altitude</th>
                        <td>
                          <app-ax-altitude-summary [altitude]="volume.min_altitude"
                                                   [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude] : volume.geography.coordinates[0][0]">
                          </app-ax-altitude-summary>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Max Altitude</th>
                        <td>
                          <app-ax-altitude-summary [altitude]="volume.max_altitude"
                                                   [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude] : volume.geography.coordinates[0][0]">
                          </app-ax-altitude-summary>
                        </td>
                      </tr>
                      @if (constraintVal.managed) {
                        <tr>
                          <th scope="row">Submitted Min Altitude</th>
                          <td>
                            <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_min_altitude">
                            </app-ax-altitude-summary>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Submitted Max Altitude</th>
                          <td>
                            <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_max_altitude">
                            </app-ax-altitude-summary>
                          </td>
                        </tr>
                      }
                      <tr>
                        <th scope="row"></th>
                        <td>
                          <button type="button" class="btn btn-primary" (click)="viewVolume($index)">View Volume</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </clr-dg-row-detail>
              </clr-dg-row>
            }
          </clr-datagrid>
        }
      </div>

      @for (volume of constraintVal.volumes; track $index) {
        <clr-modal [(clrModalOpen)]="showGeometry[$index]" [clrModalSize]="'xl'">
          <div class="modal-body">
            <div class="cesium-wrapper">
              <ax-cesium-container [timeline]="false"
                                   [navigationInstructionsInitiallyVisible]="false"
                                   [projectionPicker]="true"
                                   [sceneModePicker]="false">
                <lib-infobox-container />
                <app-constraint-volume-drawer [volumes]="[volume]"></app-constraint-volume-drawer>
              </ax-cesium-container>
            </div>
          </div>
        </clr-modal>
      }
    </div>
  }
  @if(constraintError) {
    <clr-alerts>
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text">{{constraintError.message}}</span>
        </clr-alert-item>
      </clr-alert>
    </clr-alerts>
  }
} @else {
  <clr-alerts>
    <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">No constraint provided</span>
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>
}
