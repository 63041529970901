import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {MapConfig} from '@ax/ax-angular-map-common';
import {
  IFederationDomain,
  IFederationPolicy,
  TransportFederationDomain,
  TransportFederationPolicy
} from '../model/FederationPolicy';
import * as _ from 'lodash';
import {UUID} from '@ax/ax-common';
import {Point, Polygon} from "@ax/ax-angular-map-common/lib/models/GeoJSON";
import {IXYZLayerConfig} from "@ax/ax-angular-map-common/lib/models/XYZLayerConfig";
import {IMapBoxLayerConfig} from "@ax/ax-angular-map-common/lib/models/MapBoxLayerConfig";
import {IBingLayerConfig} from "@ax/ax-angular-map-common/lib/models/BingLayerConfig";
import {ICesiumIonTileConfig} from "@ax/ax-angular-map-common/lib/models/CesiumIonLayerConfig";
import {ICesiumTerrainLayerConfig} from "@ax/ax-angular-map-common/lib/models/CesiumTerrainLayerConfig";
import {IEllipsoidTerrainLayerConfig} from "@ax/ax-angular-map-common/lib/models/EllipsoidTerrainLayerConfig";
import {IWMSLayerConfig} from "@ax/ax-angular-map-common/lib/models/WMSLayerConfig";
import {IBaseLayerConfig} from "@ax/ax-angular-map-common/lib/models/api";
import {SupportPoc, TransportSupportPoc} from '@ax-uss-ui/common';

export interface IUssSettings {
  title: string;
  supportPoc: TransportSupportPoc;
  mapConfig: TransportMapConfig;
  knownFederationDomains: TransportFederationDomain[];
  defaultFederationPolicy: TransportFederationPolicy;
  experimentalSettings: TransportExperimentalSettings;
}

export interface TransportExperimentalSettings {
  enableDashboardSupport: boolean;
  enableFederationSupport: boolean;
  enableDirectMessagingSupport: boolean;
}

export interface TransportMapConfig {
  layerConfigs: TransportLayerConfig[];
  defaultImageryLayerId: string | null;
  defaultTerrainLayerId: string | null;
  centerPoint: Point | null;
  defaultBounds: Polygon | null;
}

export declare type TransportLayerConfig = IXYZLayerConfig | IMapBoxLayerConfig | IBingLayerConfig | ICesiumIonTileConfig |
  ICesiumTerrainLayerConfig | IEllipsoidTerrainLayerConfig | TransportAzureMapTileConfig | IWMSLayerConfig;

export interface TransportAzureMapTileConfig extends IBaseLayerConfig {
  accessToken: string;
  layerIds: string[];
}

export interface ExperimentalSettings {
  enableDashboardSupport: boolean;
  enableFederationSupport: boolean;
  enableDirectMessagingSupport: boolean;
}

export interface UssSettings {
  title: string;
  supportPoc: SupportPoc;
  mapConfig: MapConfig;
  knownFederationDomains: IFederationDomain[];
  defaultFederationPolicy: IFederationPolicy;
  experimentalSettings: ExperimentalSettings;
}

export const DEFAULT_USS_SETTINGS: UssSettings = {
  title: '',
  supportPoc: new SupportPoc(),
  mapConfig: new MapConfig([], null, null, null, null),
  knownFederationDomains: [],
  defaultFederationPolicy: {
    allowAll: true,
    domains: [
      'test',
      'comm'
    ]
  },
  experimentalSettings: {
    enableDashboardSupport: false,
    enableFederationSupport: false,
    enableDirectMessagingSupport: false
  }
};

const currentId = UUID.generateUUID();

@Injectable({
  providedIn: 'root'
})
export abstract class SettingsService {
  private readonly currentId: string;

  protected constructor() {
    // Empty constructor
  }

  getCurrentId(): string {
    return currentId;
  }

  getTitle(): Observable<string> {
    return this.getUssName();
  }

  getUssName(): Observable<string> {
    return this.getDistinctRawSettings().pipe(map(s => s.title || 'Clue Federal USS'));
  }

  getMapConfig(): Observable<MapConfig> {
    return this.getDistinctRawSettings().pipe(map(s => s.mapConfig));
  }

  abstract getLogoUrlForOrg(org: string): Observable<string>;

  abstract getRawSettings(): Observable<UssSettings>;

  getDistinctRawSettings(): Observable<UssSettings> {
    return this.getRawSettings().pipe(
      distinctUntilChanged((prev, curr) => {
        return _.isEqual(prev, curr);
      }),
      debounceTime(100)
    );
  }

  abstract refreshSettings(): void;

  abstract updateSettings(settings: UssSettings): Observable<boolean>;

  abstract createSettings(settings: UssSettings): Observable<boolean>;

  getKnownFederationDomains(): Observable<IFederationDomain[]> {
    return this.getRawSettings()
      .pipe(map(s => s.knownFederationDomains || []))
      .pipe(map(_.cloneDeep));
  }

  getDefaultFederationPolicy(): Observable<IFederationPolicy> {
    return this.getRawSettings()
      .pipe(map(s => s.defaultFederationPolicy || DEFAULT_USS_SETTINGS.defaultFederationPolicy))
      .pipe(map(_.cloneDeep));

  }

  getExperimentalSettings(): Observable<ExperimentalSettings> {
    return this.getRawSettings()
      .pipe(map(s => s.experimentalSettings || DEFAULT_USS_SETTINGS.experimentalSettings))
      .pipe(map(_.cloneDeep));
  }

}
