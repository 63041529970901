import { fail, isObject, Result, some } from '../utils';
import { BaseModel, BaseModelSerializerDeserializer } from './common';
import * as v from 'valibot';
import { flattenRootishIssuesToMessage } from '../utils/valibot';

const TPositionSubmissionV2Scheme = v.pipe(
  v.object({
    latitude: v.pipe(v.number(), v.minValue(-90), v.maxValue(90)),
    longitude: v.pipe(v.number(), v.minValue(-180), v.maxValue(180)),
    registration_id: v.pipe(v.string(), v.uuid()),
    time_measured: v.pipe(v.string(), v.isoTimestamp()),
    track_bearing: v.pipe(v.number(), v.minValue(-360), v.maxValue(360)),
    track_ground_speed: v.number(),

    altitude: v.optional(v.number()),
    pressure_alt: v.optional(v.number()),

    roll: v.optional(v.number()),
    pitch: v.optional(v.number()),
    yaw: v.optional(v.number()),
    rssi: v.optional(v.pipe(v.number(), v.integer())),
    operation_id: v.optional(v.string()),
    track_bearing_reference: v.optional(v.string()),
    vertical_speed: v.optional(v.number()),
    height: v.optional(v.number()),
    height_ref: v.optional(v.string()),
    horizontal_accuracy: v.optional(v.number()),
    vertical_accuracy: v.optional(v.number()),
    baro_altitude_accuracy: v.optional(v.number()),
    speed_accuracy: v.optional(v.number()),
    timestamp_accuracy: v.optional(v.number()),
    vdop_gps: v.optional(v.number()),
    hdop_gps: v.optional(v.number()),
    altitude_num_gps_satellites: v.optional(v.number()),
    comments: v.optional(v.string()),
    simulated: v.optional(v.boolean()),
  }),
  v.custom(
    (value) =>
      isObject(value) && ('altitude' in value || 'pressure_alt' in value),
    'altitude or pressure_alt is required'
  ),
  v.custom(
    (value) =>
      isObject(value) && ('height' in value ? 'height_ref' in value : true),
    'height_ref is required if height is provided'
  )
);

export type TPositionSubmissionV2 = v.InferOutput<
  typeof TPositionSubmissionV2Scheme
>;

export class PositionSubmissionV2 implements BaseModel {
  public latitude: number;
  public longitude: number;
  public registrationId: string;
  public timeMeasured: string;
  public trackBearing: number;
  public trackGroundSpeed: number;
  public altitude?: number;
  public pressureAlt?: number;
  public roll?: number;
  public pitch?: number;
  public yaw?: number;
  public rssi?: number;
  public operationId?: string;
  public trackBearingReference?: string;
  public verticalSpeed?: number;
  public height?: number;
  public heightReference?: string;
  public horizontalAccuracy?: number;
  public verticalAccuracy?: number;
  public baroAltitudeAccuracy?: number;
  public speedAccuracy?: number;
  public timestampAccuracy?: number;
  public vdopGPS?: number;
  public hdopGPS?: number;
  public altitudeNumGPSSatellites?: number;
  public comments?: string;
  public simulated?: boolean;

  constructor(values: TPositionSubmissionV2) {
    this.latitude = values.latitude;
    this.longitude = values.longitude;
    this.registrationId = values.registration_id;
    this.timeMeasured = values.time_measured;
    this.trackBearing = values.track_bearing;
    this.trackGroundSpeed = values.track_ground_speed;
    this.altitude = values.altitude;
    this.pressureAlt = values.pressure_alt;

    this.roll = values.roll;
    this.pitch = values.pitch;
    this.yaw = values.yaw;
    this.rssi = values.rssi;
    this.operationId = values.operation_id;
    this.trackBearingReference = values.track_bearing_reference;
    this.verticalSpeed = values.vertical_speed;
    this.height = values.height;
    this.heightReference = values.height_ref;
    this.horizontalAccuracy = values.horizontal_accuracy;
    this.verticalAccuracy = values.vertical_accuracy;
    this.baroAltitudeAccuracy = values.baro_altitude_accuracy;
    this.speedAccuracy = values.speed_accuracy;
    this.timestampAccuracy = values.timestamp_accuracy;
    this.vdopGPS = values.vdop_gps;
    this.hdopGPS = values.hdop_gps;
    this.altitudeNumGPSSatellites = values.altitude_num_gps_satellites;
    this.comments = values.comments;
    this.simulated = values.simulated;
  }
}

function flattenIssuesToMessage(
  result: v.SafeParseResult<typeof TPositionSubmissionV2Scheme>
): string {
  if (result.success) {
    return '';
  }

  const flattenedIssues = v.flatten<typeof TPositionSubmissionV2Scheme>(
    result.issues
  );
  const messageParts: string[] = flattenRootishIssuesToMessage(result);

  for (const [key, nestedIssues] of Object.entries(
    flattenedIssues.nested ?? {}
  )) {
    messageParts.push(
      ...(nestedIssues ?? []).map((issue) => `[${key}]: ${issue}`)
    );
  }
  return messageParts.join('\n');
}

export class PositionSubmissionV2Util
  implements
    BaseModelSerializerDeserializer<
      TPositionSubmissionV2,
      PositionSubmissionV2
    >
{
  deserialize(raw: unknown): Result<PositionSubmissionV2> {
    const result = v.safeParse(TPositionSubmissionV2Scheme, raw);
    if (result.success) {
      const value = result.output;
      return some(new PositionSubmissionV2(value));
    }
    const message = flattenIssuesToMessage(result);

    return fail(message);
  }

  serialize(obj: PositionSubmissionV2): Result<TPositionSubmissionV2> {
    const output: TPositionSubmissionV2 = {
      latitude: obj.latitude,
      longitude: obj.longitude,
      registration_id: obj.registrationId,
      time_measured: obj.timeMeasured,
      track_bearing: obj.trackBearing,
      track_ground_speed: obj.trackGroundSpeed,
      altitude: obj.altitude,
      pressure_alt: obj.pressureAlt,
      roll: obj.roll,
      pitch: obj.pitch,
      yaw: obj.yaw,
      rssi: obj.rssi,
      operation_id: obj.operationId,
      track_bearing_reference: obj.trackBearingReference,
      vertical_speed: obj.verticalSpeed,
      height: obj.height,
      height_ref: obj.heightReference,
      horizontal_accuracy: obj.horizontalAccuracy,
      vertical_accuracy: obj.verticalAccuracy,
      baro_altitude_accuracy: obj.baroAltitudeAccuracy,
      speed_accuracy: obj.speedAccuracy,
      timestamp_accuracy: obj.timestampAccuracy,
      vdop_gps: obj.vdopGPS,
      hdop_gps: obj.hdopGPS,
      altitude_num_gps_satellites: obj.altitudeNumGPSSatellites,
      comments: obj.comments,
      simulated: obj.simulated,
    };
    const result = v.safeParse(TPositionSubmissionV2Scheme, output);
    if (result.success) {
      return some(result.output);
    }
    return fail(flattenIssuesToMessage(result));
  }
}

export const DEFAULT_POSITION_SUBMISSION_V2_UTIL =
  new PositionSubmissionV2Util();
