import {Component, inject, OnDestroy} from '@angular/core';
import {Volume4dQuery} from '../../../shared/models/Volume4dQuery';
import {DateTime} from 'luxon';
import {SituationalAwarenessService} from '../../../shared/services/situational-awareness.service';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {concat, Observable, of, Subscription} from 'rxjs';
import {Camera, Cartesian2, Math, Rectangle, Viewer} from '@cesium/engine';
import {debounceTime, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {toObservable} from '../../../shared/utils/cesium-utils';

function toDegrees(radians: number): number {
  return Math.toDegrees(radians);
}

@Component({
  selector: 'app-cesium-vol4d-query',
  template: ''
})
export class CesiumVol4dQueryComponent implements OnDestroy {

  private situationalAwarenessService = inject(SituationalAwarenessService);
  private drawerService = inject(CesiumService);


  private viewer: Viewer;
  private viewer$ = this.drawerService.watchViewerInit().pipe(tap(viewer => {
    this.viewer = viewer;
  }), shareReplay(1));
  private cameraBounds$ = this.viewer$.pipe(switchMap((viewer: Viewer) => concat(of(viewer.scene.camera),
    toObservable(viewer.scene.camera.moveEnd)).pipe(map(() => viewer.scene.camera))));
  private boxBounds$: Observable<Volume4dQuery> = this.cameraBounds$.pipe(debounceTime(100), map((camera: Camera) => {
    let viewRectangle = camera.computeViewRectangle();

    // Account for viewRectangle being undefined - this can occur due to a known Cesium bug causing computeViewRectangle
    // to return undefined when the scene mode is 2D.
    if (!viewRectangle) {
      const ellipsoid = this.viewer.scene.globe.ellipsoid;
      const cl2 = new Cartesian2(0, 0);
      const leftTop = this.viewer.scene.camera.pickEllipsoid(cl2, ellipsoid);

      const cr2 = new Cartesian2(this.viewer.scene.canvas.width, this.viewer.scene.canvas.height);
      const rightDown = this.viewer.scene.camera.pickEllipsoid(cr2, ellipsoid);

      const leftTopCartographic = ellipsoid.cartesianToCartographic(leftTop);
      const rightDownCartographic = ellipsoid.cartesianToCartographic(rightDown);
      viewRectangle = new Rectangle(leftTopCartographic.longitude, rightDownCartographic.latitude,
        rightDownCartographic.longitude, leftTopCartographic.latitude);
    }

    if (camera.positionCartographic.height > 4000000 || !viewRectangle) {
      return {
        timeWindow: {
          timeEndAfter: DateTime.now(),
          timeBeginBefore: DateTime.now().plus({day: 1})
        },
        spatial: {
          radial: {
            latitude: camera.positionCartographic.latitude,
            longitude: camera.positionCartographic.longitude,
            radius: 2000
          }
        }
      };
    }
    return this.setVolume4dQuery(viewRectangle);
  }));
  private boxBoundsSubscription: Subscription = this.boxBounds$.subscribe((query: Volume4dQuery) => this.situationalAwarenessService.setVolume4dQuery(query));



  ngOnDestroy(): void {
    this.boxBoundsSubscription?.unsubscribe();
  }

  /**
   * Sets the Volume4dQuery for the Situational Awareness Service
   *
   * @param bounds The bounds of the region that is supplied for the spatial query
   * @private
   */
  private setVolume4dQuery(bounds: Rectangle): Volume4dQuery {
    const vol4dQuery: Volume4dQuery = {
      timeWindow: {
        timeEndAfter: DateTime.now(),
        timeBeginBefore: DateTime.now().plus({day: 1})
      },
      spatial: {
        envelope: {
          north: toDegrees(bounds.north),
          west: toDegrees(bounds.west),
          south: toDegrees(bounds.south),
          east: toDegrees(bounds.east)
        }
      }
    };
    return vol4dQuery;
  }
}
