<h1 cds-text="title">Create Constraint</h1>
<form clrForm clrLayout="vertical" [formGroup]="formGroup">
  <div class="clr-row create-root-row clr-flex-md-nowrap">
    <!-- Constraint details form -->
    <div class="clr-col-md-auto">
      <clr-input-container>
        <label class="required-custom">Constraint Name</label>
        <input clrInput autocomplete="off" formControlName="reason">
        <clr-control-helper>{{formGroup.controls.reason?.value?.length || 0}}/1000</clr-control-helper>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s):
          {{formGroup.controls.reason.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.reason.value.length || 0}}
          /{{formGroup.controls.reason.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-input-container>

      <clr-select-container>
        <label class="required-custom">Constraint Type</label>
        <select autocomplete="off" clrSelect formControlName="constraint_type">
          <option *ngFor="let constraintType of availableConstraintTypes"
                  [value]="constraintType.value">{{constraintType.name}}</option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>

      <clr-select-container>
        <label class="required-custom">Vertical Reference</label>
        <select clrSelect formControlName="altitude_vertical_reference">
          <option *ngFor="let option of verticalReferenceIter" [value]="option.value">{{option.label}}</option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>

      <clr-select-container>
        <label class="required-custom">Units of Measure</label>
        <select clrSelect formControlName="altitude_units">
          <option *ngFor="let option of unitsOfMeasureIter" [value]="option.value">{{option.label}}</option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>
      @if (deviceSize$() !== 'sm') {
        <ng-container *ngTemplateOutlet="submitBtn"></ng-container>
      }
    </div>
    <!-- Geometry editor -->
    <div class="clr-col-md-auto clr-flex-md-grow-1">
      <div class="flex-auto-position-fix-outer">
        <div class="flex-auto-position-fix-inner">
          <app-constraint-geometry-editor formControlName="geometry"
                                          [selectedVertRef]="formGroup.controls.altitude_vertical_reference.value"
                                          [selectedUnits]="formGroup.controls.altitude_units.value"
                                          [userSettings]="userSettings"
                                          (altRefChange)="this.updateAltRef($event)"
                                          (altUnitsChange)="this.updateAltUnits($event)">
          </app-constraint-geometry-editor>
          </div>
      </div>
      @if (deviceSize$() === 'sm') {
        <ng-container *ngTemplateOutlet="submitBtn"></ng-container>
      }
    </div>
  </div>
</form>

<clr-modal *ngIf="displaySubmissionProgressModal" [(clrModalOpen)]="displaySubmissionProgressModal" [clrModalStaticBackdrop]="false">
  <h3 class="modal-title">{{this.constraintSubmissionStatusDetails?.modalHeading}}</h3>
  <div class="modal-body">
    <!--    <span class="spinner spinner-inline">-->
    <!--      Submitting Operation...-->
    <!--    </span>-->
    @let intentResult = constraintSubmissionStatusDetails?.intent?.intentResult;
    <app-progress-bar [percent]="constraintSubmissionStatusDetails?.percent"
                      [label]="constraintSubmissionStatusDetails?.summary"
                      [success]="constraintSubmissionStatusDetails?.success"
                      [displayDetails]="intentResult?.success === false"
                      [details]="humanizedOpIntentFailureReason[intentResult?.failureReason]"
    ></app-progress-bar>
  </div>
</clr-modal>

<ng-template #submitBtn>
  <button class="btn btn-primary btn-submit" [disabled]="formGroup.invalid" (click)="submitConstraint()">
    Submit Constraint
  </button>
</ng-template>
