import {OrganizationUtil} from '../utils/OrganizationUtil';
import {Token} from './LoginResponse';
import {Contact, IContact} from './Contact';
import {Parser} from './utm/parser/OperationParser';

export interface IUser {

  uid: string;
  sub: string;
  expiration: number;
  arole: string;
  org: string;

  roles: string[];
  contact: IContact;


  /**
   * @deprecated
   */
  iss?: string;
  /**
   * @deprecated
   */
  aud?: string;


  /**
   * @deprecated
   */
  id?: string;

  /**
   * @deprecated
   */
  subject?: string;

  /**
   * @deprecated
   */
  assertedRole?: string;

  /**
   * @deprecated
   */
  organization?: string;

  /**
   * @deprecated
   */
  manyRoles?: string;


  /**
   * @deprecated
   */
  email?: string;

}

type UserConstructorObject = {
  uid: string;
  sub?: string;
  email?: string;
  roles: string[];
  org: string;
  expiration: number;
  assertedRole: string;
  manyRoles: boolean;
  contact: Contact;
};

export class User {

  uid: string;
  sub?: string;
  roles: string[];
  org: string;
  expiration: number;
  assertedRole: string;
  manyRoles: boolean;
  contact: Contact;

  /**
   * @deprecated
   */
  get email(): string{
    return  this._email || ((this.contact.emails || [''])[0]);
  }

  /**
   * @deprecated
   */
  id?: string;

  /**
   * @deprecated
   */
  iss?: string;

  /**
   * @deprecated
   */
  aud?: string;

  // tslint:disable-next-line:variable-name
  private _email?: string;

  private constructor(obj: UserConstructorObject) {
    this.uid = obj.uid;
    this.roles = obj.roles;
    this.org = obj.org;
    this.expiration = obj.expiration;
    this.assertedRole = obj.assertedRole;
    this.manyRoles = obj.manyRoles;
    this.contact = obj.contact;

    this._email = obj.email;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  get pretty_org() {
    return this.getPrettyOrganizationName();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  // get pretty_roles() {
  //   return this.getPrettyRoleNames();
  // }

  static from(obj: IUser) {
    return new User({
      uid: obj.uid || obj.id,
      sub: obj.sub,
      expiration: obj.expiration || 0,
      assertedRole: obj.arole,
      contact: Parser.parseContact(obj.contact),
      manyRoles: (obj.roles || []).length > 1,
      org: obj.org,
      roles: obj.roles || []
    });
  }

  static fromToken(token: Token) {
    return User.from({
      email: token.subject,
      roles: token.roles,
      org: token.org,
      iss: token.issuer,
      aud: token.audience,
      sub: token.sub,
      expiration: token.expiration,
      arole: token.arole,
      manyRoles: '' + token.manyRoles,
      uid: token.uid,
      contact: token.contact
    });
  }

  isAdmin(): boolean {
    return this.assertedRole === 'admin' || this.assertedRole === 'dev';
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  hasManyRoles() {
    return this.manyRoles;
  }

  getCurrentRole(): string {
    return this.assertedRole;
  }

  getOrg(): string {
    return this.org;
  }

  getExpiration(): number {
    return this.expiration;
  }

  getPrettyOrganizationName() {
    return OrganizationUtil.getPrettyOrganizationName(this.getOrg());
  }
  //
  // getPrettyRoleNames(): string[] {
  //   return this.roles.map(role => OrganizationUtil.getPrettyRoleName(role));
  // }
}


