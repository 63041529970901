<h1 cds-text="title">USS Settings</h1>
<form clrForm clrLayout="vertical" [formGroup]="settingsForm">
  <h3 class="settings-header">General Settings</h3>
  <clr-input-container>
    <label class="required-custom">USS Name</label>
    <input autocomplete="off" clrInput placeholder="USS" formControlName="title"/>
    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{settingsForm.controls.title.value.length || 0}}
      /{{settingsForm.controls.title.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <cds-accordion class="general-settings-accordion">
    <cds-accordion-panel [expanded]="supportPocExpanded">
      <cds-accordion-header (click)="supportPocExpanded = !supportPocExpanded">Support Point of Contact
      </cds-accordion-header>
      <cds-accordion-content>
        <form clrForm clrLayout="vertical" formGroupName="supportPoc">
          <clr-input-container>
            <label>Name</label>
            <input autocomplete="off" clrInput formControlName="name"/>
            <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{settingsForm.controls.supportPoc.controls['name'].value.length || 0}}
              /{{settingsForm.controls.supportPoc.controls['name'].errors.maxlength.requiredLength}})</clr-control-error>
            <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{settingsForm.controls.supportPoc.controls['name'].errors.invalidCharacters.characters}}</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>Phone</label>
            <input autocomplete="off" clrInput formControlName="phone"/>
            <clr-control-error *clrIfError="'pattern'">Invalid phone format</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>Email</label>
            <input autocomplete="off" clrInput formControlName="email"/>
            <clr-control-error *clrIfError="'email'">Invalid email format</clr-control-error>
          </clr-input-container>
        </form>
      </cds-accordion-content>
    </cds-accordion-panel>
  </cds-accordion>

  <h3 class="settings-header">Map Settings</h3>
  <ax-map-config formControlName="mapConfig"></ax-map-config>

  <h3 class="settings-header">Advanced</h3>
  <cds-accordion>
    <cds-accordion-panel [expanded]="experimentalExpanded">
      <cds-accordion-header (click)="experimentalExpanded = !experimentalExpanded">Experimental Settings
      </cds-accordion-header>
      <cds-accordion-content>
        <form clrForm formGroupName="experimentalSettings">
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <label>Enable Dashboard Support</label>
              <input autocomplete="off" type="checkbox" clrCheckbox formControlName="enableDashboardSupport"/>
            </clr-checkbox-wrapper>
            <clr-control-helper>If checked, experimental dashboard support will be enabled.</clr-control-helper>
          </clr-checkbox-container>
<!--          <clr-checkbox-container>-->
<!--            <clr-checkbox-wrapper>-->
<!--              <label>Enable Federation Support</label>-->
<!--              <input autocomplete="off" type="checkbox" clrCheckbox formControlName="enableFederationSupport"/>-->
<!--            </clr-checkbox-wrapper>-->
<!--            <clr-control-helper>If checked, experimental federation support will be enabled.</clr-control-helper>-->
<!--          </clr-checkbox-container>-->
<!--          <clr-checkbox-container>-->
<!--            <clr-checkbox-wrapper>-->
<!--              <label>Enable Direct Messaging Support</label>-->
<!--              <input autocomplete="off" type="checkbox" clrCheckbox formControlName="enableDirectMessagingSupport"/>-->
<!--            </clr-checkbox-wrapper>-->
<!--            <clr-control-helper>If checked, experimental direct messaging support will be enabled.</clr-control-helper>-->
<!--          </clr-checkbox-container>-->
        </form>
        <div *ngIf="settingsForm.controls.experimentalSettings.value.enableFederationSupport">
          <h3 class="settings-header">Federation Settings</h3>
          <h4 class="settings-header">Known Federation Domains</h4>
          <edit-federation-domain-list formControlName="knownFederationDomains"></edit-federation-domain-list>
          <h4 class="settings-header">Default Federation Policy</h4>
          <edit-federation-policy [domains]="knownFederationDomains"
                                  formControlName="defaultFederationPolicy"></edit-federation-policy>
        </div>
      </cds-accordion-content>
    </cds-accordion-panel>
  </cds-accordion>

  <clr-alerts>
    <clr-alert *ngIf="saveSuccess === false" [clrAlertType]="'danger'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">Failed to save settings</span>
      </clr-alert-item>
    </clr-alert>
    <clr-alert *ngIf="!!saveSuccess" [clrAlertType]="'success'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">Settings saved successfully</span>
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>
  <button class="btn btn-primary btn-save-settings" [disabled]="settingsForm.invalid" (click)="saveForm()">Save</button>
</form>
