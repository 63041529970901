import {Component, inject, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {
  Cartesian3,
  Color,
  CustomDataSource,
  Entity,
  Event,
  HeightReference,
  PinBuilder,
  VerticalOrigin
} from '@cesium/engine';
import {CesiumService} from '@ax/ax-angular-map-cesium';

import {BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {IOpGeoSubmissionFG} from '../../components/operation/create-operation/create-operation.component';
import {OperationVolume, units_of_measure, vertical_reference} from '../../model/gen/utm';
import {
  IOpVolumeSubmissionFG
} from '../../components/operation/operation-geometry-editor/operation-geometry-editor.component';
import {isNil} from 'lodash';
import {CesiumDrawerUtilService} from '../common/cesium-drawer-util.service';
import {AltitudeUtilService} from '../../model/utm/altitude-util.service';
import {AltitudeRange} from '../../model/gen/utm/altitude-range-model';
import {Converter} from '../../utils/convert-units';
import {Viewer} from "@cesium/widgets";
import {LatLng} from "leaflet";
import {ColorConfig} from "../../services/color.service";

@Component({
  selector: 'app-operation-geometry-drawer',
  template: ''
})
export class OperationGeometryDrawerComponent implements OnChanges, OnDestroy {
  @Input() geometry: IOpGeoSubmissionFG;
  @Input() colorConfig: ColorConfig;

  private rawGeometrySubject: ReplaySubject<IOpGeoSubmissionFG> = new ReplaySubject<IOpGeoSubmissionFG>(1);
  private dataSource: CustomDataSource = new CustomDataSource('operation-geometry-drawer');
  private colorConfigSubject = new BehaviorSubject<ColorConfig>(null);

  private drawerService = inject(CesiumService);
  private cesiumDrawerUtilService = inject(CesiumDrawerUtilService);
  private altitudeUtilService = inject(AltitudeUtilService);


  geometry$ = this.rawGeometrySubject.pipe(
    switchMap(geometry => this.cleanupGeometry(geometry))
  );

  private entityObservable$ = combineLatest([
    this.geometry$,
    this.colorConfigSubject.pipe(filter(config => !!config))
  ]).pipe(map(([geometry, colorConfig]) => {
    const ret: Entity[] = [];

    for (const vol of geometry.volumes) {
      if ((vol.geography?.coordinates?.length || vol.circle) &&
        !isNil(vol.altitudeRange?.min_altitude) && !isNil(vol.altitudeRange?.max_altitude)) {
        const entity = this.cesiumDrawerUtilService.getOperationVolumeEntity(this.convertOpVolFGtoOpVol(vol), colorConfig);
        ret.push(entity);
      }
    }
    const pinBuilder = new PinBuilder();

    if (geometry.controllerLocation) {
      const controllerLocation = geometry.controllerLocation;

      ret.push(new Entity({
        name: 'Controller Location',
        position: Cartesian3.fromDegrees(controllerLocation.lng, controllerLocation.lat),
        billboard: {
          image: pinBuilder.fromUrl('/assets/gamepad.png', Color.fromCssColorString('#3186CA'), 48) as HTMLCanvasElement,
          verticalOrigin: VerticalOrigin.BOTTOM,
          heightReference: HeightReference.CLAMP_TO_GROUND
        }
      }));
    }

    if (geometry.takeOffLocation) {
      const takeOffLocation = geometry.takeOffLocation;

      ret.push(new Entity({
        name: 'Take Off Location',
        position: Cartesian3.fromDegrees(takeOffLocation.lng, takeOffLocation.lat),
        billboard: {
          image: pinBuilder.fromUrl('/assets/drone_icon.png', Color.fromCssColorString('#2DAC27'), 48) as HTMLCanvasElement,
          verticalOrigin: VerticalOrigin.BOTTOM,
          heightReference: HeightReference.CLAMP_TO_GROUND
        }
      }));
    }
    return ret;
  }));

  drawSub = combineLatest([
    this.drawerService.watchViewerInit(),
    this.entityObservable$
  ]).subscribe(([viewer, entities]) => {
    this.viewer = viewer;
    viewer.dataSources.remove(this.dataSource);
    this.dataSource.entities.removeAll();
    entities.forEach(entity => this.dataSource.entities.add(entity));
    viewer.dataSources.add(this.dataSource).then(() => {
      if (viewer.scene.globe.tilesLoaded) {
        viewer.zoomTo(this.dataSource).then(() => {});
      } else if (!this.terrainTileListenerRemove){
        this.terrainTileListenerRemove = viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
          if (viewer.scene.globe.tilesLoaded){
            viewer.zoomTo(this.dataSource).then(() => {});
            this.terrainTileListenerRemove();
          }
        });
      }
    });
  });

  private viewer: Viewer;
  private terrainTileListenerRemove: Event.RemoveCallback;


  ngOnDestroy(): void {
    this.drawSub?.unsubscribe();
    this.viewer?.dataSources.remove(this.dataSource);
    this.terrainTileListenerRemove?.();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geometry) {
      this.rawGeometrySubject.next(this.geometry);
    }
    if (changes.colorConfig) {
      this.colorConfigSubject.next(this.colorConfig);
    }
  }

  private convertOpVolFGtoOpVol(opVolSub: IOpVolumeSubmissionFG): OperationVolume {
    return new OperationVolume({
      min_altitude: opVolSub.altitudeRange.getMinAlt() || null,
      max_altitude: opVolSub.altitudeRange.getMaxAlt() || null,
      geography: opVolSub.geography || null,
      circle: opVolSub.circle || null
    });
  }

  private cleanupGeometry(geometry: IOpGeoSubmissionFG): Observable<IOpGeoSubmissionFG> {
    if (geometry.volumes.length) {
      return forkJoin(geometry.volumes.map(vol => this.cleanUpVolume4d(vol))).pipe(map(volumes => ({
        volumes,
        points: geometry.points,
        controllerLocation: geometry.controllerLocation,
        takeOffLocation: geometry.takeOffLocation,
      })));
    } else {
      return of({
        volumes: geometry.volumes,
        points: geometry.points,
        controllerLocation: geometry.controllerLocation,
        takeOffLocation: geometry.takeOffLocation,
      });
    }
  }

  private cleanUpVolume4d(volume: IOpVolumeSubmissionFG): Observable<IOpVolumeSubmissionFG> {
    let coordinates: LatLng;
    if (volume.circle) {
      coordinates = new LatLng(volume.circle.latitude, volume.circle.longitude);
    } else if (volume.geography?.coordinates?.length) {
      coordinates =  new LatLng(volume.geography.coordinates[0][0][1], volume.geography.coordinates[0][0][0]);
    }

    return this.altitudeUtilService.convertToWGS84Meters(volume.altitudeRange.getMinAlt(), coordinates).pipe(map(minAlt => {
      const maxOffset = volume.altitudeRange.max_altitude - volume.altitudeRange.min_altitude;
      const convertFromUnits = this.altitudeUtilService.parseUnitForConversion(volume.altitudeRange.altitude_units);
      const maxOffsetMeters = convertFromUnits === 'm' ? maxOffset : new Converter(maxOffset).from(convertFromUnits).to('m');

      return {
        geography: volume.geography,
        circle: volume.circle,
        altitudeRange: new AltitudeRange({
          min_altitude: minAlt.altitude_value,
          max_altitude: minAlt.altitude_value + maxOffsetMeters,
          altitude_units: units_of_measure.M,
          altitude_vertical_reference: vertical_reference.W84
        }),
        timeRange: volume.timeRange,
        offNominal: volume.offNominal
      };
    }));
  }
}
