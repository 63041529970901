import {inject, Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {User} from '../model/User';
import {NullRole, RoleManagerService, UserRole} from "../permissions/service/permission.service";

export interface ExtendedUser {
  user: User;
  role: UserRole;
  roles: UserRole[];
}

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private authService = inject(AuthService);
  private roleManagerService = inject(RoleManagerService);

  readonly currentUser: Observable<User> = this.authService.watchCurrentUser();


  readonly currentExtendedUser: Observable<ExtendedUser> = this.currentUser.pipe(
    switchMap(user => {
      if (!user) {
        return of({
          user,
          role: NullRole,
          roles: []
        })
      }
      return this.roleManagerService.getRolesForUser(user).pipe(map(roles => {
        const role = roles.find(r => r.id === user.assertedRole) ?? NullRole;
        return {
          user,
          role,
          roles
        };
      }));
    }),
    shareReplay(1)
  );


  isAdmin(): Observable<boolean> {
    return this.currentUser.pipe(map(u => {
      if (u) {
        return u.isAdmin();
      } else {
        return false;
      }
    }));
  }
}
