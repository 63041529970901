<clr-main-container>
  @if (isAuthenticated) {
    <app-user-message-alert-bar></app-user-message-alert-bar>
  }
  <clr-header class="header-6">
    <div class="branding">
      @if (isAuthenticated) {
        <a [routerLink]="['/']" class="nav-link">
          <img alt="AX Enterprize" class="clr-icon logo" src="{{envService.getLogoUrlForOrg(user?.getOrg()) | async}}">
          <span class="title">{{ ussName }}</span>
        </a>
      }
    </div>

    <div class="header-actions">
      @if (isAuthenticated && responsiveService$.deviceSize$() !== 'sm') {
        <div class="header-icons clr-justify-content-end">
          @if (enableExperimentalDirectMessagingSupport) {
            <button class="btn btn-link message-icon nav-link nav-icon"
              aria-label="Direct User Messages"
              (click)="openSendMessageModal()">
              <cds-icon shape="chat-bubble" class="is-solid"></cds-icon>
            </button>
          }
          <a [routerLink]="['messages']" class="nav-link nav-icon" aria-label="User Notifications">
            <cds-icon shape="bell" [size]="'md'" [solid]="true"></cds-icon>
          </a>
          <clr-dropdown class="user-menu dropdown">
            <cds-icon shape="user" [solid]="true" clrDropdownTrigger></cds-icon>
            <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
              @let role = currentRole();
              @let roles = availableRoles();

              <h4 class="dropdown-header current-user">{{ user.contact.getName(false) }}</h4>
              @if (!(roles && user.hasManyRoles())) {
                <h4 class="dropdown-header">Current
                Role: {{ role.displayName }}</h4>
              }
              @if (roles && user.hasManyRoles()) {
                <h4 class="dropdown-header">Change Role:</h4>
                <clr-dropdown class="left-bottom">
                  <button class="btn btn-outline-primary" clrDropdownTrigger>
                    {{ role.displayName }}
                  </button>
                  <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                    <label class="dropdown-header" aria-hidden="true">Available Roles</label>
                    @for (availableRole of roles; track availableRole) {
                      <div aria-label="{{role}}"
                        [clrDisabled]="role.id === availableRole.id"
                        (click)="assertRole(availableRole)" clrDropdownItem>
                        {{ availableRole.displayName }}
                      </div>
                    }
                  </clr-dropdown-menu>
                </clr-dropdown>
              }
              <div class="dropdown-divider" role="separator"></div>
              <a clrDropdownItem [routerLink]="['user-settings']">
                User Settings
                <cds-icon shape="cog" size="{{24 * uiScale}}" [solid]="true"></cds-icon>
              </a>
              <div aria-label="logout" (click)="logout()" clrDropdownItem>
                Logout
                <cds-icon shape="logout" size="{{24 * uiScale}}" [solid]="true"></cds-icon>
              </div>
            </clr-dropdown-menu>
          </clr-dropdown>
        </div>
      }
    </div>
  </clr-header>

  <div class="content-container">
    @if (isAuthenticated) {
      <clr-vertical-nav class="sidebar-menu" [clrVerticalNavCollapsible]="true"
        [clr-nav-level]="1">
        <a clrVerticalNavLink routerLink="/home" routerLinkActive="active">
          <cds-icon clrVerticalNavIcon shape="home"></cds-icon>
          Home
        </a>
        @if (enableExperimentalDashboardSupport) {
          <a clrVerticalNavLink routerLink="dashboard" routerLinkActive="active">
            <cds-icon clrVerticalNavIcon shape="display"></cds-icon>
            Dashboard
          </a>
        }
        <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
          <cds-icon shape="drone" clrVerticalNavIcon></cds-icon>
          UAS Operations
          <clr-vertical-nav-group-children>
            <a clrVerticalNavLink *hasPermission="'view_operation'" [routerLink]="['fuss', 'operations', 'ops']"
            [routerLinkActive]="'active'" [class]="operationClasses">View UAS Operations</a>
            <a clrVerticalNavLink *hasPermission="'view_approvals'" [routerLink]="['fuss', 'operations', 'approvals']"
              [routerLinkActive]="'active'">Operation Approvals
              @if (proposedOperationsCount$() > 0) {
                <span class="badge badge-danger">{{ proposedOperationsCount$() }}</span>
              }
            </a>
            <a clrVerticalNavLink *hasPermission="'submit_operation'" [routerLink]="['fuss', 'operations', 'newop']"
            [routerLinkActive]="'active'">Submit New Operation</a>
          </clr-vertical-nav-group-children>
        </clr-vertical-nav-group>
        <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
          <cds-icon shape="lock" clrVerticalNavIcon></cds-icon>
          Constraint Management
          <clr-vertical-nav-group-children>
            <a clrVerticalNavLink *hasPermission="'view_constraint'" [routerLink]="['fuss', 'constraint', 'constraints']"
            [routerLinkActive]="'active'">View Constraints</a>
            <a clrVerticalNavLink *hasPermission="'submit_constraint'"
              [routerLink]="['fuss', 'constraint', 'new-constraint']"
            [routerLinkActive]="'active'">Submit New Constraint</a>
          </clr-vertical-nav-group-children>
        </clr-vertical-nav-group>
        <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
          <cds-icon shape="view-list" clrVerticalNavIcon></cds-icon>
          Registration
          <clr-vertical-nav-group-children>
            <a clrVerticalNavLink *hasPermission="'view_platform'" [routerLink]="['fuss', 'registration', 'platforms']"
            [routerLinkActive]="'active'">View Platforms</a>
            <a clrVerticalNavLink *hasPermission="'submit_platform'"
              [routerLink]="['fuss', 'registration', 'new-platform']"
            [routerLinkActive]="'active'">Submit New Platform</a>
            <a clrVerticalNavLink *hasPermission="'view_user'" [routerLink]="['fuss', 'registration', 'users']"
            [routerLinkActive]="'active'">View Users</a>
            <a clrVerticalNavLink *hasPermission="'submit_user'" [routerLink]="['fuss', 'registration', 'new-user']"
            [routerLinkActive]="'active'">Create New User</a>
          </clr-vertical-nav-group-children>
        </clr-vertical-nav-group>
        <clr-vertical-nav-group *hasPermission="'submit_setting'" routerLinkActive="active"
          [clrVerticalNavGroupExpanded]="true">
          <cds-icon shape="cog" clrVerticalNavIcon></cds-icon>
          Settings
          <clr-vertical-nav-group-children>
            <a clrVerticalNavLink [routerLink]="['settings']" routerLinkActive="active">USS Settings</a>
            <a clrVerticalNavLink [routerLink]="['user-settings']" routerLinkActive="active">User Settings</a>
          </clr-vertical-nav-group-children>
        </clr-vertical-nav-group>
        <a *hasntPermission="'submit_setting'" clrVerticalNavLink [routerLink]="['user-settings']"
          routerLinkActive="active">
          <cds-icon clrVerticalNavIcon shape="cog"></cds-icon>
          User Settings
        </a>
        <a clrVerticalNavLink [routerLink]="['about']" routerLinkActive="active">
          <cds-icon clrVerticalNavIcon shape="help-info"></cds-icon>About
        </a>
      </clr-vertical-nav>
    }

    <div class="content-area">
      <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>

    <ng-container *hasPermission="'submit_approvals'">
      @if (audioAlertsService.autoplayPermitted$() === false) {
        <cds-card class="toast-notification">
          <div class="card-block">
            <div class="card-title"><cds-icon shape="volume-mute" solid="true" class="mute-icon"></cds-icon>Unable to Play Audio Alerts</div>
            <div class="card-text">Notification sound is unable to play for new operation approval requests. Browsers require
            user interaction before they will play audio. In order to receive audio alerts, please click Okay.</div>
          </div>
          <div class="card-footer">
            <cds-button action="solid" (click)="audioAlertsService.refreshAutoplayPermitted()">Okay</cds-button>
          </div>
        </cds-card>
      }
    </ng-container>
  </div>

  @if (responsiveService$.deviceSize$() === 'sm' && isAuthenticated) {
    @let role = currentRole();
    @let roles = availableRoles();
    <clr-vertical-nav [clrVerticalNavCollapsible]="true" [clr-nav-level]="2" class="responsive-user-menu">
      <a clrVerticalNavLink class="current-user">{{ user.contact.getName(false) }}</a>

      @if (!(roles && user.hasManyRoles())) {
        <a clrVerticalNavLink class="current-role">
        Current Role: {{ role.displayName }}</a>
      }

      @if ((roles && user.hasManyRoles())) {
        <clr-vertical-nav-group routerLinkActive="active" [clrVerticalNavGroupExpanded]="false"
          >
          <span class="vertical-nav-group-text">Change Role: {{ role.displayName }}</span>
          <clr-vertical-nav-group-children>
            @for (availableRole of roles; track availableRole) {
              <a clrVerticalNavLink aria-label="{{role}}"
                [ngClass]="{disabled: role.id === availableRole.id}"
                (click)="(role.id !== availableRole.id) && assertRole(role)">
              {{ availableRole.displayName }}</a>
            }
          </clr-vertical-nav-group-children>
        </clr-vertical-nav-group>
      }

      <a clrVerticalNavLink [routerLink]="['messages']" routerLinkActive="active">
        <span class="nav-text">
          <cds-icon clrVerticalNavIcon shape="bell" [solid]="true" [badge]="getNotificationClasses()"></cds-icon>
          Messages
        </span>
      </a>

      <a clrVerticalNavLink [routerLink]="['user-settings']" routerLinkActive="active">
        <span class="nav-text">
          <cds-icon clrVerticalNavIcon shape="cog"></cds-icon>
          User Settings
        </span>
      </a>

      <a class="nav-link" style="cursor:pointer;" (click)="logout()">
        <span class="nav-text">
          <cds-icon clrVerticalNavIcon shape="logout" [solid]="true"></cds-icon>
          Logout
        </span>
      </a>
    </clr-vertical-nav>
  }
</clr-main-container>

@if (showLogoutPrompt) {
  <cds-modal (closeChange)="refreshToken()">
    <cds-modal-header>
      <h3 cds-text="title" cds-first-focus tabindex="-1">Logout Warning</h3>
    </cds-modal-header>
    <cds-modal-content>
      <p cds-text="body">{{ timeLeftMsg }} left until logout.</p>
    </cds-modal-content>
    <cds-modal-actions>
      <cds-button action="outline" (click)="logout()">Logout</cds-button>
      <cds-button (click)="refreshToken()">Stay logged in</cds-button>
    </cds-modal-actions>
  </cds-modal>
}

@if (enableExperimentalDirectMessagingSupport) {
  <app-send-direct-message-modal></app-send-direct-message-modal>
}
