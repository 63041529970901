import {Injectable} from '@angular/core';
import {
  DEFAULT_USS_SETTINGS,
  ExperimentalSettings,
  IUssSettings,
  SettingsService,
  TransportAzureMapTileConfig,
  TransportExperimentalSettings,
  TransportLayerConfig,
  TransportMapConfig,
  UssSettings
} from '../settings.service';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {AzureMapLayerConfig, LayerConfig, MapConfig, MapParser} from '@ax/ax-angular-map-common';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {IStandardResponse} from '../../model/StandardResponse';
import {IFederationPolicy, TransportFederationPolicy} from '../../model/FederationPolicy';
import {IAzureMapTileSubLayerConfig} from "@ax/ax-angular-map-common/lib/models/AzureMapLayerConfig";
import {cloneDeep} from "lodash";
import {DEFAULT_SUPPORT_POC_UTIL, SupportPoc} from '@ax-uss-ui/common';

interface SettingsResponse extends IStandardResponse {
  setting?: IUssSettings;
}

@Injectable({
  providedIn: 'root'
})
export class RestSettingsService extends SettingsService {
  private readonly rawSettingsUrl = `${environment.baseUrl}/userserv/api/settings`;

  private rawSettingsSubject: ReplaySubject<UssSettings> = new ReplaySubject<UssSettings>(1);
  private rawSettingsSub: Subscription;

  constructor(private http: HttpClient) {
    super();
  }

  getLogoUrlForOrg(org: string): Observable<string> {
    switch (org) {
      case 'af':
      case 'army':
      case 'axe':
      case 'cpd':
      case 'dhs':
      case 'dod':
      case 'doi':
      case 'faa':
      case 'fbi':
      case 'fema':
      case 'nasa':
      case 'navy':
      case 'nova':
      case 'nysp':
      case 'nyuas':
      case 'xyz':
        return of(`/assets/img/orgs/${org}-logo.png`);
      default:
        return of(`/assets/img/orgs/default-logo.png`);

    }
  }

  getRawSettings(): Observable<UssSettings> {
    return this.rawSettingsSubject;
  }

  refreshSettings(): void {
    this.rawSettingsSub?.unsubscribe();
    this.rawSettingsSub = this.http.get(this.rawSettingsUrl).pipe(map((rawSettings: SettingsResponse): UssSettings => {
      if (!rawSettings.success) {
        return DEFAULT_USS_SETTINGS;
      }
      return this.parseSettings(rawSettings);
    })).subscribe({
      next: (s) => {
        this.rawSettingsSub?.unsubscribe();
        this.rawSettingsSub = null;
        this.rawSettingsSubject.next(s);
      },
      error: (e) => {
        console.error('Error fetching USS settings:', e);
      }
    });
  }

  updateSettings(settings: UssSettings): Observable<boolean> {
    const submission: IUssSettings = this.serializeSettings(settings);
    return this.http.patch(`${environment.baseUrl}/userserv/api/settings`, submission).pipe(map((response: SettingsResponse) => response.success));
  }

  createSettings(settings: UssSettings): Observable<boolean> {
    const submission: IUssSettings = this.serializeSettings(settings);
    return this.http.post(`${environment.baseUrl}/userserv/api/settings`, submission).pipe(map((response: SettingsResponse) => response.success));
  }

  private serializeSettings(settings: UssSettings): IUssSettings {
    const supportPocResult = DEFAULT_SUPPORT_POC_UTIL.serialize(settings.supportPoc)
    return {
      title: settings.title,
      supportPoc: supportPocResult.orElse(undefined),
      mapConfig: this.serializeMapConfig(settings.mapConfig),
      knownFederationDomains: (settings.knownFederationDomains || []).map(d => ({
          id: d.id,
          pretty_name: d.prettyName
        })),
      defaultFederationPolicy: this.serializeFederationPolicy(settings.defaultFederationPolicy),
      experimentalSettings: this.serializeExperimentalSettings(settings.experimentalSettings)
    };
  }

  private serializeMapConfig(mapConfig: MapConfig): TransportMapConfig {
    const transportLayerConfigs: TransportLayerConfig[] = mapConfig.getLayerConfigs().map((layerConfig: LayerConfig) => {
      if (layerConfig.type === 'azure-map') {
        const azureLayerConfig: TransportAzureMapTileConfig = {
          type: layerConfig.type,
          layerType: layerConfig.layerType,
          name: layerConfig.name,
          id: layerConfig.id,
          accessToken: layerConfig.access_token,
          layerIds: layerConfig.layers.map((subLayer: IAzureMapTileSubLayerConfig) => subLayer.id)
        }
        return azureLayerConfig;
      } else {
        return layerConfig;
      }
    })

    return {
      centerPoint: mapConfig.getCenterPoint(),
      defaultBounds: mapConfig.getDefaultBounds(),
      defaultImageryLayerId: mapConfig.getDefaultImageryLayerId(),
      defaultTerrainLayerId: mapConfig.getDefaultTerrainLayerId(),
      layerConfigs: transportLayerConfigs
    };
  }
  private serializeFederationPolicy(policy: IFederationPolicy): TransportFederationPolicy {
    return {
      allow_all: policy.allowAll,
      domains: policy.domains
    };
  }

  private serializeExperimentalSettings(experimentalSettings: ExperimentalSettings): TransportExperimentalSettings {
    return {
      enableDashboardSupport: experimentalSettings.enableDashboardSupport,
      enableFederationSupport: experimentalSettings.enableFederationSupport,
      enableDirectMessagingSupport: experimentalSettings.enableDirectMessagingSupport
    };
  }

  private parseSettings(settings: SettingsResponse): UssSettings {
    const mapConfig: TransportMapConfig = cloneDeep(settings.setting.mapConfig);
    if (mapConfig?.layerConfigs) {
      mapConfig.layerConfigs = mapConfig.layerConfigs.map((layerConfig: TransportLayerConfig) => {
        if (layerConfig.type === 'azure-map') {
          const transportAzureConfig = layerConfig as TransportAzureMapTileConfig;
          return new AzureMapLayerConfig(layerConfig.id, transportAzureConfig.name,
            transportAzureConfig.accessToken, transportAzureConfig.layerIds);
        } else {
          return layerConfig;
        }
      });
    }

    const supportPoc = DEFAULT_SUPPORT_POC_UTIL.deserialize(settings.setting.supportPoc);

    return {
      title: settings.setting.title,
      supportPoc: supportPoc.type === 'some' ? supportPoc.value : new SupportPoc(),
      mapConfig: mapConfig ? MapParser.parseMapConfig(mapConfig) : undefined,
      knownFederationDomains: (settings.setting.knownFederationDomains || []).map(d => ({
        prettyName: d.pretty_name,
        id: d.id
      })),
      defaultFederationPolicy: this.parseFederationPolicy(settings.setting.defaultFederationPolicy),
      experimentalSettings: this.parseExperimentalSettings(settings.setting.experimentalSettings)
    };
  }

  private parseFederationPolicy(policy: TransportFederationPolicy): IFederationPolicy {
    return {
      allowAll: policy?.allow_all ?? DEFAULT_USS_SETTINGS.defaultFederationPolicy.allowAll,
      domains: policy?.domains ?? DEFAULT_USS_SETTINGS.defaultFederationPolicy.domains
    };
  }

  private parseExperimentalSettings(experimentalSettings: TransportExperimentalSettings): ExperimentalSettings {
    return {
      enableDashboardSupport: experimentalSettings?.enableDashboardSupport ?? DEFAULT_USS_SETTINGS.experimentalSettings.enableDashboardSupport,
      enableFederationSupport: experimentalSettings?.enableFederationSupport ?? DEFAULT_USS_SETTINGS.experimentalSettings.enableFederationSupport,
      enableDirectMessagingSupport: experimentalSettings?.enableDirectMessagingSupport ?? DEFAULT_USS_SETTINGS.experimentalSettings.enableDirectMessagingSupport
    };
  }
}
