import {
  Component,
  computed,
  effect,
  EventEmitter,
  forwardRef,
  inject,
  Inject,
  input,
  Input,
  OnDestroy,
  Output,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AXLeafletWidget, LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import {
  ImportExportMode,
  ImportExportService,
  ImportExportServiceToken,
  ImportExportType
} from '../../import-export/import-export.service';
import {Subscription} from 'rxjs';
import {Errorable} from '../../utils/optional';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
  providers: [
    {provide: AXLeafletWidget, useExisting: forwardRef(() => ImportExportComponent)}
  ]
})
export class ImportExportComponent extends AXLeafletWidget implements OnDestroy {
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;
  type = input<ImportExportType>();
  @Input() exportData: any;
  @Input() disableExport = false;

  @Output() importResult: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportResult: EventEmitter<any> = new EventEmitter<any>();

  importDialogDisplay = false;
  exportDialogDisplay = false;

  /* eslint-disable @typescript-eslint/naming-convention */
  IMPORT = ImportExportMode.IMPORT;
  EXPORT = ImportExportMode.EXPORT;
  errorMessage: string;

  static compareServices(a: ImportExportService, b: ImportExportService) {
    return `${a.getPriority()}__${a.getFormatName()}`.localeCompare(`${b.getPriority()}__${b.getFormatName()}`);
  }

  /* eslint-enable @typescript-eslint/naming-convention */
  private map: L.Map;
  private mapSub: Subscription;

  private services = signal<ImportExportService[]>(inject<ImportExportService[]>(ImportExportServiceToken));
  private sortedServices = computed(() => this.services().sort(ImportExportComponent.compareServices));

  // availableServices: ImportExportService[];
  availableImportServices = computed(() => {
    let currentType = this.type();
    let services = this.sortedServices();

    const validServiceTypes = new Set([currentType, ImportExportType.UNIVERSAL]);
    return services
      .filter((service) => service.getMode() === ImportExportMode.IMPORT)
      .filter((service) => validServiceTypes.has(service.getType()));
  });
  defaultImportService = effect(() => {
    const availableServices = this.availableImportServices();

    if(this.importServiceControl.value === null && availableServices.length > 0){
      // Set the default import type to "JSON (Ultra)" if available
      const ultraJsonServiceIndex = availableServices.findIndex(service => service.getFormatName() === 'JSON (Ultra)');
      this.importServiceControl.setValue(ultraJsonServiceIndex !== -1 ? availableServices[ultraJsonServiceIndex] : availableServices[0]);
    }

  });
  defaultExportService = effect(() => {
    const availableServices = this.availableExportServices();
    if(this.exportServiceControl.value === null && availableServices.length > 0){
      this.exportServiceControl.setValue(availableServices[0]);
    }
  });

  availableExportServices = computed(() => {
    let currentType = this.type();
    return this.sortedServices()
      .filter((service) => service.getMode() === ImportExportMode.EXPORT)
      .filter((service) => service.getType() === currentType);
  });


  importServiceControl = new FormControl<ImportExportService>(null, []);
  exportServiceControl = new FormControl<ImportExportService>(null, []);


  get topRightWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  get generalWidget(): TemplateRef<any> {
    return this.modalTemplate;
  }

  constructor(private leafletMapService: LeafletDrawerService, @Inject(ImportExportServiceToken) services: ImportExportService[]) {
    super();

    this.mapSub = this.leafletMapService.watchViewerInit().subscribe((m) => {
      this.map = m;
    });
  }

  handleImport() {
    this.setImportDialogDisplay(true);
  }

  handleExport() {
    this.setExportDialogDisplay(true);
  }

  handleImportResult($event: Errorable<any>) {
    if ($event.type === 'error'){
      this.errorMessage = $event.message;
    } else if ($event.type === 'some') {
      this.errorMessage = null;
      this.setImportDialogDisplay(false);
      this.importResult.emit($event.value);
    }
  }

  handleExportResult($event: any) {
    this.setExportDialogDisplay(false);
    this.exportResult.emit($event);
  }

  setImportDialogDisplay($event: boolean) {
    this.importDialogDisplay = $event;
    if (!$event) {
      this.enableMap();
    } else {
      this.errorMessage = null;
      this.disableMap();
    }
  }

  setExportDialogDisplay($event: boolean) {
    this.exportDialogDisplay = $event;
    if (!$event) {
      this.enableMap();
    } else {
      this.disableMap();
    }
  }

  ngOnDestroy(): void {
    this.mapSub?.unsubscribe();
  }

  private enableMap() {
    if (!this.map) {
      return;
    }

    this.map.dragging.enable();
    this.map.touchZoom.enable();
    this.map.doubleClickZoom.enable();
    this.map.scrollWheelZoom.enable();
    this.map.boxZoom.enable();
    this.map.keyboard.enable();
    if (this.map.tap) {
      this.map.tap.enable();
    }
    this.map.getContainer().style.cursor = 'grab';
  }

  private disableMap() {
    if (!this.map) {
      return;
    }
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) {
      this.map.tap.disable();
    }
    this.map.getContainer().style.cursor = 'default';
  }

}
