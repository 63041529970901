import {Component, inject, input, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CesiumService} from "@ax/ax-angular-map-cesium";
import {takeUntilDestroyed, toObservable, toSignal} from "@angular/core/rxjs-interop";
import {
  Cartesian3,
  ConstantPositionProperty,
  ConstantProperty,
  CustomDataSource,
  HeightReference,
  HorizontalOrigin,
  VerticalOrigin
} from "@cesium/engine";
import {Observable, of, pairwise, startWith} from "rxjs";

import {switchMap} from "rxjs/operators";
import { PositionSubmissionV2 } from '../../models';
import {uuidv4} from "../../utils";

@Component({
  selector: 'lib-cesium-telemetry-drawer',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: ``,
})
export class CesiumTelemetryDrawerComponent implements OnDestroy {
  position$ = input<Observable<PositionSubmissionV2 | null>>(of(null));

  private cesiumService = inject(CesiumService);
  private viewer$ = this.cesiumService.watchViewerInit();
  private viewer = toSignal(this.viewer$);

  private ds = new CustomDataSource();
  private telemetryIcon = 'assets/track-icons/uas-telemetry-icon.png';
  private telemetryId = uuidv4();

  constructor() {
    const telemetryEntity = this.ds.entities.add({
      id: this.telemetryId,
      billboard: {
        show: false,
        image: this.telemetryIcon,
        scale: 0.12,
        horizontalOrigin: HorizontalOrigin.CENTER,
        verticalOrigin: VerticalOrigin.CENTER,
        disableDepthTestDistance: 1000000000000,
        heightReference: HeightReference.NONE,
      }
    });

    this.viewer$.pipe(
      takeUntilDestroyed(),
      startWith(null),
      pairwise()
    ).subscribe(([previousViewer, viewer]) => {

      previousViewer?.dataSources.remove(this.ds);
      viewer?.dataSources.add(this.ds);

    });

    toObservable(this.position$).pipe(
      takeUntilDestroyed(),
      switchMap(position$ => {
        return position$;
      }),
    ).subscribe(position => {
      if (!position) {
        telemetryEntity.billboard!.show = new ConstantProperty(false);
        return;
      }
      const cesiumPosition = Cartesian3.fromDegrees(position.longitude, position.latitude, position.altitude);
      telemetryEntity.position = new ConstantPositionProperty(cesiumPosition);
      telemetryEntity.billboard!.show = new ConstantProperty(true);
    });

  }

  ngOnDestroy() {
    this.viewer()?.dataSources.remove(this.ds);
  }

}
