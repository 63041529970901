import {Component, forwardRef} from '@angular/core';
import {ClrDatagridFilterInterface} from '@clr/angular';
import {Subject} from 'rxjs';
import {state} from '../../../../../shared/model/gen/utm';
import {OperationUtil} from '../../../../../shared/model/OperationUtil';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Parser} from '../../../../../shared/model/utm/parser/OperationParser';
import {IOperation} from '../../../../../shared/model/gen/transport/utm';
import StateEnum = IOperation.StateEnum;

@Component({
  selector: 'app-operation-states-filter',
  templateUrl: './operation-states-filter.component.html',
  styleUrls: ['./operation-states-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => OperationStateFilterComponent),
  }]
})
export class OperationStateFilterComponent implements ClrDatagridFilterInterface<any>, ControlValueAccessor {
  changes = new Subject<any>();
  active = false;
  statesFg = new FormGroup({
    PROPOSED: new FormControl<boolean>(false),
    ACCEPTED: new FormControl<boolean>(false),
    ACTIVATED: new FormControl<boolean>(false),
    CLOSED: new FormControl<boolean>(false),
    REJECTED: new FormControl<boolean>(false),
  });
  availableStateOptions: state[] = [
    state.PROPOSED,
    state.ACCEPTED,
    state.ACTIVATED,
    state.CLOSED,
    state.REJECTED
  ];

  private onChange: any;

  private valueChanges$ = this.statesFg.valueChanges.subscribe(() => {
    const rawValues = this.statesFg.getRawValue();
    const selectedStates: state[] = [];
    let isActivatedSelected = false;

    Object.keys(rawValues).forEach(k => {
      // If the checkbox is checked/true, add the state to the selectedStates array
      if (!!rawValues[k]) {
        const parsedState = Parser.parseState(k as StateEnum);
        if (!!parsedState) {
          selectedStates.push(parsedState);
          if (parsedState === state.ACTIVATED) {
            isActivatedSelected = true;
          }
        }
      }
    });

    // If only one checkbox is checked, disable it so that at least one box is checked at all times
    if (selectedStates.length === 1) {
      this.statesFg.controls[selectedStates[0]].disable({emitEvent: false});
    } else {
      // Otherwise, all checkboxes should be enabled
      this.statesFg.enable({emitEvent: false});
    }

    // If the ACTIVATED state is checked, set the ROGUE and NONCONFORMING states to true
    // This must occur after the selectedStates length check in order for it to accurately reflect the number of states
    // that are checked in the UI
    if (isActivatedSelected) {
      selectedStates.push(state.NONCONFORMING);
      selectedStates.push(state.ROGUE);
    }

    if (this.onChange) {
      this.onChange(selectedStates);
    }
  });

  isActive(): boolean {
    return !!this.active;
  }

  accepts(val: any): boolean {
    return true;
  }

  getPrettyOperationStateName(s: state): string {
    return OperationUtil.getPrettyOperationStateName(s);
  }

  toggleSelectAll() {
    // This only works one way (enable). Disabling all checkboxes is prevented by the
    // minimum requirement of one box being checked at any given time.
    this.statesFg.setValue({
      PROPOSED: true,
      ACCEPTED: true,
      ACTIVATED: true,
      CLOSED: true,
      REJECTED: true
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(selectedStates: state[]): void {
    this.statesFg.reset({
      PROPOSED: false,
      ACCEPTED: false,
      ACTIVATED: false,
      CLOSED: false,
      REJECTED: false
    }, {emitEvent: false, onlySelf: true});

    selectedStates?.forEach(s => {
      // If ROGUE or NONCONFORMING states are selected, check the ACTIVATED state
      if (s === state.NONCONFORMING || s === state.ROGUE) {
        this.statesFg.controls.ACTIVATED.setValue(true, {emitEvent: false, onlySelf: true});
      } else {
        this.statesFg.controls[s]?.setValue(true, {emitEvent: false, onlySelf: true});
      }
    });
  }
}
