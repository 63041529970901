import {BaseModel, BaseModelSerializerDeserializer} from './common';
import {fail, isEmpty, isNullish, isObject, Result, some} from '../utils';

export type TransportSupportPoc = undefined | {
  name?: string;
  phone?: string;
  email?: string;
}

export class SupportPoc implements BaseModel {
  name: string|null;
  phone: string|null;
  email: string|null;

  constructor(name?: string, phone?: string, email?: string) {
    this.name = name || null;
    this.phone = phone || null;
    this.email = email || null;
  }

}

export class SupportPocUtil implements BaseModelSerializerDeserializer<TransportSupportPoc, SupportPoc> {
  static isEmpty(obj: SupportPoc): boolean {
    if(!obj) return true;
    return !obj.name && !obj.phone && !obj.email;
  }

  deserialize(raw: unknown): Result<SupportPoc> {
    if (isNullish(raw)) return some(new SupportPoc());
    if (!isObject(raw)) return fail('Invalid data supplied for support PoC');

    let name: string | undefined = undefined;
    if ('name' in raw && !isEmpty(raw.name)) {
      if (typeof raw.name !== 'string') return fail('Invalid support PoC name');
      name = raw.name;
    }

    let phone: string | undefined = undefined;
    if ('phone' in raw && !isEmpty(raw.phone)) {
      if (typeof raw.phone !== 'string') return fail('Invalid support PoC phone number');
      phone = raw.phone;
    }

    let email: string | undefined = undefined;
    if ('email' in raw && !isEmpty(raw.email)) {
      if (typeof raw.email !== 'string') return fail('Invalid support PoC email');
      email = raw.email;
    }

    return some(new SupportPoc(name, phone, email));
  }

  serialize(obj: SupportPoc): Result<TransportSupportPoc> {
    if(SupportPocUtil.isEmpty(obj)){
      return some(undefined);
    }

    return some({
      name: obj?.name || undefined,
      phone: obj?.phone || undefined,
      email: obj?.email || undefined
    });

  }
}

export const DEFAULT_SUPPORT_POC_UTIL = new SupportPocUtil();
