import {RoleManagerService, UserRole} from "../permissions/service/permission.service";
import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";

const hardcodedRoles: Record<string, UserRole> = {
  pilot: {
    id: 'pilot',
    displayName: 'Pilot',
    description: "",
    isHidden: false,
    permissions: new Set([
      'view_operation',
      'modify_operation',
      'submit_operation',
      'activate_operation',
      'view_constraint',
      'view_platform',
      'submit_platform'
    ])
  },
  mcom:{
    id: 'mcom',
    displayName: 'Mission Commander',
    description: "",
    isHidden: false,
    permissions: new Set([
      'view_operation',
      'modify_operation',
      'submit_operation',
      'activate_operation',
      'view_constraint',
      'submit_constraint',
      'view_platform',
      'view_user',
      'view_approvals'
    ])
  },
  atc:{
    id: 'atc',
    displayName: 'Air Traffic Controller',
    description: "",
    isHidden: false,
    permissions: new Set([
      'view_approvals',
      'submit_approvals',
      'view_constraint',
      'submit_constraint',
      'view_operation',
      'view_platform',
      'view_user',
    ])
  },
  observer:{
    id: 'observer',
    displayName: 'Observer',
    description: "",
    isHidden: false,
    permissions: new Set([
      'view_constraint',
      'view_operation',
      'view_platform',
      'view_user',
    ])
  },
  admin:{
    id: 'admin',
    displayName: 'Administrator',
    description: "",
    isHidden: false,
    permissions: new Set([
      'view_operation',
      'view_constraint',
      'view_platform',
      // 'submit_platform',
      'view_user',
      'submit_user',
      'delete_user',
      'submit_setting'
    ])
  }
}

export function createEmptyRole(id: string): UserRole{
  return {
    id,
    displayName: id,
    description: "",
    isHidden: true,
    permissions: new Set<string>()
  }
}

@Injectable()
export class MockRoleManagerService extends RoleManagerService{
  getRole(roleId: string): Observable<UserRole> {
    if(!hardcodedRoles[roleId]){
      console.error(`Role with id ${roleId} not found`);
      return of(createEmptyRole(roleId));
    }

    return of(hardcodedRoles[roleId]);
  }

  getAvailableRoles(): Observable<UserRole[]> {
    return of(Object.values(hardcodedRoles) as UserRole[]);
  }

}
