<h1 cds-text="title">{{submissionMode$() === submissionModeEnum.EDIT ? 'Edit' : 'Create'}} User</h1>
<form [formGroup]="formGroup" clrForm>
  <div class="clr-row">
    <div class="clr-col-md-6">
      <h3>General Information</h3>
      <clr-input-container>
        <label class="required-custom">Email</label>
        <input autocomplete="off" clrInput type="text" formControlName="email"/>
        <clr-control-helper>{{formGroup.controls.email?.value?.length || 0}}/50</clr-control-helper>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'email'">Invalid email format</clr-control-error>
        <clr-control-error *clrIfError="'minlength'">Minimum length is {{formGroup.controls.email.errors.minlength.requiredLength}}
          characters ({{formGroup.controls.email.value.length || 0}}/{{formGroup.controls.email.errors.minlength.requiredLength}})
        </clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.email.value.length || 0}}
          /{{formGroup.controls.email.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <label [ngClass]="{'required-custom': this.submissionMode$() === submissionModeEnum.CREATE}">Password</label>
        <input clrPassword autocomplete="new-password" type="password" formControlName="password"/>
        <clr-control-helper>{{formGroup.controls.password?.value?.length || 0}}/64</clr-control-helper>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.password.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'minlength'">Minimum length is {{formGroup.controls.password.errors.minlength.requiredLength}}
          characters ({{formGroup.controls.password.value.length || 0}}/{{formGroup.controls.password.errors.minlength.requiredLength}})
        </clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.password.value.length || 0}}
          /{{formGroup.controls.password.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-password-container>
      <clr-password-container>
        <label [ngClass]="{'required-custom': this.submissionMode$() === submissionModeEnum.CREATE || formGroup.controls.password?.value}">
          Confirm Password</label>
        <input clrPassword autocomplete="new-password" type="password" formControlName="passwordConfirmation"/>
        <clr-control-helper>{{formGroup.controls.passwordConfirmation?.value?.length || 0}}/64</clr-control-helper>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.passwordConfirmation.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'minlength'">Minimum length is {{formGroup.controls.passwordConfirmation.errors.minlength.requiredLength}}
          characters ({{formGroup.controls.passwordConfirmation.value.length || 0}}/{{formGroup.controls.passwordConfirmation.errors.minlength.requiredLength}})
        </clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.passwordConfirmation.value.length || 0}}
          /{{formGroup.controls.passwordConfirmation.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-password-container>
      <error-container [control]="formGroup">
        <error-message [has]="'passwordConfirmation'">Passwords do not match</error-message>
      </error-container>
      <app-label-select [prettyName]="'Roles'" formControlName="roles" [options]="availableRoles()" [required]="true"
                        [helperText]="'The first selected role will be the default for the user at login. \nOnly one role can be used at a time.'">
      </app-label-select>
      <error-container [control]="formGroup.controls.roles">
        <error-message [has]="'required'">At least one role is required.</error-message>
      </error-container>
      <clr-select-container>
        <label class="required-custom">Organization</label>
        <select autocomplete="off" clrSelect formControlName="org" >
          @for(option of organizations$(); track $index){
            <option [value]="option.value">{{option.label}}</option>
          }
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>
    </div>

    <div class="clr-col-md-6">
      <app-edit-contact [altEmails]="true" formControlName="contact"></app-edit-contact>
    </div>
  </div>

  <button class="btn btn-primary" (click)="submitUser()" [disabled]="formGroup.invalid">
    {{submissionMode$() === submissionModeEnum.EDIT ? 'Update' : 'Create'}} User</button>
</form>

<clr-modal *ngIf="displaySubmissionProgressModal" [(clrModalOpen)]="displaySubmissionProgressModal">
  <h3 class="modal-title">{{submissionStatus.modalHeading}}</h3>
  <div class="modal-body">
    <ng-container [ngSwitch]="submissionStatus.state">
      <ng-container *ngSwitchCase="submissionStateEnum.PROCESSING">
        <span class="spinner spinner-inline"> {{submissionStatus.summary}}</span>
        <span> {{submissionStatus.summary}}</span>
      </ng-container>
      <clr-alerts *ngSwitchCase="submissionStateEnum.ERROR">
        <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
          <clr-alert-item>
            <span class="alert-text">Error: {{submissionStatus.summary}}</span>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts>
      <p *ngSwitchDefault>{{submissionStatus.summary}}</p>
    </ng-container>
  </div>
</clr-modal>
