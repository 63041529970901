import {Component, effect, forwardRef, inject, input, OnDestroy} from '@angular/core';
import {UasRegistrationInfo} from '../../../model/UasRegistrationInfo';
import {PlatformService} from '../../../services/platform.service';
import {AbstractControl, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {IAircraftSpecs} from '../../../model/AircraftSpecs';
import {IAdditionalRegistration} from '../../../model/AdditionalRegistration';
import {double, integer} from '../../../model/DataTypes';
import {
  enforcedPatternRegexes,
  forbiddenPatternRegexes,
  greaterThan,
  invalidCharactersValidator,
  isInteger
} from '../../../utils/Validators';
import {UserSettingsService} from '../../../services/user-settings.service';
import {metricLabels} from '../platformUnits';
import {ContactService} from '../../../services/contact.service';
import * as _ from 'lodash';
import {ISubmissionStatus, SubmissionMode, SubmissionState} from '../../../model/SubmissionStatus';
import {HttpErrorResponse} from '@angular/common/http';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TelemetryIntegrations} from '../../../model/TelemetryIntegrations/TelemetryIntegrations';
import {requiredWhiteSpace} from '@ax-uss-ui/common';

interface IActiveTabStatus {
  generalDetails: boolean,
  integrations: boolean
}

@Component({
  selector: 'app-edit-platform',
  templateUrl: './edit-platform.component.html',
  styleUrls: ['./edit-platform.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditPlatformComponent),
    }
  ]
})

export class EditPlatformComponent implements OnDestroy {
  mode$ = input<SubmissionMode>(SubmissionMode.CREATE);
  platform$ = input<UasRegistrationInfo>();

  specsFg = new FormGroup({
    serialNumber: new FormControl<string>(null, [requiredWhiteSpace, Validators.minLength(2),
    Validators.maxLength(36), invalidCharactersValidator(forbiddenPatternRegexes.serialNumber)]),
    manufacturer: new FormControl<string>(null, [requiredWhiteSpace, Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    make: new FormControl<string>(null, [requiredWhiteSpace, Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    model: new FormControl<string>(null, [requiredWhiteSpace, Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    color: new FormControl<string>(null, [Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    primaryConstructionMaterial: new FormControl<string>(null, [Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    dryWeight: new FormControl<double>(null, [greaterThan(0)]),
    length: new FormControl<double>(null, [greaterThan(0)]),
    width: new FormControl<double>(null, [greaterThan(0)]),
    height: new FormControl<double>(null, [greaterThan(0)]),
    numberOfRotors: new FormControl<integer>(null, [Validators.min(0), isInteger]),
    propLength: new FormControl<double>(null, [greaterThan(0)]),
    numBatteries: new FormControl<integer>(null, [Validators.min(0), isInteger]),
    batteryCapacity: new FormControl<double>(null, [Validators.min(0)]),
    batteryVoltage: new FormControl<double>(null, [greaterThan(0)]),
    batteryWeight: new FormControl<double>(null, [greaterThan(0)]),
    batteryChemistry: new FormControl<string>(null, [Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    maxTakeOffWeight: new FormControl<double>(null, [greaterThan(0)]),
    maxEnduranceTime: new FormControl<integer>(null, [greaterThan(0), isInteger]),
    minOperatingTemp: new FormControl<double>(null, [(control: AbstractControl) =>
      Validators.min(-272)(control)]),
    maxOperatingTemp: new FormControl<double>(null),
    ipRating: new FormControl<string>(null, [Validators.pattern(enforcedPatternRegexes.ipRating)]),
    engineType: new FormControl<string>(null, [Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    fuelSource: new FormControl<string>(null, [
      invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    fuelCapacity: new FormControl<double>(null, [Validators.min(0)]),
    controlLink: new FormControl<string>(null, [Validators.maxLength(128),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpacesCommas)]),
    accessoriesInstalled: new FormControl<string[]>([], {validators: [Validators.maxLength(20)], nonNullable: true}),
    countryOfOrigin: new FormControl<string>('', [ Validators.maxLength(64),
    invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpaces)]),
    // Physical Characteristics
    airframe: new FormControl<string>('rotary', {validators: [(control) => {
      if (!control.value) {
        return null;
      }

      switch (control.value) {
        case 'rotary':
        case 'fixed':
        case 'vtol':
          return null;
        default:
          return {required: true};
      }
    }], nonNullable: true}),
    wingspan: new FormControl<double>(null, [greaterThan(0)]),
    // Capabilities
    maxRoundTripRange: new FormControl<double>(null, [greaterThan(0)]),
    maxAirspeed: new FormControl<double>(null, [greaterThan(0)]),
    maxVerticalSpeed: new FormControl<double>(null, [greaterThan(0)]),
    maxVerticalAcceleration: new FormControl<double>(null, [greaterThan(0)]),
    serviceInterval: new FormControl<integer>(null, [Validators.min(1), isInteger])
  },{validators: [Validators.required]});

  generalDetailsFg = new FormGroup({
    name: new FormControl<string>(null, [requiredWhiteSpace, Validators.maxLength(64),
      invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpaces)]),
    faaRegistrationNumber: new FormControl<string>(null, [requiredWhiteSpace,
      Validators.maxLength(32), invalidCharactersValidator(forbiddenPatternRegexes.upperAlphanumeric)]),
    tailNumber: new FormControl<string>(null, []),
    ownerContact: new FormControl<string>(null, [Validators.required]),
    ownerIs: new FormControl<'contact' | 'org'>('org', {validators: [Validators.required], nonNullable: true}),
    additionalRegistrations: new FormControl<IAdditionalRegistration[]>([], {nonNullable: true}),
    aircraftSpecs: this.specsFg,
    integrations: new FormControl<TelemetryIntegrations>(null)
  });

  unitLabels = metricLabels;
  submissionStateEnum = SubmissionState;
  submissionModeEnum = SubmissionMode;
  submissionStatus: ISubmissionStatus;
  displaySubmissionModal = false;
  activeTabStatus: IActiveTabStatus = {
    generalDetails: true,
    integrations: false
  }

  availableContacts$ = toSignal(inject(ContactService).getContacts({}, 100)
    .pipe(map(contacts => contacts.results)));

  private onChange: (value: UasRegistrationInfo) => void;
  private clearForm$ = toSignal(inject(UserSettingsService).getClearFormAfterSubmission()
    .pipe(map(settings => settings.platformSubmission)), {initialValue: false});
  private submissionSub: Subscription;
  private updateSub: Subscription;

  constructor(private platformService: PlatformService) {
    // Set form values whenever the platform input changes
    effect(() => {
      if (this.platform$()) {
        this.setValues(this.platform$());
      }
    });
  }

  /**
   * Submits the platform form values to the respective endpoint for creation or modification
   */
  submitPlatform() {
    const values = this.getSubmission();
    if (this.onChange) {
      this.onChange(values);
    } else {
      this.submissionStatus = {
        state: SubmissionState.PROCESSING,
        modalHeading: (this.mode$() === SubmissionMode.CREATE ? 'Submitting ' : 'Updating ') + 'Platform',
        summary: (this.mode$() === SubmissionMode.CREATE ? 'Submitting ' : 'Updating ') + 'Platform...'
      };
      this.displaySubmissionModal = true;

      if (this.mode$() === SubmissionMode.CREATE) {
        this.submissionSub = this.platformService.submitPlatform(values).subscribe((res) => {
          this.submissionStatus = {
            state: SubmissionState.SUCCESS,
            modalHeading: `Platform Created`,
            summary: `Platform has been created successfully`
          };
          if (this.clearForm$()) {
            this.generalDetailsFg.reset();
            this.activeTabStatus = {
              generalDetails: true,
              integrations: false
            }
          }
        }, (error: HttpErrorResponse) => {
          this.submissionStatus = {
            state: SubmissionState.ERROR,
            modalHeading: `Platform Creation Failed`,
            summary: error?.error?.message || `Unknown error during platform creation`
          };
        });
      } else if (this.mode$() === SubmissionMode.EDIT) {
        this.updateSub = this.platformService.updatePlatform(this.platform$().uvin, values).subscribe((res) => {
          this.submissionStatus = {
            state: SubmissionState.SUCCESS,
            modalHeading: `Platform Updated`,
            summary: `Platform has been updated successfully`
          };
        }, (error: HttpErrorResponse) => {
          this.submissionStatus = {
            state: SubmissionState.ERROR,
            modalHeading: `Platform Update Failed`,
            summary: error?.error?.message || `Unknown error during platform update`
          };
        });
      }
    }
  }

  registerOnChange(fn: (value: UasRegistrationInfo) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  /**
   * Sets the form values to the provided UasRegistrationInfo object
   * @param values - The UasRegistrationInfo object to set the form values to
   */
  setValues(values: UasRegistrationInfo) {
    this.generalDetailsFg.patchValue({
      name: values.name,
      tailNumber: values.tailNumber,
      faaRegistrationNumber: values.faaRegistrationNumber,
      ownerContact: values.ownerContact?.id,
      ownerIs: values.ownerIsOrganization ? 'org' : 'contact',
      aircraftSpecs: values.aircraftSpecs,
      additionalRegistrations: values.additionalRegistrations,
      integrations: values.integrations
    });
    this.generalDetailsFg.markAllAsTouched();
  }

  /**
   * Transforms a form input value to uppercase
   * @param control - The form control to be transformed
   * @param inputElement - The input element being targeted
   */
  transformInputToUppercase(control: FormControl, inputElement: HTMLInputElement) {
    const val = control.getRawValue();
    if (val) {
      const currentCursorPosition = inputElement.selectionStart;
      control.setValue(val.toUpperCase());
      control.updateValueAndValidity();

      // Reposition the cursor to the position after content was entered. This is necessary because the cursor will have
      // moved to the end of the input after calling setValue().
      inputElement.selectionStart = currentCursorPosition;
      inputElement.selectionEnd = currentCursorPosition;
    }
  }

  ngOnDestroy(): void {
    this.submissionSub?.unsubscribe();
    this.updateSub?.unsubscribe();
  }

  /**
   * Returns the submission object for the aircraft specs form fields
   * @param aircraftSpecs - The aircraft specs object to be transformed
   */
  private getAircraftSpecsSubmission(aircraftSpecs: IAircraftSpecs): IAircraftSpecs {
    return {
      serialNumber: aircraftSpecs.serialNumber,
      manufacturer: aircraftSpecs.manufacturer,
      make: aircraftSpecs.make,
      model: aircraftSpecs.model,
      color: aircraftSpecs.color || null,
      primaryConstructionMaterial: aircraftSpecs.primaryConstructionMaterial || null,
      dryWeight: !_.isNil(aircraftSpecs.dryWeight) ? aircraftSpecs.dryWeight : null,
      length: !_.isNil(aircraftSpecs.length) ? aircraftSpecs.length : null,
      width: !_.isNil(aircraftSpecs.width) ? aircraftSpecs.width : null,
      height: !_.isNil(aircraftSpecs.height) ? aircraftSpecs.height : null,
      numberOfRotors: !_.isNil(aircraftSpecs.numberOfRotors) ? aircraftSpecs.numberOfRotors : null,
      propLength: !_.isNil(aircraftSpecs.propLength) ? aircraftSpecs.propLength : null,
      numBatteries: !_.isNil(aircraftSpecs.numBatteries) ? aircraftSpecs.numBatteries : null,
      batteryCapacity: !_.isNil(aircraftSpecs.batteryCapacity) ? aircraftSpecs.batteryCapacity : null,
      batteryVoltage: !_.isNil(aircraftSpecs.batteryVoltage) ? aircraftSpecs.batteryVoltage : null,
      batteryWeight: !_.isNil(aircraftSpecs.batteryWeight) ? aircraftSpecs.batteryWeight : null,
      batteryChemistry: aircraftSpecs.batteryChemistry || null,
      maxTakeOffWeight: !_.isNil(aircraftSpecs.maxTakeOffWeight) ? aircraftSpecs.maxTakeOffWeight : null,
      maxEnduranceTime: !_.isNil(aircraftSpecs.maxEnduranceTime) ? aircraftSpecs.maxEnduranceTime : null,
      minOperatingTemp: !_.isNil(aircraftSpecs.minOperatingTemp) ? aircraftSpecs.minOperatingTemp : null,
      maxOperatingTemp: !_.isNil(aircraftSpecs.maxOperatingTemp) ? aircraftSpecs.maxOperatingTemp : null,
      ipRating: aircraftSpecs.ipRating || null,
      engineType: aircraftSpecs.engineType || null,
      fuelSource: aircraftSpecs.fuelSource || null,
      fuelCapacity: !_.isNil(aircraftSpecs.fuelCapacity) ? aircraftSpecs.fuelCapacity : null,
      airframe: aircraftSpecs.airframe ? aircraftSpecs.airframe : 'rotary',
      maxRoundTripRange: !_.isNil(aircraftSpecs.maxRoundTripRange) ? aircraftSpecs.maxRoundTripRange : null,
      maxAirspeed: !_.isNil(aircraftSpecs.maxAirspeed) ? aircraftSpecs.maxAirspeed : null,
      maxVerticalSpeed: !_.isNil(aircraftSpecs.maxVerticalSpeed) ? aircraftSpecs.maxVerticalSpeed : null,
      maxVerticalAcceleration: !_.isNil(aircraftSpecs.maxVerticalAcceleration) ? aircraftSpecs.maxVerticalAcceleration : null,
      wingspan: ((aircraftSpecs.airframe === 'fixed' || aircraftSpecs.airframe === 'vtol') && !_.isNil(aircraftSpecs.wingspan)) ?
        aircraftSpecs.wingspan : null,
      controlLink: aircraftSpecs.controlLink || null,
      accessoriesInstalled: aircraftSpecs.accessoriesInstalled || [],
      countryOfOrigin: aircraftSpecs.countryOfOrigin,
      serviceInterval: !_.isNil(aircraftSpecs.serviceInterval) ? aircraftSpecs.serviceInterval : null
    };
  }

  /**
   * Returns the submission object for the platform form fields
   */
  private getSubmission(): UasRegistrationInfo {
    const raw = this.generalDetailsFg.getRawValue();
    return new UasRegistrationInfo({
      name: raw.name,
      additionalRegistrations: raw.additionalRegistrations,
      aircraftSpecs: this.getAircraftSpecsSubmission(raw.aircraftSpecs),
      faaRegistrationNumber: raw.faaRegistrationNumber,
      ownerContact: this.availableContacts$().find(contact => contact.id === raw.ownerContact),
      ownerIsOrganization: raw.ownerIs === 'org',
      tailNumber: raw.tailNumber,
      integrations: raw.integrations
    });
  }
}
