import {PermissionContext, PermissionService} from '../permissions/service/permission.service';
import {Observable} from 'rxjs';
import {AxPermissionRequest} from '../permissions/AxPermissionRequest';
import {CurrentUserService} from './current-user.service';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {OperationExt} from '../model/utm/OperationExt';


@Injectable()
export class MockPermissionService extends PermissionService {

  evaluateRequest(req: AxPermissionRequest<any>): Observable<boolean> {
    return this.currentUserService.currentExtendedUser.pipe(
      map(curUser => {
      const user = curUser.user;
      const role = curUser.role;
      return this.evaluateWithContext(req, {
        user,
        role
      });

    }));
  }

  evaluateRequestWithAnyRoles(req: AxPermissionRequest<any>): Observable<boolean> {
    return this.currentUserService.currentExtendedUser.pipe(
      map(curUser => {
        const user = curUser.user;
        for(const role of curUser.roles){
          if(this.evaluateWithContext(req, {user, role})){
            return true;
          }
        }
        return false;

      }));
  }


  constructor(private currentUserService: CurrentUserService) {
    super();
  }

  private evaluateWithContext(req: AxPermissionRequest<any>, ctx: PermissionContext): boolean {
    const user = ctx.user;
    const role = ctx.role;

    if (!user || !role){
      return false;
    }

    // Additional checks for actions requiring that the user is the submitting user for the resource
    if (req.getResourceType() === 'operation' && (req.getAction() === 'modify' || req.getAction() === 'activate')) {
      const operation = req.getResource() as OperationExt | undefined;
      if (user.uid !== operation?.additional_data?.user_id) {
        return false;
      }
    }

    if(!role.permissions){
      return false;
    }
    return role.permissions.has(req.toString())
  }

}
