<h1 cds-text="title">About This USS</h1>
@if (settings$()) {
  @let supportPoc = settings$().supportPoc;
  <h2 cds-text="section">Support</h2>
  @if (supportPoc?.name || supportPoc?.phone || supportPoc?.email) {
    <p>For assistance with this USS, please contact {{supportPoc?.name || 'support'}}.<br>
      @if (supportPoc?.phone) {
        Phone: <a href="tel:{{supportPoc.phone}}" target="_blank">{{supportPoc.phone}}</a><br>
      }
      @if (supportPoc?.email) {
        Email: <a href="mailto:{{supportPoc.email}}" target="_blank">{{supportPoc.email}}</a><br>
      }
    </p>
  } @else {
    <p>A support contact has not been configured for this USS. Please contact an administrator for assistance.</p>
  }
}

<h2 cds-text="section">Version</h2>
<p>{{ussVersion || 'Unavailable'}}</p>
