import {Component, DestroyRef, effect, inject, input, signal} from '@angular/core';
import {UvrExt} from '../../../model/utm/UvrExt';
import {UserService} from '../../../services/user.service';
import {Contact} from '../../../model/Contact';
import {AdditionalConstraintData} from '../../../model/utm/AdditionalConstraintData';
import {combineLatest, Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {ConstraintTypeService} from '../../../services/constraint-type.service';
import {ConstraintService} from '../../../services/constraint.service';
import {ResponsiveScreenService} from '../../../services/responsive-screen.service';
import {fail, Result, resultSignal, some} from '@ax-uss-ui/common';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {filter, switchMap, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-constraint-ext-summary',
  templateUrl: './constraint-ext-summary.component.html',
  styleUrls: ['./constraint-ext-summary.component.scss']
})
export class ConstraintExtSummaryComponent {
  constraintId = input<string | null>(null);
  constraint = input<UvrExt | null>(null);

  displayedConstraint = signal<Result<UvrExt> | null>(null);
  displayedConstraint$ = resultSignal(this.displayedConstraint);
  constraintLoading = true;
  constraintUser: Contact | null;
  showGeometry = [];

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  // If a constraint is provided via input, set displayedConstraint to that constraint
  // Otherwise, if a constraintId is provided, update the constraintIdToFetch signal with its value. Later on, this is
  // used to fetch the constraint details.
  constraintIdToFetch = signal(null);
  constraintInputSub = combineLatest([toObservable(this.constraint), toObservable(this.constraintId)])
    .pipe(takeUntilDestroyed())
    .subscribe(([constraint, constraintId]) => {
      if (constraint) {
        this.displayedConstraint.set(some(constraint));
        this.constraintLoading = false;
        this.constraintIdToFetch.set(null);
      } else if (constraintId) {
        this.constraintIdToFetch.set(constraintId);
      } else {
        this.constraintIdToFetch.set(null);
      }
    });

  private destroyRef = inject(DestroyRef);

  constructor(private userService: UserService,
              private constraintTypeService: ConstraintTypeService,
              private constraintService: ConstraintService) {
    // If a constraintId is provided via input, fetch the associated constraint
    toObservable(this.constraintIdToFetch)
      .pipe(
        filter(constraintId => !!constraintId),
        tap(() => this.constraintLoading = true),
        switchMap(constraintId => this.constraintService.getConstraint(constraintId)),
        takeUntilDestroyed()
      ).subscribe({
      next: constraint => {
        this.constraintLoading = false;
        this.displayedConstraint.set(some(constraint));
      },
      error: (error: HttpErrorResponse) => {
        this.constraintLoading = false;
        this.displayedConstraint.set(fail('Error: ' + (error?.error?.message || 'Unknown error loading constraint')));
      }
    });

    // If a constraint is loaded and contains a user ID, fetch the associated user contact
    effect(() => {
      if (this.displayedConstraint$?.value() && this.displayedConstraint$.value().additional_data?.user_id) {
        this.userService.getUserContactInformation(this.displayedConstraint$.value().additional_data.user_id)
          .pipe(takeUntilDestroyed(this.destroyRef)).subscribe((contact) => {
            this.constraintUser = contact;
          });
      }
    });
  }

  getPermittedOperations(additionalConstraintData: AdditionalConstraintData): Observable<string> {
    if (
      additionalConstraintData && additionalConstraintData.constraint_type) {
      return this.constraintTypeService.getPrettyName(additionalConstraintData.constraint_type);
    } else {
      return of('');
    }
  }

  viewVolume(i: number) {
    this.showGeometry[i] = true;
  }
}
