import {Component, effect, inject, input, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeafletDrawerService} from "@ax/ax-angular-map-leaflet";
import {Observable, of, share, switchMap} from "rxjs";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {Marker, icon} from "leaflet";
import {PositionSubmissionV2} from "../../models";
import {withPrevious} from "../../utils";

export const TelemetryIcon = icon({
  iconUrl: 'assets/track-icons/uas-telemetry-icon.png',
  iconSize: [16, 16],
  iconAnchor: [8, 8],
});

@Component({
  selector: 'lib-leaflet-position-drawer',
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: ``,
})
export class LeafletPositionDrawerComponent implements OnDestroy{
  private leafletService = inject(LeafletDrawerService);
  private map$ = this.leafletService.watchViewerInit().pipe(share());
  private mapSignal = withPrevious(toSignal(this.map$));
  private marker = new Marker([0, 0], {icon: TelemetryIcon});
  position$ = input<Observable<PositionSubmissionV2 | null>>(of(null));
  private position = toSignal(
    toObservable(this.position$).pipe(
      switchMap(position$ => position$)
    ), {
      initialValue: null
    }
  );

  constructor() {

    effect(() => {
      const [previousMap, map] = this.mapSignal();
      const position = this.position();


      previousMap?.removeLayer(this.marker);

      if(!map){
        return;
      }
      const marker = this.marker;
      if(position){
        marker.setLatLng([position.latitude, position.longitude]);
        marker.addTo(map);
      } else {
        map.removeLayer(marker);
      }


    });
  }

  ngOnDestroy(): void {
    const marker = this.marker;
    const [previousMap, map] = this.mapSignal();
    previousMap?.removeLayer(marker);
    map?.removeLayer(marker);

  }

}
