import {
  Altitude,
  ContingencyPlan,
  EntityVolume4d,
  EventMetadata,
  GeoCircle,
  IPoint,
  IUasRegistration,
  NegotiationAgreement,
  Operation,
  OperationActivationPolicy,
  OperationVolume,
  PersonOrOrganization,
  Point,
  Polygon,
  PriorityElements,
  state,
  UasRegistration,
  units_of_measure,
  volume_type
} from './gen/utm';
import {IUasRegistrationInfo} from './UasRegistrationInfo';
import {IOperationSubmission, IOperationVolumeSubmission} from './OperationSubmission';
import {DateTime} from 'luxon';
import {IContact} from './Contact';
import {OperationExt} from './utm/OperationExt';
import {AdditionalOperationData} from './utm/AdditionalOperationData';
import {UvrExt} from './utm/UvrExt';
import {AdditionalConstraintData} from './utm/AdditionalConstraintData';
import {IOperationModification} from './OperationModification';
import {Converter} from '../utils/convert-units';
import {CreateOperationFormValues} from '../components/operation/create-operation/create-operation.component';


const sameTruthy = <T>(a: T, b: T): boolean => (!!a === !!b);

const compareTruthy = <T, S>(a: T, b: T, selector: (s: T) => S, cmp: (a: S, b: S) => boolean):
  boolean => sameTruthy(a, b) && (!a || cmp(selector(a), selector(b)));

const stringEquals = (a: string, b: string): boolean => a === b;

const numberEquals = (a: number, b: number): boolean => a === b;

const datetimeEquals = (a: DateTime, b: DateTime): boolean => sameTruthy(a, b) && (!a || a.equals(b));

const arrayEquals = <T>(cmp: (x: T, y: T) => boolean): (a: Array<T>, b: Array<T>) => boolean =>
  (a: Array<T>, b: Array<T>) => sameTruthy(a, b)
    && !!a
    && a.length === b.length
    && !a.find((e, i) => !cmp(e, b[i]));

export class OperationUtil {

  static extractIOperationSubmission(
    rawValues: CreateOperationFormValues,
    contact: IContact,
    controllerLocation: IPoint,
    takeoffLocation: IPoint,
    previousIntentId?: string,
    previousEntityId?: string): IOperationSubmission {

    const uasRegistrations = this.extractUasRegistrations(rawValues.platform);
    const iopVols: IOperationVolumeSubmission[] = [];

    for (const opVol of rawValues.operationGeometry.volumes) {
      iopVols.push({
        /* eslint-disable @typescript-eslint/naming-convention */
        volume_type: volume_type.ABOV,
        near_structure: false,
        effective_time_begin: opVol.timeRange.start,
        effective_time_end: opVol.timeRange.end,
        beyond_visual_line_of_sight: rawValues.visibility === 'bvlos',
        max_altitude: opVol.altitudeRange ? opVol.altitudeRange.max_altitude : undefined,
        min_altitude: opVol.altitudeRange ? opVol.altitudeRange.min_altitude : undefined,
        geography: opVol.geography,
        circle: opVol.circle
        /* eslint-enable @typescript-eslint/naming-convention */
      });
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      public_access: rawValues.public_access,
      flight_number: rawValues.operationName || '',
      flight_comments: rawValues.operationDescription || '',
      uas_registrations: uasRegistrations,
      contact: contact ? new PersonOrOrganization({
        cid: contact.id,
        // eslint-disable-next-line max-len
        name: `${contact.lastName}${contact.suffixName ? ' ' + contact.suffixName : ''}, ${contact.firstName}${contact.middleName ? ' ' + contact.middleName[0].toUpperCase() + '.' : ''}`,
        email_addresses: contact.emails,
        phone_numbers: contact.phones,
        comments: contact.comments,
        title: contact.title
      }) : undefined,
      operation_type: rawValues.operationType,
      // state: state.PROPOSED,
      controller_location: controllerLocation,
      takeoff_location: takeoffLocation,
      // contingency_plans: OperationUtil.extractContingencyPlan(contingencyPlans, controllerLocation, iopVols, beginTime, endTime, returnToTakeOffLocation),
      altitude_vertical_reference: rawValues.altitude_vertical_reference,
      altitude_units_of_measure: rawValues.altitude_units,
      volumes: iopVols,
      faa_rule: rawValues.faarule,
      vlos: rawValues.visibility !== 'bvlos',
      permitted_constraint_types: rawValues.permittedConstraintType ? [rawValues.permittedConstraintType] : [],
      federation_policy: rawValues.federationPolicy,
      contact_id: rawValues.pilot.id,
      priority: rawValues.priority,
      previous_intent_id: previousIntentId,
      previous_entity_id: previousEntityId,
      activation: rawValues.activation
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static extractIOperationModification(
    rawValues: CreateOperationFormValues,
    controllerLocation: IPoint,
    takeoffLocation: IPoint,
    previousIntentId: string,
    previousEntityId: string): IOperationModification {

    const iopVols: IOperationVolumeSubmission[] = [];

    for (const opVol of rawValues.operationGeometry.volumes) {
      iopVols.push({
        /* eslint-disable @typescript-eslint/naming-convention */
        volume_type: volume_type.ABOV,
        near_structure: false,
        effective_time_begin: opVol.timeRange.start,
        effective_time_end: opVol.timeRange.end,
        beyond_visual_line_of_sight: rawValues.visibility === 'bvlos',
        max_altitude: opVol.altitudeRange ? opVol.altitudeRange.max_altitude : undefined,
        min_altitude: opVol.altitudeRange ? opVol.altitudeRange.min_altitude : undefined,
        geography: opVol.geography,
        circle: opVol.circle
        /* eslint-enable @typescript-eslint/naming-convention */
      });
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      altitude_vertical_reference: rawValues.altitude_vertical_reference,
      altitude_units: rawValues.altitude_units,
      volumes: iopVols,
      controller_location: controllerLocation,
      takeoff_location: takeoffLocation,
      previous_intent_id: previousIntentId,
      previous_entity_id: previousEntityId
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static extractPartialIOperationSubmission(
    rawValues: CreateOperationFormValues,
    contact: IContact,
    controllerLocation: IPoint,
    takeoffLocation: IPoint): Partial<IOperationSubmission> {

    const uasRegistrations = this.extractUasRegistrations(rawValues.platform);
    const iopVols: IOperationVolumeSubmission[] = [];
    for (const opVol of rawValues.operationGeometry.volumes) {
      iopVols.push({
        /* eslint-disable @typescript-eslint/naming-convention */
        volume_type: volume_type.ABOV,
        near_structure: false,
        effective_time_begin: opVol.timeRange.start,
        effective_time_end: opVol.timeRange.end,
        beyond_visual_line_of_sight: rawValues.visibility === 'bvlos',
        max_altitude: opVol.altitudeRange ? opVol.altitudeRange.max_altitude : undefined,
        min_altitude: opVol.altitudeRange ? opVol.altitudeRange.min_altitude : undefined,
        geography: opVol.geography,
        circle: opVol.circle
        /* eslint-enable @typescript-eslint/naming-convention */
      });
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      public_access: rawValues.public_access,
      flight_number: rawValues.operationName || '',
      flight_comments: rawValues.operationDescription || '',
      uas_registrations: uasRegistrations,
      contact: new PersonOrOrganization({
        cid: contact?.id,
        name: `${contact?.lastName}${contact?.suffixName ? ' ' + contact.suffixName : ''},
              ${contact?.firstName}${contact?.middleName ? ' ' + contact.middleName : ''}`,
        email_addresses: contact?.emails,
        phone_numbers: contact?.phones,
        comments: contact?.comments,
        title: contact?.title
      }),
      contact_id: rawValues.pilot.id,
      operation_type: rawValues.operationType,
      // state: state.PROPOSED,
      controller_location: controllerLocation,
      takeoff_location: takeoffLocation,
      // contingency_plans: OperationUtil.extractContingencyPlan(contingencyPlans, controllerLocation, iopVols, beginTime, endTime, returnToTakeOffLocation),
      volumes: iopVols,
      faa_rule: rawValues.faarule,
      vlos: rawValues.visibility !== 'bvlos',
      permitted_constraint_types: rawValues.permittedConstraintType ? [rawValues.permittedConstraintType] : [],
      federation_policy: rawValues.federationPolicy,
      priority: rawValues.priority
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private static extractUasRegistrations(uasRegistrationInfo: IUasRegistrationInfo) {
    const uasRegistrations: IUasRegistration[] = [];
    if (uasRegistrationInfo) {
      uasRegistrations.push({
        /* eslint-disable @typescript-eslint/naming-convention */
        registration_id: uasRegistrationInfo.uvin,
        registration_location: uasRegistrationInfo.registration_location,
        /* eslint-enable @typescript-eslint/naming-convention */
      });
      uasRegistrations.push(...uasRegistrationInfo.additionalRegistrations.map(reg => ({
        /* eslint-disable @typescript-eslint/naming-convention */
        registration_id: reg.registrationId,
        registration_location: reg.registrar,
        /* eslint-enable @typescript-eslint/naming-convention */
      })));
    }
    return uasRegistrations;
  }

  static getPrettyOperationStateName(s: state): string {
    switch (s) {
      case state.PROPOSED:
        return 'Proposed';
      case state.ACCEPTED:
        return 'Accepted';
      case state.ACTIVATED:
        return 'Activated';
      case state.CLOSED:
        return 'Closed';
      case state.NONCONFORMING:
        return 'Non-Conforming';
      case state.ROGUE:
        return 'Contingent';
      case state.REJECTED:
        return 'Rejected';
      default:
        return '' + s;
    }
  }

  /**
    * Returns a humanized version of the operation activation field for exposure in the UI
    * @param operationActivation The operation activation value that will be converted to a humanized string.
   **/
  static humanizeOperationActivationPolicy(operationActivation: OperationActivationPolicy | string): string {
    switch (operationActivation) {
      case OperationActivationPolicy.MANUAL:
        return 'Manual';
      case OperationActivationPolicy.ON_OPERATION_START:
        return 'On Operation Start';
      case OperationActivationPolicy.ON_TELEMETRY_START:
        return 'On Telemetry Start';
      default:
        console.error(`Invalid operation activation value: ${operationActivation}`);
        return operationActivation;
    }
  }

  static toM(num: number, units: units_of_measure): number {
    if (!num && num !== 0 || !units) {
      return null;
    }
    switch (units) {
      case units_of_measure.M:
        return num;
      case units_of_measure.FT:
        return new Converter(num).from('ft').to('m');
      default:
        return -1;
    }
  }

  static operationExtEqual(a: OperationExt, b: OperationExt): boolean {
    return compareTruthy(a, b, s => s, OperationUtil.operationEquals)
      && compareTruthy(a, b, s => s.additional_data, OperationUtil.additionalDataEquals);
  }

  static additionalDataEquals(a: AdditionalOperationData, b: AdditionalOperationData) {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.airspace, stringEquals)
    && compareTruthy(a, b, s => s.category, stringEquals)
    && compareTruthy(a, b, s => s.division, stringEquals)
    && compareTruthy(a, b, s => s.operation_type, (x, y) => x === y)
    && compareTruthy(a, b, s => s.organization, stringEquals)
    && compareTruthy(a, b, s => s.public_access, (x, y) => x === y)
    && compareTruthy(a, b, s => s.security_tags, arrayEquals((x, y) => x === y))
    && compareTruthy(a, b, s => s.user, stringEquals)
    && compareTruthy(a, b, s => s.user_id, stringEquals)
    && compareTruthy(a, b, s => s.permitted_constraint_types, arrayEquals((x, y) => x === y));
  }

  static operationEquals(a: Operation, b: Operation) {
    return sameTruthy(a, b)
      && compareTruthy(a, b, s => s.operationId, stringEquals)
      && compareTruthy(a, b, s => s.uss_name, stringEquals)
      && compareTruthy(a, b, s => s.discovery_reference, stringEquals)
      && compareTruthy(a, b, s => s.submit_time, datetimeEquals)
      && compareTruthy(a, b, s => s.update_time, datetimeEquals)
      && compareTruthy(a, b, s => s.aircraft_comments, stringEquals)
      && compareTruthy(a, b, s => s.flight_comments, stringEquals)
      && compareTruthy(a, b, s => s.volumes_description, stringEquals)
      && compareTruthy(a, b, s => s.uas_registrations, arrayEquals(OperationUtil.uasRegistrationEquals))
      && compareTruthy(a, b, s => s.airspace_authorization, stringEquals)
      && compareTruthy(a, b, s => s.flight_number, stringEquals)
      && compareTruthy(a, b, s => s.contact, OperationUtil.personOrOrganizationEquals)
      && compareTruthy(a, b, s => s.state, (x, y) => x === y)
      && compareTruthy(a, b, s => s.controller_location, OperationUtil.pointEquals)
      && compareTruthy(a, b, s => s.gcs_location, OperationUtil.pointEquals)
      && compareTruthy(a, b, s => s.contingency_plans, arrayEquals(OperationUtil.contingencyPlanEquals))
      && compareTruthy(a, b, s => s.negotiation_agreements, arrayEquals(OperationUtil.negotiationAgreementEquals))
      && compareTruthy(a, b, s => s.faa_rule, (x, y) => x === y)
      && compareTruthy(a, b, s => s.priority_elements, OperationUtil.priorityElementsEquals)
      && compareTruthy(a, b, s => s.operation_volumes, arrayEquals(OperationUtil.operationVolumeEquals))
      && compareTruthy(a, b, s => s.metadata, OperationUtil.eventMetadataEquals)
      && compareTruthy(a, b, s => s.priority, numberEquals);
  }

  static uasRegistrationEquals(a: UasRegistration, b: UasRegistration): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.registration_id, stringEquals)
    && compareTruthy(a, b, s => s.registration_location, stringEquals);
  }

  static personOrOrganizationEquals(a: PersonOrOrganization, b: PersonOrOrganization): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.cid, stringEquals)
    && compareTruthy(a, b, s => s.name, stringEquals)
    && compareTruthy(a, b, s => s.email_addresses, arrayEquals(stringEquals))
    && compareTruthy(a, b, s => s.phone_numbers, arrayEquals(stringEquals))
    && compareTruthy(a, b, s => s.comments, stringEquals)
    && compareTruthy(a, b, s => s.title, stringEquals)
    && compareTruthy(a, b, s => s.isPilot, (x, y) => x === y);
  }

  static pointEquals(a: Point, b: Point) {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.type, (x, y) => x === y)
    && compareTruthy(a, b, s => s.coordinates, arrayEquals((x, y) => x === y));
  }

  static contingencyPlanEquals(a: ContingencyPlan, b: ContingencyPlan): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.contingency_id, (x, y) => x === y)
    && compareTruthy(a, b, s => s.name, (x, y) => x === y)
    && compareTruthy(a, b, s => s.contingency_cause, arrayEquals((x, y) => x === y))
    && compareTruthy(a, b, s => s.contingency_response, (x, y) => x === y)
    && compareTruthy(a, b, s => s.contingency_polygon, OperationUtil.polygonEquals)
    && compareTruthy(a, b, s => s.loiter_altitude, OperationUtil.altitudeEquals)
    && compareTruthy(a, b, s => s.relative_preference, (x, y) => x === y)
    && compareTruthy(a, b, s => s.contingency_location_description, (x, y) => x === y)
    && compareTruthy(a, b, s => s.relevant_operation_volumes, arrayEquals((x, y) => x === y))
    && compareTruthy(a, b, s => s.valid_time_begin, datetimeEquals)
    && compareTruthy(a, b, s => s.valid_time_end, datetimeEquals)
    && compareTruthy(a, b, s => s.free_text, (x, y) => x === y);
  }

  static polygonEquals(a: Polygon, b: Polygon): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.type, (x, y) => x === y)
    && compareTruthy(a, b, s => s.coordinates, arrayEquals((x, y) => JSON.stringify(x) === JSON.stringify(y)));
  }

  static circleEquals(a: GeoCircle, b: GeoCircle): boolean {
    return sameTruthy(a, b)
        && compareTruthy(a, b, s => s.latitude, (x, y) => x === y)
        && compareTruthy(a, b, s => s.longitude, (x, y) => x === y)
        && compareTruthy(a, b, s => s.radius, (x, y) => x === y)
  }

  static altitudeEquals(a: Altitude, b: Altitude): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.altitude_value, (x, y) => x === y)
    && compareTruthy(a, b, s => s.vertical_reference, (x, y) => x === y)
    && compareTruthy(a, b, s => s.units_of_measure, (x, y) => x === y)
    && compareTruthy(a, b, s => s.source, (x, y) => x === y);
  }

  static negotiationAgreementEquals(a: NegotiationAgreement, b: NegotiationAgreement): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.message_id, stringEquals)
    && compareTruthy(a, b, s => s.negotiation_id, stringEquals)
    && compareTruthy(a, b, s => s.uss_name, stringEquals)
    && compareTruthy(a, b, s => s.uss_name_of_originator, stringEquals)
    && compareTruthy(a, b, s => s.uss_name_of_receiver, stringEquals)
    && compareTruthy(a, b, s => s.gufi_originator, stringEquals)
    && compareTruthy(a, b, s => s.gufi_receiver, stringEquals)
    && compareTruthy(a, b, s => s.free_text, stringEquals)
    && compareTruthy(a, b, s => s.discovery_reference, stringEquals)
    && compareTruthy(a, b, s => s.type, (x, y) => x === y);
  }

  static priorityElementsEquals(a: PriorityElements, b: PriorityElements): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.priority_level, (x, y) => x === y)
    && compareTruthy(a, b, s => s.priority_status, (x, y) => x === y);

  }

  static eventMetadataEquals(a: EventMetadata, b: EventMetadata): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.data_collection, (x, y) => x === y)
    && compareTruthy(a, b, s => s.event_id, stringEquals)
    && compareTruthy(a, b, s => s.scenario, stringEquals)
    && compareTruthy(a, b, s => s.test_card, stringEquals)
    && compareTruthy(a, b, s => s.test_run, stringEquals)
    && compareTruthy(a, b, s => s.call_sign, stringEquals)
    && compareTruthy(a, b, s => s.test_type, (x, y) => x === y)
    && compareTruthy(a, b, s => s.source, (x, y) => x === y)
    && compareTruthy(a, b, s => s.location, stringEquals)
    && compareTruthy(a, b, s => s.setting, (x, y) => x === y)
    && compareTruthy(a, b, s => s.free_text, stringEquals)
    && compareTruthy(a, b, s => s.data_quality_engineer, stringEquals)
    && compareTruthy(a, b, s => s.modified, (x, y) => x === y);

  }

  static operationVolumeEquals(a: OperationVolume, b: OperationVolume): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.volume_type, (x, y) => x === y)
    && compareTruthy(a, b, s => s.near_structure, (x, y) => x === y)
    && compareTruthy(a, b, s => s.effective_time_begin, datetimeEquals)
    && compareTruthy(a, b, s => s.effective_time_end, datetimeEquals)
    && compareTruthy(a, b, s => s.min_altitude, OperationUtil.altitudeEquals)
    && compareTruthy(a, b, s => s.max_altitude, OperationUtil.altitudeEquals)
    && compareTruthy(a, b, s => s.geography, OperationUtil.polygonEquals)
    && compareTruthy(a, b, s => s.beyond_visual_line_of_sight, (x, y) => x === y);
  }

  static uvrExtEqual(a: UvrExt, b: UvrExt): boolean {
    return compareTruthy(a, b, s => s, OperationUtil.uvrEqual)
      && compareTruthy(a, b, s => s.additional_data, OperationUtil.uvrAdditionalDataEquals);
  }

  static uvrAdditionalDataEquals(a: AdditionalConstraintData, b: AdditionalConstraintData): boolean {
    return sameTruthy(a, b)
    && compareTruthy(a, b, s => s.user, stringEquals)
    && compareTruthy(a, b, s => s.user_id, stringEquals)
    && compareTruthy(a, b, s => s.category, stringEquals)
    && compareTruthy(a, b, s => s.division, stringEquals)
    && compareTruthy(a, b, s => s.organization, stringEquals)
    && compareTruthy(a, b, s => s.security_tags, arrayEquals(stringEquals))
    && compareTruthy(a, b, s => s.constraint_type, stringEquals);
  }

  static uvrEqual(a: UvrExt, b: UvrExt): boolean {
    return sameTruthy(a, b)
      && compareTruthy(a, b, s => s.message_id, stringEquals)
      && compareTruthy(a, b, s => s.uss_name, stringEquals)
      && compareTruthy(a, b, s => s.type, (x, y) => x === y)
      && compareTruthy(a, b, s => s.permitted_uas, arrayEquals((x, y) => x === y))
      && compareTruthy(a, b, s => s.required_support, arrayEquals((x, y) => x === y))
      && compareTruthy(a, b, s => s.permitted_gufis, arrayEquals(stringEquals))
      && compareTruthy(a, b, s => s.cause, (x, y) => x === y)
      // && compareTruthy(a, b, s => s.geography, OperationUtil.polygonEquals)
      && compareTruthy(a, b, s => s.volumes, arrayEquals(OperationUtil.uvrVolumeEquals))
      && compareTruthy(a, b, s => s.effective_time_begin, datetimeEquals)
      && compareTruthy(a, b, s => s.effective_time_end, datetimeEquals)
      && compareTruthy(a, b, s => s.actual_time_end, datetimeEquals)
      && compareTruthy(a, b, s => s.reason, stringEquals);
  }

  static uvrVolumeEquals(a: EntityVolume4d, b: EntityVolume4d): boolean {
    return sameTruthy(a, b)
      && compareTruthy(a, b, s => s.effective_time_begin, datetimeEquals)
      && compareTruthy(a, b, s => s.effective_time_end, datetimeEquals)
      && compareTruthy(a, b, s => s.min_altitude, OperationUtil.altitudeEquals)
      && compareTruthy(a, b, s => s.max_altitude, OperationUtil.altitudeEquals)
      && compareTruthy(a, b, s => s.geography, OperationUtil.polygonEquals);
  }

  // private static extractContingencyPlan(contingencyPlans: IContingencyPlan[],
  //                                       controllerLocation: IPoint,
  //                                       operationVolumes: IOperationVolume[],
  //                                       operationBeginTime: DateTime,
  //                                       operationEndTime: DateTime,
  //                                       returnToTakeOffLocation?: LatLngPoint
  // ): IContingencyPlan[] {
  //   const ret: IContingencyPlan[] = [];
  //
  //   let i = 1;
  //   for (const conplan of contingencyPlans) {
  //     let conplanPolygon: IPolygon = conplan.contingency_polygon;
  //
  //     let loiterAlt: IAltitude = new Altitude({
  //       /* eslint-disable @typescript-eslint/naming-convention */
  //       altitude_value: 0,
  //       vertical_reference: vertical_reference.W84,
  //       units_of_measure: units_of_measure.FT
  //       /* eslint-enable @typescript-eslint/naming-convention */
  //     });
  //
  //     if (!conplanPolygon || !conplanPolygon.coordinates || conplanPolygon.coordinates.length === 0) {
  //       switch (conplan.contingency_response) {
  //         case contingency_response.RETURN_TO_BASE:
  //           const pt = point([returnToTakeOffLocation.lng, returnToTakeOffLocation.lat]);
  //           const bufferedPoint = buffer(pt, GeometricStuff.DEFAULT_OPERATION_VOLUME_BUFFER_DISTANCE_METERS, {
  //             units: 'meters',
  //             steps: 8
  //           });
  //           conplanPolygon = {
  //             type: polygon_type.Polygon,
  //             coordinates: bufferedPoint.geometry.coordinates,
  //           };
  //           break;
  //         case contingency_response.LOITERING:
  //           loiterAlt = conplan.loiter_altitude;
  //           throw new Error('Not Implemented');
  //         default:
  //           console.error('Conplan had no polygon, and isn\'t a response type which we can calculate a polygon. ');
  //           break;
  //       }
  //     }
  //
  //     ret.push({
  //       /* eslint-disable @typescript-eslint/naming-convention */
  //       contingency_id: i,
  //       name: conplan.name,
  //       contingency_cause: conplan.contingency_cause || [],
  //       valid_time_begin: (conplan.valid_time_begin || operationBeginTime),
  //       valid_time_end: (conplan.valid_time_end || operationEndTime),
  //       contingency_response: conplan.contingency_response,
  //       contingency_polygon: conplanPolygon,
  //       contingency_location_description: conplan.contingency_location_description,
  //       free_text: conplan.free_text,
  //       loiter_altitude: loiterAlt,
  //       relative_preference: conplan.relative_preference,
  //       relevant_operation_volumes: conplan.relevant_operation_volumes
  //       /* eslint-enable @typescript-eslint/naming-convention */
  //     });
  //     (ret[i - 1] as any).cid = (conplan as any).cid;
  //     ++i;
  //   }
  //
  //   return ret;
  // }
}
